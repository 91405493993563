import React, { useCallback, useMemo, useState } from "react";
import RemixIcon from "components/RemixIcon";
import { AppixSelect } from "ui";
import clsx from "clsx";

const PAGINATION_DISTANCE = 3;

interface PaginationPage {
  value: number;
  title: string | number;
}
interface PaginationProps {
  id: string;
  page: number;
  limit: number;
  total: number;
  onChangePage: (nextPage: number, nextPageSize: number) => void;
  onlyPages?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  id,
  page: currentPage,
  limit,
  total,
  onChangePage,
  onlyPages = false,
}) => {
  const PAGINATION_SIZES = useMemo(
    () =>
      [limit, 10, 30, 50, 100]
        .filter((item, index, arr) => {
          return !index || item !== arr[index - 1];
        })
        .sort((a, b) => a - b),
    [limit],
  );

  const paginationSizeOptions = useMemo(() => {
    const options = [
      {
        label: "Все",
        value: total,
      },
    ];
    PAGINATION_SIZES.forEach((number) => {
      if (number < total) {
        options.push({ label: `На странице: ${number}`, value: number });
      }
    });
    return options;
  }, [total, PAGINATION_SIZES]);

  const [pageSize, setPageSize] = useState(limit);

  const maxPage = useMemo<number>(() => Math.ceil(total / pageSize), [total, pageSize]);

  const showedPages = useMemo<string>(() => {
    const endPage = pageSize * currentPage;
    const from = endPage - pageSize + 1;
    const to = endPage < total ? endPage : total;
    return `${from} - ${to}`;
  }, [currentPage, pageSize, total]);

  const pages = useMemo<PaginationPage[]>(() => {
    const pages: PaginationPage[] = [];
    for (let page = 1; page <= maxPage; page++) {
      const distance = Math.abs(page - currentPage);
      if (page === 1 || page === maxPage || distance < PAGINATION_DISTANCE) {
        pages.push({ value: page, title: page });
      } else {
        if (distance === PAGINATION_DISTANCE) {
          pages.push({ value: page, title: "..." });
        }
      }
    }

    return pages;
  }, [maxPage, currentPage]);

  const handlePrevButtonClick = useCallback(
    () => onChangePage(currentPage - 1, pageSize),
    [onChangePage, currentPage, pageSize],
  );

  const handleCurrentButtonClick = useCallback(
    (page) => () => onChangePage(page, pageSize),
    [onChangePage, pageSize],
  );

  const handleNextButtonClick = useCallback(
    () => onChangePage(currentPage + 1, pageSize),
    [onChangePage, currentPage, pageSize],
  );

  const changePageSize = useCallback(
    (nextPageSize) => {
      setPageSize(nextPageSize);
      onChangePage(1, nextPageSize);
    },
    [onChangePage],
  );

  return (
    <nav
      aria-label="Page navigation"
      className={clsx("pagination__container", {
        "justify-content-end": onlyPages,
      })}
    >
      {!onlyPages && (
        <div className="d-flex align-items-center">
          <AppixSelect
            id={`${id}paginationSize`}
            options={paginationSizeOptions}
            value={pageSize}
            placeholder={`На странице: ${pageSize}`}
            onChange={changePageSize}
          />
          <div className="pagination__showed-pages">
            {showedPages} из {total}
          </div>
        </div>
      )}
      <ul className="pagination">
        <li className={`page-item` + (currentPage <= 1 ? " disabled" : "")}>
          <button
            type="button"
            className="page-link page-prev"
            tabIndex={currentPage <= 1 ? -1 : undefined}
            aria-disabled={currentPage <= 1 ? true : undefined}
            disabled={currentPage <= 1}
            onClick={handlePrevButtonClick}
          >
            <RemixIcon icon="arrow-left-s" />
          </button>
        </li>
        {pageSize !== total && (
          <>
            {pages.map(({ title, value }) => (
              <li
                key={value}
                className={`page-item` + (currentPage === value ? " active" : "")}
                aria-current={currentPage === value ? "page" : undefined}
              >
                <button
                  type="button"
                  className="page-link"
                  onClick={handleCurrentButtonClick(value)}
                >
                  {title}
                </button>
              </li>
            ))}

            <li className={`page-item` + (currentPage >= maxPage ? " disabled" : "")}>
              <button
                type="button"
                className="page-link page-next"
                tabIndex={currentPage >= maxPage ? -1 : undefined}
                aria-disabled={currentPage >= maxPage ? true : undefined}
                disabled={currentPage >= maxPage}
                onClick={handleNextButtonClick}
              >
                <RemixIcon icon="arrow-right-s" />
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
