import clsx from "clsx";
import { FC, Dispatch, useEffect, useState, useCallback } from "react";
import { Dropdown } from "react-bootstrap";

import { ClickAwayListener } from "ui";
import { ConcatenatedNames } from "ui/AppixSelect/ConcatenatedNames";

import { TableWidget } from "widgets";

type TableRow = Record<string, any>;

interface SelectTableProps {
  id: string;
  tableData: AppixWidget.Table.State;
  label?: string;
  helper?: string;
  values?: (string | number)[];
  onChange?: Dispatch<(string | number)[]>;
}

const SelectTable: FC<SelectTableProps> = ({ id, tableData, helper, label, values, onChange }) => {
  const [dict, setDict] = useState<Record<string, string>>({});

  const [selectedRows, setSelectedRows] = useState<TableRow[]>([]);
  const [opened, setOpened] = useState(false);

  const handleChange = useCallback<Dispatch<TableRow[]>>(
    (nextSelectedRows) => {
      if (onChange) {
        onChange(nextSelectedRows.map(({ id }) => id));
      } else {
        setSelectedRows(nextSelectedRows);
      }
    },
    [onChange],
  );

  const handleSelectedRowsChange = useCallback<(nextSelectedRows: TableRow[]) => void>(
    (nextValue) => {
      handleChange(nextValue);
    },
    [handleChange],
  );

  const handleLoadedData = useCallback<(data: TableRow[]) => void>((data) => {
    const nextDict = {};
    data.forEach((item) => {
      nextDict[item.id] = item.name;
    });
    setDict((prevDict) => ({
      ...prevDict,
      ...nextDict,
    }));
  }, []);

  useEffect(() => {
    if (Array.isArray(values)) {
      setSelectedRows(
        values.map((id) => ({
          id,
          name: dict[id] || id,
        })),
      );
    }
    // eslint-disable-next-line
  }, [values, dict]);

  const handleClickAway = useCallback(() => {
    setOpened(false);
  }, []);

  const toggleOpened = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Dropdown>
        <div id={id}>
          <Dropdown.Toggle as="div" id={id + "toggler"} bsPrefix="toggler">
            <ConcatenatedNames
              onClick={toggleOpened}
              opened={opened}
              tags={selectedRows.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={opened}
            renderOnMount={true}
            bsPrefix={clsx("appix-dropdown appix-dropdown__menu", {
              visible: opened,
              invisible: !opened,
            })}
          >
            <TableWidget
              id={`${id}TABLE`}
              table={tableData}
              showCaption={false}
              values={selectedRows}
              onChange={handleSelectedRowsChange}
              onLoadedData={handleLoadedData}
            />
          </Dropdown.Menu>
        </div>
      </Dropdown>
    </ClickAwayListener>
  );
};

export default SelectTable;
