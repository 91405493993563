import { ChainEditorNodeState } from "@appix/chain-editor";

export function editorNodeToWidgetNode(node: ChainEditorNodeState): AppixWidget.Chain.Node {
  return {
    ...node.raw,
    id: node.id,
    connections:
      node.connections?.map((conn) => {
        const rawConn = node.raw.connections.find(({ nodeId }) => nodeId === conn.nodeId);
        return {
          nodeId: conn.nodeId,
          details: {
            ...(rawConn?.details ?? {}),
            ...conn,
          },
        };
      }) ?? [],
    position: {
      x: node.position?.[0],
      y: node.position?.[1],
    },
  };
}

export function editorNodesToWidgetNodes(nodes: ChainEditorNodeState[]): AppixWidget.Chain.Node[] {
  return nodes.map(editorNodeToWidgetNode);
}
