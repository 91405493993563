import { AppixMonitorResponse } from "@appix/core";
import { AppixCharts } from "ui";
import React, { useEffect, useState } from "react";
import { optionHandler } from "ui/AppixCharts";

interface TimeSeriesMonitorWidgetProps extends Partial<AppixMonitorResponse.Result> {
  state: any;
}

const TimeSeriesMonitorWidget: React.FC<TimeSeriesMonitorWidgetProps> = ({ id, state }) => {
  const [option, setOption] = useState({});

  useEffect(() => {
    setOption(optionHandler(state?.monitorExtendedData, { monitorData: state?.monitorData }));
  }, [state]);

  return (
    <div id={id} className="h-100">
      <AppixCharts style={{ height: "400px" }} option={option} />
    </div>
  );
};

export default TimeSeriesMonitorWidget;
