import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { AppixSize, AppixValidation } from "../types";
import { AppixBaseInputProps } from "./types";
import RemixIcon from "components/RemixIcon";
import Appix from "Appix";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export enum AppixTextFieldVariant {
  default = "default",
  plaintext = "plaintext",
}

export enum AppixTextFieldType {
  email = "email",
  password = "password",
  tel = "tel",
  text = "text",
  url = "url",
}

export interface AppixTextFieldProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    AppixBaseInputProps {
  label?: string;
  type?: keyof typeof AppixTextFieldType;
  sizing?: keyof typeof AppixSize;
  variant?: keyof typeof AppixTextFieldVariant;
  validation?: keyof typeof AppixValidation;
  helper?: string;
  isRequired?: boolean;
  copy?: boolean;
  clear?: boolean;
  placeholder?: string;
  ico?: string;
  onClear?: () => void;
}

export const AppixTextField: React.FC<AppixTextFieldProps> = ({
  id,
  label,
  className,
  type = AppixTextFieldType.text,
  sizing = "md",
  variant = "default",
  validation,
  helper,
  isRequired,
  onChange,
  onClear,
  copy,
  value,
  ico,
  disabled,
  clear,
  ...inputProps
}) => {
  // делаем инпут контролируемым, чтоб в любой ситуации он не висел "в воздухе"
  const [inputValue, setInputValue] = useState<string | number>("");

  useEffect(() => {
    if (typeof value === "string" || typeof value === "number") {
      setInputValue(value);
    } else setInputValue("");
  }, [value]);

  const draftId = React.useMemo<string>(() => {
    return id || `input-${Math.random()}`;
  }, [id]);
  const handleCopy = useCallback(
    (e) => {
      e.preventDefault();
      value &&
        navigator.clipboard
          .writeText(`${value}`)
          .then(() => {
            Appix.showSuccess("Текст скопирован в буфер обмена!", true);
          })
          .catch((e) => {
            Appix.showError("Не удалось скопировать текст!", true);
          });
    },
    [value],
  );

  const handleChange = useCallback(
    (e) => {
      setInputValue(e.target.value);
      onChange && onChange(e);
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    onClear && onClear();
  }, [onClear]);

  return (
    <>
      <div
        className={clsx("input-group", {
          "is-valid": validation === AppixValidation.success,
          "is-warning": validation === AppixValidation.warning,
          "is-invalid": validation === AppixValidation.error,
          disabled,
        })}
      >
        {ico && (
          <div className="input-group-text input-group-text_left">
            <RemixIcon icon={ico} />
          </div>
        )}

        <input
          {...inputProps}
          disabled={disabled}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleChange}
          id={draftId}
          type={type}
          className={
            className
              ? className
              : clsx("form-control", {
                  "form-control-sm": sizing === AppixSize.sm,
                  "form-control-lg": sizing === AppixSize.lg,
                  "form-control-plaintext": variant === AppixTextFieldVariant.plaintext,
                  "icon-left": ico,
                  "icon-right": copy,
                })
          }
        />
        {clear && !disabled && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 0, hide: 100 }}
            overlay={<Tooltip id="button-tooltip">Очистить</Tooltip>}
          >
            <button className="input-group-text input-group-text" onClick={handleReset}>
              <RemixIcon icon="close" iconSize="fw" />
            </button>
          </OverlayTrigger>
        )}
        {copy && (
          <button className="input-group-text" onClick={handleCopy}>
            <i className="ri-file-copy-line"></i>
          </button>
        )}
      </div>
      {helper && (
        <div
          className={clsx({
            "valid-feedback": validation === AppixValidation.success,
            "invalid-feedback": validation === AppixValidation.error,
          })}
        >
          {helper}
        </div>
      )}
    </>
  );
};
