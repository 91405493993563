import clsx from "clsx";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface TableDataProps {
  table: AppixWidget.TableText["table"];
  alignment: AppixWidget.TableText.Alignment;
  noHeader?: boolean;
}

const TableData: React.FC<TableDataProps> = ({ table, alignment, noHeader }) => {
  return (
    <tbody>
      {table.map((row, rowIndex) => (
        <tr
          key={rowIndex}
          className={clsx({
            headless: noHeader && rowIndex === 0,
          })}
        >
          {row.elements.map((element, index) => {
            const align: any = alignment?.[index].toLowerCase();
            return (
              <td
                key={index}
                style={{
                  textAlign: align,
                }}
              >
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{element}</ReactMarkdown>
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};

export default TableData;
