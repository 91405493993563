import React from "react";
// import clsx from "clsx";

const TableData: React.FC<any> = ({ data }) => {
  return <div className="">Table monitor widget breakdown - TableData</div>;

  // return (
  //   <>
  //     {data && Object.keys(data)
  //       ? Object.keys(data).map((item, index) => {
  //           return (
  //             <React.Fragment key={item}>
  //               <tr>
  //                 <th
  //                   scope="row"
  //                   rowSpan={Object.keys(data[item]).length + 1}
  //                   className={clsx("table__th_left-col table__th_left-col-breakdown", {
  //                     "table__th_left-col-breakdown_last":
  //                       index === Object.keys(data[item]).length - 1,
  //                   })}
  //                 >
  //                   {item}
  //                 </th>
  //               </tr>
  //               {Object.keys(data[item]).map((_data) => {
  //                 return (
  //                   <tr key={_data}>
  //                     <td>{_data}</td>
  //                     <td>{data[item][_data].value}</td>
  //                   </tr>
  //                 );
  //               })}
  //             </React.Fragment>
  //           );
  //         })
  //       : null}
  //   </>
  // );
};

export default TableData;
