import { AppixPage } from "@appix/core";
import LayoutContent from "containers/Layout/LayoutContent";
import React, { FC } from "react";

import { Carousel } from "react-bootstrap";

interface AppixSliderProps extends AppixWidget.Slider {
  handleSelectItem: (id: number) => void;
}

export const AppixSlider: FC<AppixSliderProps> = ({
  handleSelectItem,
  slides,
  scrollControls,
  scrollInterval,
  activeSlideIndex,
  slideWidthAbsolute,
  slideWidthRelative,
  contentOffsetRight,
}) => {
  return (
    <Carousel
      activeIndex={activeSlideIndex}
      onSelect={handleSelectItem}
      controls={scrollControls}
      indicators={scrollControls}
      interval={scrollInterval ? scrollInterval * 1000 : scrollInterval}
      style={{ maxHeight: "100%" }}
    >
      {slideWidthRelative === 12 &&
        slides.map((slide) => (
          <Carousel.Item key={slide.id}>
            <div
              className="d-flex justify-content-between"
              style={{
                width: slideWidthAbsolute ? slideWidthAbsolute + "px" : "100%",
              }}
            >
              <LayoutContent className="w-100" rows={slide.rows} id={slide.id} />
            </div>
          </Carousel.Item>
        ))}

      {slideWidthRelative && slideWidthRelative !== 12 && (
        <Carousel.Item>
          <div className={`d-flex ${scrollControls ? "" : "overflow-auto"}`}>
            {slides.map((slide) => (
              <div
                key={slide.id}
                style={{
                  width: `${
                    100 / (12 / slideWidthRelative) -
                    Number(contentOffsetRight) / slideWidthRelative
                  }%`,
                  flex: "0 0 auto",
                }}
              >
                <LayoutContent key={slide.id} className="w-100" rows={slide.rows} id={slide.id} />
              </div>
            ))}
          </div>
        </Carousel.Item>
      )}

      {slideWidthAbsolute && (
        <Carousel.Item>
          <div className="d-flex overflow-auto">
            {slides.map((slide) => (
              <div
                key={slide.id}
                style={{
                  width: slideWidthAbsolute + "px",
                  minWidth: slideWidthAbsolute + "px",
                }}
              >
                <LayoutContent
                  key={slide.id}
                  className="w-100"
                  rows={slide.rows as AppixPage.Container.Row[]}
                  id={slide.id}
                />
              </div>
            ))}
          </div>
        </Carousel.Item>
      )}
    </Carousel>
  );
};
