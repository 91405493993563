import clsx from "clsx";
import React from "react";

const TableData: React.FC<any> = ({ data }) => {
  return (
    <>
      {data &&
        Object.keys(data) &&
        Object.keys(data).map((item, index) => (
          <tr key={item} className={clsx({ headless: index === 0 })}>
            <th scope="row" className="table__th_left-col">
              {item}
            </th>
            <td>{data[item]?.value}</td>
          </tr>
        ))}
    </>
  );
};

export default TableData;
