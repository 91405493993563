import { AppixPage } from "@appix/core";
import clsx from "clsx";
import { Widget } from "containers";
import { FC, useMemo } from "react";
import Row from "./LayoutRow";

interface ColumnProps extends AppixPage.Container.Column {
  siblingsAmount: number;
  width?: number;
  alignment?: string;
  fixedHeight?: boolean;
}

const Column: FC<ColumnProps> = ({
  width,
  rows,
  widget,
  siblingsAmount,
  id,
  backgroundColor,
  alignment,
  fixedHeight,
}) => {
  const columnClassName = useMemo<string>(() => {
    const classArr = ["h-100", "appix-column"];

    if (backgroundColor)
      classArr.push(`bg-${backgroundColor.toLowerCase()}`, `appix-container-colored`);

    if (width) {
      classArr.push(`col-${width}`);
    } else if (12 % siblingsAmount) {
      classArr.push(`col`);
    } else {
      classArr.push(`col-${12 / siblingsAmount}`);
    }

    return classArr.join(" ");
  }, [width, backgroundColor, siblingsAmount]);
  return (
    <div id={id} className={columnClassName}>
      {(widget || rows.length > 0) && (
        <>
          {widget && (
            <div
              className={clsx({
                "h-100": fixedHeight,
                "d-flex justify-content-start": alignment === "LEFT",
                "d-flex justify-content-center": alignment === "CENTER",
                "d-flex justify-content-end": alignment === "RIGHT",
              })}
            >
              <Widget {...widget} />
            </div>
          )}
          {rows.map((row, index) => (
            <Row key={index} {...row} />
          ))}
        </>
      )}
    </div>
  );
};

export default Column;
