import { MessageDataType } from "App";
import React, { useCallback, Dispatch, SetStateAction } from "react";
import { MessageItem } from "./MessageItem";

interface MessengerProps {
  messageData: MessageDataType[];
  setMessageData?: Dispatch<SetStateAction<MessageDataType[]>>;
}

const Messenger: React.FC<MessengerProps> = ({ messageData, setMessageData }) => {
  const handleClose = useCallback(
    (id) => () => {
      if (setMessageData) {
        setMessageData((prev) => prev.filter((message) => message.id !== id));
      }
    },
    [setMessageData],
  );

  return (
    <div className="appix-messages__container">
      {messageData.map((message) => (
        <MessageItem key={message.id} handleClose={handleClose} message={message}></MessageItem>
      ))}
    </div>
  );
};

export default Messenger;
