import clsx from "clsx";
import RemixIcon from "components/RemixIcon";
import React, { useMemo } from "react";
import { CardPartProps } from "./types";

const CardHeader: React.FC<CardPartProps> = ({ data, columns }) => {
  const iconValue = `${data[columns[0]?.dataIndex]}`;
  const isIconWithHTTP = iconValue.startsWith("http");
  const iconCellIconData = columns[0]?.ico(data);
  const iconCellColoring = columns[0]?.color(data);
  const captionCellIconData = columns[1]?.ico(data);
  const captionCellColoring = columns[1]?.color(data);
  const descriptionCellIconData = columns[2]?.ico(data);
  const descriptionCellColoring = columns[2]?.color(data);

  const Icon = useMemo(() => {
    if (isIconWithHTTP) {
      return <img alt="cardIcon" src={iconValue} />;
    } else if (iconCellIconData) {
      return (
        <RemixIcon
          className={clsx({
            [`text-${iconCellIconData.color?.toLowerCase()}`]: !!iconCellIconData.color,
          })}
          icon={iconCellIconData.ico}
        />
      );
    } else {
      return (
        <div className="appix-cards__card__header__icon-text">
          {`${data[columns[0]?.dataIndex]}`.substring(0, 2)}
        </div>
      );
    }
  }, [iconCellIconData, columns, data, iconValue, isIconWithHTTP]);

  return (
    <div className="appix-cards__card__header">
      <div className="appix-cards__card__header__text-wrapper">
        <div
          className={clsx("appix-cards__card__header__text-wrapper__caption", {
            [`text-${captionCellColoring?.toLowerCase()}`]: !!captionCellColoring,
          })}
        >
          {captionCellIconData ? (
            <RemixIcon
              className={clsx({
                [`text-${captionCellIconData.color?.toLowerCase()}`]: !!captionCellIconData.color,
              })}
              icon={captionCellIconData.ico}
            />
          ) : null}
          {data[columns[1]?.dataIndex]}
        </div>
        <div
          className={clsx("appix-cards__card__header__text-wrapper__description", {
            [`text-${descriptionCellColoring?.toLowerCase()}`]: !!descriptionCellColoring,
          })}
        >
          {descriptionCellIconData ? (
            <RemixIcon
              className={clsx({
                [`text-${descriptionCellIconData.color?.toLowerCase()}`]:
                  !!descriptionCellIconData.color,
              })}
              icon={descriptionCellIconData.ico}
            />
          ) : null}
          {data[columns[2]?.dataIndex]}
        </div>
      </div>
      <div
        className={clsx("appix-cards__card__header__icon", {
          [`bg-${iconCellColoring?.toLowerCase()}`]:
            !isIconWithHTTP && !iconCellIconData && iconCellColoring,
        })}
      >
        {Icon}
      </div>
    </div>
  );
};

export default CardHeader;
