import { FC, PropsWithChildren, useEffect, useState, useCallback, useRef } from "react";
import ReactDOM from "react-dom";

import AppixDropdownPanel from "./AppixDropdownPanel";

import { AppixDropdownPosition, AppixDropdownProps, AppixDropdownToggleOpened } from "./types";

const AppixDropdown: FC<PropsWithChildren<AppixDropdownProps>> = ({
  dropdownId,
  position = AppixDropdownPosition.bottomLeft,
  withAngle,
  ViewComponent,
  children,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const angleRef = useRef<HTMLDivElement>(null);

  const [opened, setOpened] = useState<boolean>(false);
  const [draftPosition, setDraftPosition] = useState<AppixDropdownPosition>(position);

  const makeDropdownPosition = useCallback(() => {
    const containerEl = containerRef.current;
    const dropdownEl = dropdownRef.current;
    const dropdownAngleEl = angleRef.current;

    if (!containerEl || !dropdownEl) return;

    const sideOffset = 16;
    const { top: containerTop, left: containerLeft } = containerEl.getBoundingClientRect();
    const { clientWidth: containerWidth, clientHeight: containerHeight } = containerEl;
    Object.assign(dropdownEl.style, {
      position: "absolute",
      minWidth: `${containerWidth}px`,
      zIndex: 9999,
    });

    const { clientWidth: dropdownWidth, clientHeight: dropdownHeight } = dropdownEl;
    const windowWidth = window.innerWidth;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    let top = containerTop + scrollTop;
    let left = containerLeft + scrollLeft;

    switch (draftPosition) {
      case AppixDropdownPosition.topLeft:
        top -= dropdownHeight;
        break;
      case AppixDropdownPosition.topRight:
        top -= dropdownHeight;
        left += containerWidth - dropdownWidth;
        break;
      case AppixDropdownPosition.topCenter:
        top -= dropdownHeight;
        left += (containerWidth - dropdownWidth) / 2;
        break;
      case AppixDropdownPosition.bottomLeft:
        top += containerHeight;
        break;
      case AppixDropdownPosition.bottomRight:
        top += containerHeight;
        left += containerWidth - dropdownWidth;
        break;
      case AppixDropdownPosition.bottomCenter:
        top += containerHeight;
        left += (containerWidth - dropdownWidth) / 2;
        break;
      default:
    }

    // если дропдаун близко к левому краю
    if (left - sideOffset < 0) {
      left = sideOffset;
      // если дропдаун близко к правому краю
    } else if (left + dropdownWidth + sideOffset > windowWidth) {
      left = windowWidth - dropdownWidth - sideOffset;
    }

    if (dropdownAngleEl) {
      Object.assign(dropdownAngleEl.style, {
        left: `${containerLeft - left + containerWidth / 2}px`,
      });
    }

    Object.assign(dropdownEl.style, {
      top: `${top}px`,
      left: `${left}px`,
    });
  }, [draftPosition]);

  const toggleOpened = useCallback<AppixDropdownToggleOpened>(
    (flag) => {
      const nextOpened = typeof flag === "boolean" ? flag : !opened;
      setOpened(!!nextOpened);
      if (nextOpened === true) {
        setTimeout(makeDropdownPosition);
      }
    },
    [opened, makeDropdownPosition],
  );

  const handleClickAway = useCallback<(e: MouseEvent) => void>(
    (e) => {
      const containerEl = containerRef.current;
      const dropdownEl = dropdownRef.current;
      if (
        e.target &&
        !containerEl?.contains(e.target as Node) &&
        !dropdownEl?.contains(e.target as Node)
      ) {
        toggleOpened(false);
      }
    },
    [toggleOpened],
  );

  useEffect(() => {
    setDraftPosition(position);
  }, [position]);

  useEffect(() => {
    window.document.addEventListener("click", handleClickAway);
    return () => {
      window.document.removeEventListener("click", handleClickAway);
    };
  }, [handleClickAway]);

  return (
    <div ref={containerRef} className="dropdown">
      <ViewComponent opened={opened} toggleOpened={toggleOpened} />
      {ReactDOM.createPortal(
        <AppixDropdownPanel
          dropdownRef={dropdownRef}
          angleRef={angleRef}
          dropdownId={dropdownId}
          opened={opened}
          position={draftPosition}
          withAngle={withAngle}
          className={className}
        >
          {children}
        </AppixDropdownPanel>,
        window.document.body,
      )}
    </div>
  );
};

export default AppixDropdown;
