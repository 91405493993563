import clsx from "clsx";
import React from "react";

interface RemixIconProps {
  icon: string | null;
  iconStyle?: string;
  iconSize?:
    | "fw"
    | "xxs"
    | "xs"
    | "sm"
    | "1x"
    | "lg"
    | "xl"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";
  style?: Record<string, string | number>;
  onClick?: (e: React.SyntheticEvent) => void;
  id?: string;
  className?: string;
  isButton?: boolean;
  size?: number;
}

const RemixIcon: React.FC<RemixIconProps> = ({
  icon,
  iconStyle = "line",
  iconSize,
  style = {},
  onClick,
  id,
  className,
  isButton,
  size,
}) => {
  const iconComputedStyle = iconStyle === "none" ? "" : `-${iconStyle}`;
  return (
    <>
      {icon && (
        <i
          role={isButton ? "button" : undefined}
          id={id}
          className={clsx(`ri-${icon}${iconComputedStyle}`, {
            [`ri-${iconSize}`]: iconSize,
            [`${className}`]: className,
          })}
          style={{
            ...style,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            lineHeight: size ? `${size}px` : undefined,
            fontSize: size ? `${size * 1.4}px` : undefined,
          }}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default RemixIcon;
