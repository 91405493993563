import { AppixTabs } from "ui";
import { Widget } from "containers";
import React from "react";
import clsx from "clsx";
import { TabsWidgetClass, INITIAL_TABS_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

interface TabsWidgetProps extends Partial<AppixWidget.Tabs> {}

const TabsWidget: React.FC<TabsWidgetProps> = (props) => {
  const tabsWidget = useWidget<AppixWidget.Tabs>(
    props,
    INITIAL_TABS_WIDGET_STATE,
    TabsWidgetClass,
    [props.tabs, props.wizard],
  );

  return (
    <div
      className={clsx("appix-tabs-widget position-relative", {
        "d-none": !tabsWidget.state.displayed,
        "d-block": tabsWidget.state.displayed,
      })}
    >
      <CurrentWidgetInfo widget={tabsWidget} />

      {tabsWidget.state.isLoading && <Spinner />}
      {!tabsWidget.state.isLoading && (
        <>
          {!tabsWidget.state.wizard && (
            <AppixTabs
              id={String(props.id)}
              tabs={tabsWidget.state.tabs.map((tab, index) => ({
                value: index,
                label: tab.name || `Tab ${++index}`,
                counter: tab.counter,
              }))}
              onClick={tabsWidget.activateTab}
              value={tabsWidget.state.activeTabIdx}
              className="appix-tabs-widget__tabs-container"
            />
          )}
          {tabsWidget.state.tabs.map((data, index) => (
            <div
              key={index}
              className={clsx({
                "d-none": index !== tabsWidget.state.activeTabIdx,
              })}
            >
              <Widget {...data.widget} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default TabsWidget;
