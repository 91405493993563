import React from "react";

import { AppixPopover, AppixTooltip } from "ui";

import MenuItem from "./MenuItem";
import MenuChildrenTooltip from "./MenuChildrenTooltip";
import { MenuItemProps } from "./types";

const CollapsedMenuItem: React.FC<MenuItemProps> = ({
  id,
  item,
  index,
  currentLocation,
  lastChild,
  isSmall,
  collapsed,
  onItemClick,
  expandedItemsData,
  handleSetExpanded,
}) => {
  return item.children?.length ? (
    <AppixPopover
      id={id + "POPOVER"}
      PopoverContent={
        <MenuChildrenTooltip
          id={id}
          item={item}
          onItemClick={onItemClick}
          expandedItemsData={expandedItemsData}
          handleSetExpanded={handleSetExpanded}
        />
      }
      withArrow
    >
      <MenuItem
        item={item}
        index={index}
        onItemClick={onItemClick}
        currentLocation={currentLocation}
        id={id}
        handleSetExpanded={handleSetExpanded}
        expandedItemsData={expandedItemsData}
        lastChild={lastChild}
        isSmall={isSmall}
        collapsed={collapsed}
      />
    </AppixPopover>
  ) : (
    <AppixTooltip label={item.title} placement={"right"}>
      <MenuItem
        item={item}
        index={index}
        onItemClick={onItemClick}
        currentLocation={currentLocation}
        id={id}
        handleSetExpanded={handleSetExpanded}
        expandedItemsData={expandedItemsData}
        lastChild={lastChild}
        isSmall={isSmall}
        collapsed={collapsed}
      />
    </AppixTooltip>
  );
};

export default CollapsedMenuItem;
