import RemixIcon from "components/RemixIcon";
import { useCallback } from "react";
import { getColor } from "utils";

export function NodeContextMenu({ position, node, onActionSelect }) {
  const handleActionClick = useCallback(
    (action) => () => {
      onActionSelect(node, action);
    },
    [node, onActionSelect],
  );
  return (
    <div
      className="position-fixed list-group-flush"
      style={{
        top: position?.y + "px",
        left: position?.x + "px",
        zIndex: 5,
      }}
    >
      {node.customActions.map((action, index) => (
        <div
          key={index}
          role="button"
          onClick={handleActionClick(action)}
          className={`d-flex align-items-center dropdown-item`}
          style={{ color: getColor(action.color) }}
        >
          {action.ico && <RemixIcon className="me-2" icon={action.ico} />}
          {action.text}
        </div>
      ))}
    </div>
  );
}
