import { FC } from "react";
import { AppixModal } from "ui";
import LayoutContent from "containers/Layout/LayoutContent";
import { ModalWidgetClass, INITIAL_MODAL_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const ModalWidget: FC<AppixWidget.Modal> = (props) => {
  const modalWidget = useWidget<AppixWidget.Modal>(
    props,
    INITIAL_MODAL_WIDGET_STATE,
    ModalWidgetClass,
    [props.size, props.position, props.caption, props.rows],
  );

  return modalWidget.state.displayed ? (
    <AppixModal
      className={"ps-0"}
      id={props.id}
      type="MODAL"
      onClose={modalWidget.close}
      caption={modalWidget.state.caption}
      isShown={modalWidget.state.isShown}
      rows={modalWidget.state.rows}
      size={modalWidget.state.size}
      position={modalWidget.state.position}
      widgetCtxInfo={<CurrentWidgetInfo widget={modalWidget} />}
      isClosable={modalWidget.state.isClosable}
    >
      <>
        {modalWidget.state.isLoading && <Spinner />}
        {!modalWidget.state.isLoading && <LayoutContent rows={modalWidget.state.rows} />}
      </>
    </AppixModal>
  ) : null;
};

export default ModalWidget;
