import Appix from "Appix";
import RemixIcon from "components/RemixIcon";
import React, { useCallback, useState } from "react";
import { AppixCodeEditor, AppixModal } from "ui";
import "./style.scss";

const CurrentWidgetInfo: React.FC<any> = ({ widget }) => {
  const [isShown, setIsShown] = useState(false);

  const handleClose = useCallback(() => {
    setIsShown(false);
  }, []);

  const handleShow = useCallback(() => {
    setIsShown(true);
  }, []);

  return (
    Appix.devModeOptions.showCurrentCtx && (
      <>
        <div onClick={handleShow} className="widget_ctx_info">
          <RemixIcon iconSize="lg" icon="terminal-box" />
        </div>
        <AppixModal
          onClose={handleClose}
          caption={`Информация о виджете id: ${widget.state.id}`}
          isShown={isShown}
          size={{ w: 30, h: 100, fitContent: false }}
          className="modal-dialog-scrollable"
        >
          <>
            <span>Context:</span>
            <AppixCodeEditor
              language={"json"}
              value={JSON.stringify(widget.state.ctx)}
              id={widget.state.id + "context"}
              readOnly={true}
            />
            <br />
            <span>State:</span>
            <AppixCodeEditor
              language={"json"}
              value={JSON.stringify(widget.state)}
              id={widget.state.id + "state"}
              readOnly={true}
            />
          </>
        </AppixModal>
      </>
    )
  );
};

export default CurrentWidgetInfo;
