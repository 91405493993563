import clsx from "clsx";
import { FC, ReactElement, useCallback, useEffect, useMemo, useRef } from "react";
import AppixOffcanvas from "./AppixOffcanvas";
import RemixIcon from "components/RemixIcon";

export interface AppixModalProps extends Partial<AppixWidget.Modal> {
  children: ReactElement;
  className?: string;
  size?: { w: number; h: number; fitContent: boolean } | null;
  position?: "LEFT" | "CENTER" | "RIGHT" | "TOP" | "BOTTOM";
  widgetCtxInfo?: any;
  titleIconBtn?: string;
  onClose?: () => void;
  onHandleTitleBtnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isClosable?: boolean;
}

export const AppixModal: FC<AppixModalProps> = ({
  children,
  id,
  caption,
  className,
  rows,
  isShown,
  size,
  position,
  widgetCtxInfo,
  titleIconBtn,
  onClose,
  onHandleTitleBtnClick,
  isClosable = true,
}) => {
  const placement = useMemo(() => {
    switch (position) {
      case "LEFT":
        return "start";
      case "RIGHT":
        return "end";
      case "BOTTOM":
        return "bottom";
      case "TOP":
        return "top";
      case "CENTER":
      default:
        return "center";
    }
  }, [position]);

  const modalRef = useRef(null);

  const handleClose = useCallback(
    (e) => {
      if (modalRef?.current === e.target && onClose) {
        isClosable && onClose();
      }
    },
    [isClosable, onClose],
  );

  const handleCancel = useCallback(() => {
    isClosable && onClose?.();
  }, [isClosable, onClose]);

  useEffect(() => {
    isShown &&
      !document.body.classList.contains("modal-open") &&
      document.body.classList.add("modal-open");
    !isShown && document.body.classList.remove("modal-open");
    return () => {
      document.body.classList.contains("modal-open") &&
        document.body.classList.remove("modal-open");
    };
  }, [isShown]);

  const finalHeight = size?.fitContent ? "fit-content" : size?.h ? size.h + "%" : undefined;

  const finalWidth = size?.fitContent ? "fit-content" : undefined;

  return placement === "center" ? (
    <>
      <div
        ref={modalRef}
        role="dialog"
        aria-modal="true"
        className={clsx(`fade modal`, {
          show: isShown,
          "d-block": isShown,
          "d-none": !isShown,
          [`${className}`]: className,
        })}
        tabIndex={-1}
        style={{ paddingRight: "15px" }}
        onClick={handleClose}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          style={{
            maxWidth: size?.fitContent ? "fit-content" : size?.w ? size.w + "%" : undefined,
            height: size?.fitContent ? "fit-content" : finalHeight ? "calc(100% - 3.5rem)" : "auto",
            minHeight: "calc(100% - 3.5rem)",
          }}
        >
          <div
            className="modal-content"
            style={{
              height: finalHeight,
              width: finalWidth,
            }}
          >
            {widgetCtxInfo}

            <div className="modal-close d-flex justify-content-end">
              <button
                type="button"
                className={clsx("btn-close", {
                  invisible: !isClosable,
                })}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
                id={id + "CLOSE"}
              />
            </div>
            <div
              className="modal-header"
              style={{
                maxWidth: titleIconBtn ? "none" : "fit-content",
              }}
            >
              <h5 className="modal-title">{caption}</h5>
              {titleIconBtn && (
                <button className="input-group-text" onClick={onHandleTitleBtnClick}>
                  <RemixIcon icon={titleIconBtn} />
                </button>
              )}
            </div>

            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
      {isShown && (
        <div
          className={clsx(`fade modal-backdrop`, {
            show: isShown,
          })}
          onClick={handleClose}
        ></div>
      )}
    </>
  ) : (
    <AppixOffcanvas
      isShown={isShown}
      onClose={onClose}
      handleCancel={handleCancel}
      children={children}
      id={id}
      caption={caption}
      className={className}
      placement={placement}
      size={size}
      rows={rows}
      isClosable={isClosable}
    />
  );
};
