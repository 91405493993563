import { FC, useCallback, useEffect, useState } from "react";

import ConfirmModal from "../ConfirmModal";

// вынесено сюда, потому что одновременно может существовать только один конфирм
// и взаимодействие с setInterval проще
let dialogConfirmed = false;
let dialogCanceled = false;
let dialogTimer: NodeJS.Timeout;

interface ConfirmProps {
  id: string;
  dialogTitle?: string;
  widget: { confirm: (message: string, row?: any) => Promise<boolean> };
}

const Confirm: FC<ConfirmProps> = ({ id, dialogTitle = "Подтвердите действие", widget }) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const confirm = useCallback<(message: string) => Promise<boolean>>((message) => {
    setDialogOpened(true);
    setDialogMessage(message);
    dialogConfirmed = false;
    dialogCanceled = false;

    return new Promise((resolve) => {
      dialogTimer = setInterval(() => {
        if (dialogConfirmed) {
          resolve(true);
          clearInterval(dialogTimer);
          setDialogOpened(false);
        } else if (dialogCanceled) {
          resolve(false);
          clearInterval(dialogTimer);
          setDialogOpened(false);
        }
      }, 250);
    });
  }, []);

  const handleDialogSubmit = useCallback(() => {
    dialogConfirmed = true;
    setDialogOpened(false);
  }, []);

  const handleDialogCancel = useCallback(() => {
    dialogCanceled = true;
    setDialogOpened(false);
  }, []);

  useEffect(() => {
    widget.confirm = confirm;
    return () => clearInterval(dialogTimer);
    // eslint-disable-next-line
  }, [widget]);

  return (
    <ConfirmModal
      id={id}
      title={dialogMessage}
      opened={dialogOpened}
      onSubmit={handleDialogSubmit}
      onCancel={handleDialogCancel}
    />
  );
};

export default Confirm;
