// import moment from "moment";
// import React, { useMemo } from "react";
import React from "react";
// import clsx from "clsx";

// const granularityFormat = {
//   TIMESTAMP: "X",
//   SECOND: "s",
//   MINUTE: "m",
//   HOUR: "H",
//   DAY: "DD.MM.YYYY",
//   WEEK: "w",
//   MONTH: "MMM",
//   YEAR: "YYYY",
// };

const TableData: React.FC<any> = ({ data, granularity }) => {
  return <div className="">Table monitor widget timeseries - TableData</div>;

  // const allDates = useMemo(
  //   () =>
  //     data
  //       ?.reduce((acc: string[], item) => {
  //         item.data?.forEach((date) => {
  //           if (!acc.includes(date.datetime)) {
  //             acc.push(date.datetime);
  //           }
  //         });
  //         return acc;
  //       }, [])
  //       .sort(),
  //   [data],
  // );

  // return (
  //   <>
  //     <tr>
  //       <th></th>
  //       {allDates?.map((date, index) => (
  //         <th
  //           key={date}
  //           className={clsx({ "table-time-series__th_last": index === allDates.length - 1 })}
  //         >
  //           {moment(`${date}`).format(granularity ? granularityFormat[granularity] : "DD.MM.YYYY")}
  //         </th>
  //       ))}
  //     </tr>
  //     {data?.map((item) => (
  //       <React.Fragment key={item.name}>
  //         <tr>
  //           <th scope="row" className="table__th_left-col">
  //             {item.name}
  //           </th>
  //           {allDates?.map((date) => (
  //             <td key={date}>
  //               {item.data.find((item) => item.datetime === date)?.value || "no data"}
  //             </td>
  //           ))}
  //         </tr>
  //       </React.Fragment>
  //     ))}
  //   </>
  // );
};

export default TableData;
