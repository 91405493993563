import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { AppixBlockquote } from "ui/AppixBlockquote/AppixBlockquote";

interface ITab {
  label: string;
  value: any;
  disabled?: boolean;
  counter?: AppixWidget.Button.Counter;
}

export interface AppixTabsProps extends React.HTMLAttributes<HTMLUListElement> {
  value?: number;
  onClick: React.MouseEventHandler;
  tabs: ITab[];
  id: string;
  className?: string;
}

export const AppixTabs: React.FC<AppixTabsProps> = ({
  tabs,
  value,
  onClick,
  id,
  className,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState<any>(null);

  useEffect(() => {
    !value && setCurrentValue(tabs?.[0]?.value);
    value && setCurrentValue(value);
  }, [value, tabs]);

  const handleTabClick = useCallback(
    (item: ITab) => (e: React.SyntheticEvent) => {
      e.preventDefault();
      setCurrentValue(item.value);
      onClick(item.value);
    },
    [onClick],
  );
  return (
    <div className={clsx(className)}>
      <ul className="nav nav-tabs" {...props}>
        {tabs.map((item, index) => (
          <li key={item.value} className="nav-item">
            <a
              id={id + `${index}`}
              className={clsx("nav-link d-flex align-items-center", {
                active: currentValue === item.value,
                disabled: item.disabled,
              })}
              aria-current="page"
              href="/"
              onClick={handleTabClick(item)}
            >
              {item.label}
              {item.counter && (
                <AppixBlockquote
                  badge={true}
                  isNano={true}
                  displayed={true}
                  text={item.counter.value.toString()}
                  level={currentValue === item.value ? "info" : "secondary"}
                />
              )}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
