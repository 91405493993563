import React from "react";
import Card from "./Card/Card";
import { AppixCardProps } from "./Card/types";

const AppixCards: React.FC<AppixCardProps> = ({
  data,
  columns,
  coloringRules,
  rowActions,
  primaryKey,
  id,
}) => {
  return (
    <div className="appix-cards">
      {data.map((item, index) => (
        <Card
          id={id}
          key={index}
          data={item}
          columns={columns}
          coloringRules={coloringRules}
          rowActions={rowActions}
          primaryKey={primaryKey}
        />
      ))}
    </div>
  );
};

export default AppixCards;
