import React from "react";
import { AppixBreadcrumbsItem } from "./AppixBreadcrumbsItem";

export interface ICrumb {
  label: string;
  value: any;
  hasPage?: boolean;
}

export interface AppixBreadcrumbsProps {
  value?: number;
  onClick(path: string): void;
  crumbs: ICrumb[];
  divider?: string;
  baseUrl: string;
}

export const AppixBreadcrumbs: React.FC<AppixBreadcrumbsProps> = ({
  crumbs,
  value,
  onClick,
  divider,
  baseUrl,
  ...props
}) => {
  return (
    <nav
      style={
        {
          "--bs-breadcrumb-divider": `'${divider || ">"}'`,
        } as React.CSSProperties
      }
    >
      <ol className="breadcrumb">
        {crumbs.map((item, i) => (
          <AppixBreadcrumbsItem
            isLink={i !== crumbs.length - 1}
            key={i}
            onClick={onClick}
            item={item}
            i={i}
            baseUrl={baseUrl}
          />
        ))}
      </ol>
    </nav>
  );
};
