import { useLayoutEffect, useCallback, useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "components/FallbackComponent/FallbackComponent";

import { createBackendUrlForm, AppixPage, AppixNotification } from "@appix/core";
import Appix from "Appix";

import { Router } from "containers";
import { SidebarMenu, Confirm, Decision } from "components";
import Messenger from "components/Messenger/Messenger";
import { useNavigate } from "react-router-dom";

interface IMenuData {
  siteName?: string;
  mainMenuItems?: Array<AppixPage.Layout.MenuItem>;
  secondaryMenuItems?: Array<AppixPage.Layout.MenuItem>;
  loginInfoLocation?: AppixPage.Layout["loginInfoLocation"];
  loginPageLocation?: AppixPage.Layout["loginPageLocation"];
  authToken?: string;
}

export interface MessageDataType {
  title: string;
  code?: string | number;
  autoHide?: boolean;
  level: string;
  id: number;
}

const getNewMessageData: (
  title: string,
  level: string,
  autoHide: boolean,
  code?: string | number,
) => MessageDataType = (title, level, autoHide, code = "") => ({
  title,
  level,
  code,
  autoHide,
  id: Math.floor(Math.random() * 10000),
});

const raw = localStorage.getItem("sidebar-opened");
const savedCollapsed: boolean = raw !== null ? JSON.parse(raw) : false;

const App = () => {
  const [menuData, setMenuData] = useState<IMenuData>();
  const [messageData, setMessageData] = useState<MessageDataType[]>([]);
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(savedCollapsed);
  const handleSidebarCollapsed = useCallback((flag: boolean) => {
    localStorage.setItem("sidebar-opened", JSON.stringify(flag));
    setSidebarCollapsed(flag);
  }, []);

  const initialize = useCallback(async () => {
    const result = await Appix.loadData();
    const authToken = Appix.getAuthToken();

    (!menuData || authToken !== menuData.authToken || !result?.layout?.mainMenu) &&
      setMenuData({
        loginInfoLocation: result?.layout?.loginInfoLocation || undefined,
        loginPageLocation: result?.layout?.loginPageLocation || undefined,
        siteName: result?.layout?.systemName || undefined,
        mainMenuItems: result?.layout?.mainMenu?.items,
        authToken,
      });
    return result;
  }, [menuData]);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    createBackendUrlForm();

    Appix.setBackendUrl(process.env.REACT_APP_BACKEND_API);
    Appix.navigate = navigate;
    Appix.showError = (...args) => {
      const { message, autoHide, code } =
        typeof args[0] === "object"
          ? args[0]
          : {
              message: args[0],
              autoHide: args[1],
              code: args[2],
            };

      switch (code) {
        default:
          setMessageData((prev) => [
            ...prev,
            getNewMessageData(message, "danger", !!autoHide, code),
          ]);
      }
    };

    Appix.showInfo = (infoMessage: string, autoHide: boolean) => {
      setMessageData((prev) => [...prev, getNewMessageData(infoMessage, "info", autoHide)]);
    };

    Appix.showNotification = (notification: AppixNotification) => {
      setMessageData((prev) => [
        ...prev,
        getNewMessageData(notification.title, notification.notificationLevel, true),
      ]);
    };

    Appix.showSuccess = (successMessage: string, autoHide: boolean) => {
      setMessageData((prev) => [...prev, getNewMessageData(successMessage, "success", autoHide)]);
    };

    Appix.showWarning = (warningMessage: string, autoHide: boolean) => {
      setMessageData((prev) => [...prev, getNewMessageData(warningMessage, "warning", autoHide)]);
    };
  }, [navigate]);

  useEffect(() => {
    if (menuData?.siteName) {
      document.querySelector(".app-title")!.textContent = menuData.siteName;
    }
  }, [menuData?.siteName]);

  const errorHandlingFunction = useCallback<
    (
      error: Error,
      info: {
        componentStack: string;
      },
    ) => void
  >((error, info) => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, []);

  const CircuitErrorFallback = useCallback(({ error, resetErrorBoundary }) => {
    return <FallbackComponent resetErrorBoundary={resetErrorBoundary} error={error} />;
  }, []);

  return (
    <>
      <ErrorBoundary FallbackComponent={CircuitErrorFallback} onError={errorHandlingFunction}>
        <Messenger messageData={messageData} setMessageData={setMessageData} />
        <div className="d-flex position-relative" style={{ height: "100%", minHeight: "100vh" }}>
          <SidebarMenu
            menuData={menuData || {}}
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={handleSidebarCollapsed}
          />

          <Router init={initialize} sidebarCollapsed={sidebarCollapsed} />
        </div>
      </ErrorBoundary>

      {/* переопределяем конфирм для экземпляра Аппикса */}
      <Confirm id={"COMMON_CONFIRM"} widget={Appix} />

      {/* переопределяем decision */}
      <Decision id={"COMMON_DECISION"} widget={Appix} />
    </>
  );
};

export default App;
