import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Appix from "Appix";
import { collapseSlashes } from "utils";
import clsx from "clsx";
import { MenuWidgetClass, INITIAL_MENU_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import { AppixNavigation } from "ui/AppixNavigation";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const MenuWidget: React.FC<AppixWidget.Menu> = (props) => {
  const menuWidget = useWidget<AppixWidget.Menu>(
    props,
    INITIAL_MENU_WIDGET_STATE,
    MenuWidgetClass,
    [props.menu],
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const changeRoute = useCallback(
    (path: string) => {
      navigate(collapseSlashes(Appix.baseUrl + path));
    },
    [navigate],
  );

  return menuWidget.state.isLoading ? (
    <Spinner />
  ) : (
    <div className="position-relative">
      <CurrentWidgetInfo widget={menuWidget} />
      <AppixNavigation
        id={menuWidget.id}
        items={menuWidget.state.menu.items}
        onItemClick={changeRoute}
        currentLocation={pathname}
        className={clsx({
          "d-none": !menuWidget.state.displayed,
          "d-block": menuWidget.state.displayed,
        })}
        isSmall={true}
      />
    </div>
  );
};

export default MenuWidget;
