import React, { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { AppixButton, AppixDatePicker, AppixTextField } from "ui";
import RemixIcon from "components/RemixIcon";
import "./style.scss";

const FilterListValue = ({ id, onChange, value, isDate }) => {
  const [items, setItems] = useState<Record<string, string>[]>(
    value?.length ? value : [{ value: "" }],
  );

  useEffect(() => {
    const newItems = items.map((item, index) => {
      if (value[index]?.error) {
        return { ...item, error: value[index].error };
      }
      return item;
    });
    setItems(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const addItem = useCallback(() => {
    const newValue = [...value, ""];
    setItems((prev) => [...prev, { value: "" }]);
    onChange(newValue);
  }, [onChange, value]);

  const handleRemove = useCallback<(index: number) => MouseEventHandler<HTMLDivElement>>(
    (index) => () => {
      setItems((prevItems) => {
        const nextItems = [...prevItems];
        nextItems.splice(index, 1);
        return nextItems;
      });
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    },
    [value, onChange],
  );

  const handleInputChange = useCallback(
    (index) => (e) => {
      let newValue = e.target.value;

      // если пришла дата - берем старт
      if (e.target.value?.startDate) {
        newValue = e.target.value?.startDate;
      }

      const newItems = [...items];
      newItems[index].value = newValue;
      setItems(newItems);
      onChange(newItems);
    },
    [items, onChange],
  );

  return (
    <div className="list-value filter-list-value">
      {items.map((item, index) => (
        <div className="list-value__item" key={index}>
          <div className="list-value__item__header">
            <span className="list-value__item__title">Значение {index + 1}</span>
            <div
              role="button"
              id={`${id}X${index}`}
              onClick={handleRemove(index)}
              className="list-value__item__delete "
            >
              <RemixIcon icon="delete-bin-6" />
            </div>
          </div>
          <div className="list-value__item__fields">
            {isDate && (
              <AppixDatePicker
                value={items[index].value?.replace("Z", "")}
                onChange={handleInputChange(index)}
                selectRange={false}
                withTime={true}
                defaultView="month"
                helper={items[index]?.error}
              />
            )}
            {!isDate && (
              <AppixTextField
                value={items[index].value}
                onChange={handleInputChange(index)}
                validation={items[index]?.error ? "error" : undefined}
                helper={items[index]?.error}
              />
            )}
          </div>
        </div>
      ))}
      <div className="list-value__more">
        <AppixButton
          id={`${id}ADD_MORE`}
          size="md"
          beforeIconCode="add"
          variant="link"
          onClick={addItem}
        >
          Добавить еще
        </AppixButton>
      </div>
    </div>
  );
};

export default FilterListValue;
