import { FC, LegacyRef, PropsWithChildren } from "react";

import clsx from "clsx";

import { AppixDropdownProps } from "./types";

interface AppixDropdownPanelProps
  extends Omit<AppixDropdownProps, "ViewComponent" | "ContentComponent"> {
  dropdownRef?: LegacyRef<HTMLDivElement>;
  angleRef?: LegacyRef<HTMLDivElement>;
  opened: boolean;
}

const AppixDropdownPanel: FC<PropsWithChildren<AppixDropdownPanelProps>> = ({
  dropdownRef,
  angleRef,
  opened,
  dropdownId,
  position,
  withAngle,
  children,
  className,
}) => {
  return (
    <div
      ref={dropdownRef}
      id={dropdownId}
      className={clsx("dropdown-menu", "appix-dropdown", className, {
        show: opened,
        [`appix-dropdown_${position}`]: !!position,
        "appix-dropdown_with-angle": withAngle,
      })}
    >
      {withAngle && <div ref={angleRef} className="appix-dropdown__angle" />}

      <div className="appix-dropdown__content">{children}</div>
    </div>
  );
};

export default AppixDropdownPanel;
