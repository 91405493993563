import { FC, Dispatch, useState, useEffect, useMemo, useCallback } from "react";

import { ITreeTableWidgetNode } from "@appix/core";

import { AppixFormControl, ClickAwayListener } from "ui";

import { TreetableWidget } from "widgets";
import { Dropdown } from "react-bootstrap";
import clsx from "clsx";
import { ConcatenatedNames } from "ui/AppixSelect/ConcatenatedNames";

interface ISelectTreeSelectedRow {
  id: string | number;
  name: string;
}

interface SelectTreeProps {
  id: string;
  treeData: AppixWidget.Table.State;
  label?: string;
  helper?: string;
  values?: (string | number)[];
  onChange?: Dispatch<(string | number)[]>;
}

const SelectTree: FC<SelectTreeProps> = ({ id, treeData, helper, label, values, onChange }) => {
  const [dict, setDict] = useState<Record<string, string>>({});

  const [selectedRows, setSelectedRows] = useState<ISelectTreeSelectedRow[]>([]);
  const [opened, setOpened] = useState(false);

  const handleChange = useCallback<Dispatch<ISelectTreeSelectedRow[]>>(
    (nextSelectedRows) => {
      if (onChange) {
        onChange(nextSelectedRows.map(({ id }) => id));
      } else {
        setSelectedRows(nextSelectedRows);
      }
    },
    [onChange],
  );

  const handleSelectedRowsChange = useCallback<
    (nextSelectedRows: ISelectTreeSelectedRow[]) => void
  >(
    (nextSelectedRows) => {
      handleChange(nextSelectedRows);
    },
    [handleChange],
  );

  const handleLoadedData = useCallback<
    (data: ITreeTableWidgetNode[], labelProperty: string) => void
  >((data, labelProperty) => {
    function getDataDict(data) {
      const nextDict = {};
      data.forEach((item) => {
        if (item.value) {
          nextDict[item.value?.id] = item.value[labelProperty];
        } else {
          nextDict[item.id] = item[labelProperty];
        }
        if (item.children) {
          Object.assign(nextDict, getDataDict(item.children));
        }
      });
      return nextDict;
    }
    setDict((prevDict) => ({
      ...prevDict,
      ...getDataDict(data),
    }));
  }, []);

  useEffect(() => {
    if (Array.isArray(values)) {
      setSelectedRows(
        values.map((id) => ({
          id: +id,
          name: dict[id] || String(id),
        })),
      );
    }
    // eslint-disable-next-line
  }, [values, dict]);

  const treeValues = useMemo(() => selectedRows.map(({ id }) => id), [selectedRows]);

  const handleClickAway = useCallback(() => {
    setOpened(false);
  }, []);

  const toggleOpened = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Dropdown>
        <AppixFormControl id={id} helper={helper} label={label}>
          <>
            <Dropdown.Toggle as="div" id={id + "toggler"} bsPrefix="toggler">
              <ConcatenatedNames
                onClick={toggleOpened}
                opened={opened}
                tags={selectedRows.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              show={opened}
              renderOnMount={true}
              bsPrefix={clsx("appix-dropdown appix-dropdown__menu", {
                visible: opened,
                invisible: !opened,
              })}
            >
              <TreetableWidget
                id={id}
                table={treeData}
                showCaption={false}
                values={treeValues}
                isTreeForSelect={true}
                onChange={handleSelectedRowsChange}
                onLoadedData={handleLoadedData}
              />
            </Dropdown.Menu>
          </>
        </AppixFormControl>
      </Dropdown>
    </ClickAwayListener>
  );
};

export default SelectTree;
