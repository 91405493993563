import clsx from "clsx";
import RemixIcon from "components/RemixIcon";
import React from "react";
import { CardPartProps } from "./types";

const CardBody: React.FC<CardPartProps> = ({ data, columns }) => {
  return (
    <div className="appix-cards__card__body">
      {columns.slice(4).map((column, index) => {
        const cellIconData = columns[index + 4]?.ico(data);
        const cellColoring = columns[index + 4]?.color(data);
        return (
          <div key={index} className="appix-cards__card__body-row">
            {cellIconData ? (
              <RemixIcon
                className={clsx({
                  [`text-${cellIconData.color?.toLowerCase()}`]: !!cellIconData.color,
                })}
                icon={cellIconData.ico}
              />
            ) : null}
            <span
              className={clsx({
                [`text-${cellColoring?.toLowerCase()}`]: !!cellColoring,
              })}
            >
              {data[column.dataIndex]}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default CardBody;
