import React from "react";
import AccordionItem from "./AccordionItem";
import { AccordionWidgetClass, INITIAL_ACCORDION_WIDGET_STATE } from "@appix/core";

import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

interface AccordionWidgetProps extends AppixWidget.Accordion {}

const AccordionWidget: React.FC<AccordionWidgetProps> = (props) => {
  const accordionWidget = useWidget<AppixWidget.Accordion>(
    props,
    INITIAL_ACCORDION_WIDGET_STATE,
    AccordionWidgetClass,
    [props.collapsibleSections],
  );

  return (
    <div className="position-relative">
      <CurrentWidgetInfo widget={accordionWidget} />
      {accordionWidget.state.isLoading && <Spinner />}
      {!accordionWidget.state.isLoading && accordionWidget.state.displayed && (
        <div id={props.id} className="accordion">
          {accordionWidget.state.collapsibleSections.map((section, index) => (
            <AccordionItem key={index} {...section} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AccordionWidget;
