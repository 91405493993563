import React, { useCallback, useEffect, useState } from "react";
import { AppixSize } from "../types";

export interface AppixPaginationProps {
  value?: number;
  onClick(n: number): void;
  pagesCount: number;
  prevAndNextLabel?: string[];
  size?: keyof typeof AppixSize;
}

export const AppixPagination: React.FC<AppixPaginationProps> = ({
  pagesCount,
  value,
  onClick,
  prevAndNextLabel,
  size = "md",
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState<number>(1);

  useEffect(() => {
    value && setCurrentValue(value);
  }, [value]);

  const handleItemClick = useCallback(
    (item: number) => (e) => {
      e.preventDefault();
      !value && setCurrentValue(item);
      onClick(item);
    },
    [onClick, value],
  );

  const handleActionButtonClick = useCallback(
    (action: string) => (e) => {
      e.preventDefault();
      const newValue = action === "next" ? currentValue + 1 : currentValue - 1;
      setCurrentValue(newValue);
      onClick(newValue);
    },
    [onClick, currentValue],
  );

  const pagesArray: Array<number | undefined> = Array.from({ length: pagesCount }, (_, i) => i + 1);

  return (
    <nav {...props}>
      <ul className={`pagination pagination-${size}`}>
        <li className={`page-item ${currentValue === 1 ? "disabled" : ""}`}>
          <a className="page-link" href="/" onClick={handleActionButtonClick("prev")}>
            {prevAndNextLabel?.[0] || "«"}
          </a>
        </li>
        {pagesArray.map((item) => (
          <li key={item} className={`page-item ${currentValue === item ? "active" : ""}`}>
            <a className="page-link" href="/" onClick={handleItemClick(item!)}>
              {item}
            </a>
          </li>
        ))}
        <li className={`page-item ${currentValue === pagesCount ? "disabled" : ""}`}>
          <a className="page-link" href="/" onClick={handleActionButtonClick("next")}>
            {prevAndNextLabel?.[1] || "»"}
          </a>
        </li>
      </ul>
    </nav>
  );
};
