import React, { useMemo } from "react";

import * as widgets from "widgets";

const Widget: React.FC<any> = (props) => {
  const { type } = props;
  const widgetName = useMemo<string>(() => {
    return typeof type === "string"
      ? type.charAt(0).toUpperCase() + type.slice(1).toLowerCase() + "Widget"
      : "";
  }, [type]);

  return (
    <>
      {widgets[widgetName]
        ? React.createElement(widgets[widgetName], props)
        : `<Unknown widget (type=${type} name=${widgetName})>`}
    </>
  );
};

export default Widget;
