import { FC, useEffect, useState } from "react";
import { MultiGraphWidgetClass, INITIAL_MULTIGRAPH_WIDGET_STATE, Multigraph } from "@appix/core";
import useWidget from "utils/useWidget";
import { AppixCharts } from "ui";
import { optionHandler } from "ui/AppixCharts";

interface propType extends Multigraph.State {
  id: string;
}

const MultiGraphWidget: FC<propType> = (props) => {
  const [option, setOption] = useState({});

  const multigraphWidget = useWidget<Multigraph.State>(
    props,
    INITIAL_MULTIGRAPH_WIDGET_STATE,
    MultiGraphWidgetClass,
    [],
  );

  useEffect(() => {
    const newOption = optionHandler(multigraphWidget.state.data, multigraphWidget.state.metas);
    setOption(newOption);
  }, [multigraphWidget.state.data, multigraphWidget.state.metas]);

  return (
    <div id={props.id} className="position-relative h-100">
      <AppixCharts option={option} />
    </div>
  );
};

export default MultiGraphWidget;
