import React from "react";
import { AppixSize } from "../types";

export interface AppixRangeSliderProps {
  label?: string;
  disabled: boolean;
  children?: any;
  size: keyof typeof AppixSize;
  step: number;
  min: number;
  max: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export const AppixRangeSlider: React.FC<AppixRangeSliderProps> = ({
  label,
  step = 1,
  min = 0,
  max = 100,
  size = "md",
  disabled = false,
  onChange,
  value,
  ...props
}) => {
  return (
    <div className="form-check d-flex align-items-center">
      <label className="form-label d-flex flex-column">
        <span className="p-1">{label}</span>
        <input
          {...props}
          min={min}
          max={max}
          step={step}
          type="range"
          className={`form-control-range slider-${size}`}
          disabled={disabled}
          onChange={onChange}
          value={value}
          style={{ height: "2px" }}
        />
      </label>
    </div>
  );
};
