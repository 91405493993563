import Appix from "Appix";
import RemixIcon from "components/RemixIcon";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormControlProps } from "./types";
import clsx from "clsx";
import { AppixTooltip } from "ui";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface AppixFileUploadProps {
  onChange?: any;
  value?: string;
  disabled?: boolean;
  id: string;
  validation?: string;
  isRequired?: boolean;
  copy?: boolean;
  clear?: boolean;
  helper?: string;
  type?: string;
  validator?: FormControlProps["validator"] | any;
  dataSubType?: string;
}

const fileExtensions = {
  ppt: "file-ppt",
  txt: "file-text",
  pdf: "file-pdf",
  doc: "file-word",
  docx: "file-word",
  xls: "file-excel",
  xlsx: "file-excel",
  csv: "file-excel",
  png: "image-2",
  jpg: "image-2",
  jpeg: "image-2",
  bmp: "image-2",
};

const AppixFileUploadInput: React.FC<AppixFileUploadProps> = ({
  validator,
  onChange,
  value,
  id,
  dataSubType,
  clear,
}) => {
  const description = useMemo(() => {
    return `Макс. размер: ${validator.maxSize ?? "неограничен"} кб, формат: ${
      validator?.extensions?.length ? validator?.extensions : "любой"
    }`;
  }, [validator]);

  const [currentFile, setCurrentFile] = useState<Record<string, any>>({});
  const [info, setInfo] = useState<string>("");
  const [infoType, setInfoType] = useState("");
  const [iconText, setIconText] = useState<string>("Выбрать файл");

  const inputRef: any = useRef(null);

  useEffect(() => {
    if (value && !currentFile.name) {
      const fileName = value.split("/").pop();
      setCurrentFile({ name: fileName });
    }
  }, [currentFile.name, value]);

  const handleUploadFile = useCallback(
    async ({ target }) => {
      const file = target.files[0];
      setInfoType("");
      setInfo("");
      if (file.size > validator.maxSize * 1024) {
        setInfo(`Размер файла превышает максимальное значение: ${validator.maxSize}КБ!`);
        setInfoType("error");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      setIconText("Загрузка...");
      try {
        const data = await Appix.fetch(validator?.uploadUrl, {
          data: formData,
          method: "PUT",
        });
        setCurrentFile(file);

        setInfo(`Файл ${file.name} успешно загружен`);

        setInfoType("success");

        onChange(data);
      } catch (error) {
        setInfo("Ошибка сервера" + JSON.stringify(error));
        setInfoType("error");
      }
      setIconText("Выбрать файл");
    },
    [validator, onChange],
  );

  const handleReset = useCallback(() => {
    setInfoType("");
    setInfo("");
    setCurrentFile({});
    inputRef.current.value = "";
    onChange && onChange(null);
  }, [onChange]);

  const acceptableExtensions = validator.extensions.map((extension) => `.${extension}`).join(", ");

  const getFileIcon = useCallback(() => {
    const currentFileExtension = currentFile?.name
      ? currentFile?.name.split(".").pop().toLowerCase()
      : "txt";
    return dataSubType === "IMAGE" ? "image" : fileExtensions[currentFileExtension];
  }, [currentFile.name, dataSubType]);

  return (
    <>
      <div className="d-flex">
        <div id={id} className="form-group__input-container form-file-uploader w-100">
          <input
            name={"file" + id}
            readOnly={true}
            placeholder="Файл не выбран"
            type="text"
            className={"form-control form-control_for-file"}
            value={currentFile.name ?? ""}
            autoComplete="off"
          />
          {clear && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 100 }}
              overlay={<Tooltip id="button-tooltip">Очистить</Tooltip>}
            >
              <button
                className="input-group-text form-control__btn-clear_file"
                onClick={handleReset}
              >
                <RemixIcon icon="close" iconSize="fw" />
              </button>
            </OverlayTrigger>
          )}
          <label
            className={"form-control__icons form-control__icons_after form-control__icons_file"}
          >
            <RemixIcon icon={getFileIcon()} />
            <span className="form-control__icons-text">{iconText}</span>
            <input
              ref={inputRef}
              className="d-none"
              type="file"
              accept={acceptableExtensions}
              onChange={handleUploadFile}
              autoComplete="off"
            />
          </label>
        </div>
        <AppixTooltip className="form-control__file-tooltip" label={description}>
          <div role="button" className="h-100">
            <RemixIcon icon={"information"} className="h-100" />
          </div>
        </AppixTooltip>
      </div>
      {info && (
        <small
          className={clsx("form-group__description", {
            "form-group__description_success": infoType === "success",
            "form-group__description_error": infoType === "error",
          })}
        >
          {info}
        </small>
      )}
    </>
  );
};

export default AppixFileUploadInput;
