import { FC, useMemo, useCallback, useState, useEffect } from "react";
import {
  TreeWidgetClass,
  INITIAL_TREETEXT_WIDGET_STATE as INITIAL_TREE_WIDGET_STATE,
  TreeWidgetNode,
} from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import { AppixTree, AppixTreeNodeState, AppixTreeOnChange, tree2list } from "ui";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const prepareData = (
  treeData: TreeWidgetNode[] = [],
  parentKey = "",
): AppixTreeNodeState<TreeWidgetNode>[] => {
  return treeData.map((item) => ({
    key: String(parentKey + item.id),
    parentKey,
    label: item.label,
    value: String(item.id),
    raw: item,
    opened: !!item.isExpanded,
    children: item.children?.length ? prepareData(item.children, String(parentKey + item.id)) : [],
  }));
};

const getCheckedRowsItems = (
  checkedKeys: string[],
  draftData: AppixTreeNodeState<TreeWidgetNode>[],
): TreeWidgetNode[] => {
  const list = tree2list(draftData);
  const checkedRowsList = checkedKeys.map((key) => list.find((item) => item.key === key)!);
  return checkedRowsList.map(({ raw }) => raw).filter((row) => !!row) as TreeWidgetNode[];
};

const TreeWidget: FC<AppixWidget.Tree> = (props) => {
  const widget = useWidget<AppixWidget.Tree, TreeWidgetClass>(
    props,
    INITIAL_TREE_WIDGET_STATE,
    TreeWidgetClass,
    [props.selectMode, props.api],
  );

  const { data, displayed, isLoading, selectMode, selectedRows } = widget.state;

  const draftData = useMemo<AppixTreeNodeState<TreeWidgetNode>[]>(() => {
    if (data) {
      return prepareData(data);
    }
    return [];
  }, [data]);

  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

  useEffect(() => {
    const list = tree2list(draftData);
    setCheckedKeys(selectedRows.map(({ id }) => list.find((item) => id === item.value)?.key || ""));
  }, [selectedRows, draftData]);

  const isClearControl = useMemo<boolean>(() => {
    return Boolean(selectMode?.control?.clear);
  }, [selectMode?.control?.clear]);

  const isSelectAllControl = useMemo<boolean>(() => {
    return Boolean(selectMode?.control?.selectAll);
  }, [selectMode?.control?.selectAll]);

  const isSubmitControl = useMemo<boolean>(() => {
    return Boolean(selectMode?.control?.submit);
  }, [selectMode?.control?.submit]);

  const handleTreeChange = useCallback<AppixTreeOnChange>(
    (nextCheckedKeys) => {
      setCheckedKeys(nextCheckedKeys);
      const nextSelectedRows = getCheckedRowsItems(nextCheckedKeys, draftData);
      widget.setSelectedRows(nextSelectedRows);
    },
    [draftData, widget],
  );

  const handleTreeClear = useCallback(() => {
    widget.clear();
  }, [widget]);

  const handleSelectAll = useCallback(() => {
    widget.selectAll();
  }, [widget]);

  const handleTreeSubmit = useCallback(() => {
    widget.submit();
  }, [widget]);

  return displayed ? (
    <>
      {isLoading && <Spinner />}
      <div className="position-relative">
        <CurrentWidgetInfo widget={widget} />

        {!isLoading && (
          <AppixTree<TreeWidgetNode>
            id={props.id}
            tree={draftData}
            checkable={selectMode?.enabled}
            checkedKeys={checkedKeys}
            isClearControl={isClearControl}
            isSelectAllControl={isSelectAllControl}
            isSubmitControl={isSubmitControl}
            onChange={handleTreeChange}
            onClear={handleTreeClear}
            onSelectAll={handleSelectAll}
            onSubmit={handleTreeSubmit}
          />
        )}
      </div>
    </>
  ) : null;
};

export default TreeWidget;
