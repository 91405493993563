import React from "react";

import { ITableWidgetActions } from "@appix/core";

import { AppixButton, AppixButtonColor } from "ui";

const getButtonColor = (action: string) => {
  if (action === "DELETE") {
    return AppixButtonColor.danger;
  } else if (action === "ADD") {
    return AppixButtonColor.primary;
  }
  return AppixButtonColor.dark;
};

interface TreetableWidgetGlobalActionsProps {
  widgetId: string;
  globalActions: ITableWidgetActions;
}

const TreetableWidgetGlobalActions: React.FC<TreetableWidgetGlobalActionsProps> = ({
  widgetId,
  globalActions,
}) => {
  return globalActions && Object.keys(globalActions).length > 0 ? (
    <div className="d-flex justify-content-end pb-2">
      {Object.keys(globalActions).map((actionKey) => (
        <AppixButton
          id={widgetId + (globalActions[actionKey].action || actionKey)}
          key={actionKey}
          size="sm"
          fullWidth={false}
          color={getButtonColor(actionKey)}
          onClick={globalActions[actionKey].cb}
          beforeIconCode={globalActions[actionKey].ico}
        >
          {globalActions[actionKey].title}
        </AppixButton>
      ))}
    </div>
  ) : null;
};

export default TreetableWidgetGlobalActions;
