import React, { useCallback } from "react";

interface QuartersProps {
  currentQuarter: number;
  setCurrentQuarter: (value: number) => void;
  onChange: (values: [Date, Date]) => void;
  currentValue: Date;
  getQuarterRange: (date: Date, quarter: number) => [Date, Date];
}

export const Quarters: React.FC<QuartersProps> = ({
  currentQuarter,
  setCurrentQuarter,
  onChange,
  currentValue,
  getQuarterRange,
}) => {
  const handleQuarterClick = useCallback(
    (e) => {
      setCurrentQuarter(e.target.value);
      onChange(getQuarterRange(currentValue, e.target.value));
    },
    [onChange, currentValue, setCurrentQuarter, getQuarterRange],
  );

  return (
    <div className="quarter-buttons-container">
      {[1, 2, 3, 4].map((quarter) => (
        <button
          key={quarter}
          className={`quarter-pick-button ${currentQuarter === quarter ? "current-quarter" : ""}`}
          value={quarter}
          onClick={handleQuarterClick}
        >
          Q{quarter}
        </button>
      ))}
    </div>
  );
};
