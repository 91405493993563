import { FC, useRef, useState, useCallback, useEffect } from "react";

import { Modal } from "bootstrap";

import { AppixButton } from "ui";

interface ConfirmModalProps {
  id: string;
  title: string;
  description?: string;
  opened?: boolean;
  onSubmit(): void;
  onCancel(): void;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  id,
  title,
  description,
  opened,
  onSubmit,
  onCancel,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const [bsModal, setBsModal] = useState<Modal | null>(null);

  const handleCloseDialogFromBack = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (e.target === e.currentTarget) {
        onCancel();
      }
    },
    [onCancel],
  );

  useEffect(() => {
    if (opened) {
      bsModal?.show();
    } else {
      bsModal?.hide();
    }
  }, [opened, bsModal]);

  useEffect(() => {
    const modalContainer = modalRef.current;
    if (modalContainer) {
      modalContainer.addEventListener("hide.bs.modal", onCancel);
    }
    return () => {
      if (modalContainer) {
        modalContainer.removeEventListener("hide.bs.modal", onCancel);
      }
      bsModal?.hide();
    };
  }, [bsModal, onCancel]);

  useEffect(() => {
    if (modalRef.current) {
      setBsModal(new Modal(modalRef.current));
    }
  }, []);

  return (
    <div
      ref={modalRef}
      className={"modal fade"}
      aria-modal="true"
      role="dialog"
      onClick={handleCloseDialogFromBack}
    >
      <div className="modal-dialog">
        <div className="modal-content text-center">
          <div className="modal-close d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onCancel}
              id={id + "CLOSE"}
            />
          </div>

          <div className="info-icon">
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="32"
                cy="32"
                r="30.2222"
                fill="#4285F4"
                fillOpacity="0.12"
                stroke="#4285F4"
                strokeWidth="3.55556"
              />
              <path
                d="M29 17C29 15.3431 30.3431 14 32 14C33.6569 14 35 15.3431 35 17V18.2C35 19.8569 33.6569 21.2 32 21.2C30.3431 21.2 29 19.8569 29 18.2V17ZM29 29.6C29 27.9431 30.3431 26.6 32 26.6C33.6569 26.6 35 27.9431 35 29.6V47C35 48.6569 33.6569 50 32 50C30.3431 50 29 48.6569 29 47V29.6Z"
                fill="#4285F4"
              />
            </svg>
          </div>

          <div className="modal-header justify-content-center d-block">
            <h5 className="modal-confirm-title">{title}</h5>
            {description ? <p className="modal-text">{description}</p> : null}
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-6">
                <AppixButton
                  id={id + "DELETE_CANCEL"}
                  color="secondary"
                  onClick={onCancel}
                  size={"sm"}
                  fullWidth
                >
                  Нет
                </AppixButton>
              </div>
              <div className="col-6">
                <AppixButton
                  id={id + "DELETE_SUBMIT"}
                  color="primary"
                  onClick={onSubmit}
                  size={"sm"}
                  fullWidth
                >
                  Да
                </AppixButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
