import React from "react";
import ReactMarkdown from "react-markdown";
import clsx from "clsx";
import { TreeTextWidgetClass, INITIAL_TREETEXT_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import remarkGfm from "remark-gfm";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

interface TreetextWidgetProps extends Partial<AppixWidget.TreeText> {}

const TreetextWidget: React.FC<TreetextWidgetProps> = (props) => {
  const treeTextWidget = useWidget<AppixWidget.TreeText>(
    props,
    INITIAL_TREETEXT_WIDGET_STATE,
    TreeTextWidgetClass,
    [props.items],
  );

  return (
    <div className="position-relative">
      <CurrentWidgetInfo widget={treeTextWidget} />

      <ul
        id={props.id}
        className={clsx("ps-0", {
          "d-none": !treeTextWidget.state.displayed,
          "d-block": treeTextWidget.state.displayed,
        })}
      >
        {treeTextWidget.state.isLoading && (
          <li>
            <Spinner />
          </li>
        )}
        {!treeTextWidget.state.isLoading &&
          treeTextWidget.state.items.map((item, index) => {
            const valueString = `${item.bullet} ${item.value}`;
            return (
              <li key={index} className="list-group-item border-0 py-0">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{valueString}</ReactMarkdown>
                {item.children.length > 0 && (
                  <TreetextWidget id={props.id + index} items={item.children} />
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default TreetextWidget;
