import React, { useCallback } from "react";

import { AppixButton, AppixButtonProps, AppixButtonColor } from "../AppixButton";
import { AppixSize } from "../types";

type AppixSwitcherValue = string | number;

interface IAppixSwitcherItem extends AppixButtonProps {
  title?: string;
  value: AppixSwitcherValue;
}

export interface AppixSwitcherProps {
  options: Array<IAppixSwitcherItem>;
  size?: keyof typeof AppixSize;
  onChange?: (value: AppixSwitcherValue) => void;
  value?: AppixSwitcherValue;
  color?: keyof typeof AppixButtonColor;
}

export const AppixSwitcher: React.FC<AppixSwitcherProps> = ({
  options,
  onChange,
  value,
  size = "md",
  color = "primary",
}) => {
  const handleChange = useCallback(
    (itemValue) => {
      return () => {
        onChange && onChange(itemValue);
      };
    },
    [onChange],
  );

  return (
    <div className="btn-group" role="group">
      {options.map(({ title, value: itemValue }) => (
        <AppixButton
          key={itemValue}
          color={color}
          onClick={handleChange(itemValue)}
          size={size}
          active={value === itemValue}
        >
          <input
            type="radio"
            className="visually-hidden"
            value={itemValue}
            checked={itemValue === value}
            readOnly
          />
          {title}
        </AppixButton>
      ))}
    </div>
  );
};
