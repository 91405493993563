import React, { useCallback } from "react";
import MenuItem from "./MenuItem";
import { NavChildrenProps } from "./types";
import Appix from "Appix";
import { collapseSlashes } from "utils";
import RemixIcon from "components/RemixIcon";
import clsx from "clsx";

const NavChildren: React.FC<NavChildrenProps> = ({
  item,
  index,
  currentLocation,
  onItemClick,
  className,
  id,
  handleSetExpanded,
  expandedItemsData,
  lastChild,
  ico,
  isSmall,
}) => {
  const baseUrl = Appix.baseUrl;
  const nextRoute = collapseSlashes(baseUrl + "/content" + item.fullpath);
  const itemSaveId = id;

  const isActive = window.location.pathname.includes(nextRoute);
  const hasActivePage = item.fullpath && window.location.pathname.includes(nextRoute);

  const handleItemClick = useCallback(
    (e) => {
      e.preventDefault();
      item.title && item.fullpath && onItemClick(nextRoute);
    },
    [item, onItemClick, nextRoute],
  );

  const isExpanded = Boolean(expandedItemsData?.[itemSaveId] ?? item.expanded);

  const handleSetExpandedCallback = useCallback(() => {
    handleSetExpanded && handleSetExpanded(itemSaveId, Boolean(!isExpanded));
  }, [handleSetExpanded, itemSaveId, isExpanded]);

  if (!item.title) {
    return null;
  }

  return (
    <div
      className={clsx(`accordion-item bg-transparent border-0 rounded-0 ${className}`, {
        "accordion-item-sm": isSmall,
      })}
    >
      {item.children?.length ? (
        <>
          <h2 className={`accordion-header ${className}`}>
            <div
              className={clsx(`accordion-button bg-transparent appix-menu-item sub-item`, {
                collapsed: isExpanded,
                "appix-menu-item-sm": isSmall,
                active: hasActivePage,
              })}
              role="button"
              aria-expanded={isExpanded}
              aria-controls={`collapse${id}${index}`}
              onClick={handleSetExpandedCallback}
            >
              <div className="appix-menu-item__icon-wrapper">
                <RemixIcon icon={item.ico || null} />
              </div>
              <span className={`m-0`}>{item.title}</span>
            </div>
          </h2>
          <div
            id={`collapse${id}${index}`}
            className={`accordion-collapse collapse ${className} ${isExpanded ? "show" : ""}`}
          >
            <div className={className}>
              {item.children.map((child, index) => (
                <MenuItem
                  className={"sub-item"}
                  key={index}
                  item={child}
                  index={index}
                  onItemClick={onItemClick}
                  currentLocation={currentLocation}
                  id={child.fullpath ?? id + index}
                  handleSetExpanded={handleSetExpanded}
                  expandedItemsData={expandedItemsData}
                  paddingClass={className}
                  lastChild={item.children && item.children.length - 1 === index}
                  isSmall={isSmall}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <a
            href={nextRoute}
            className={`text-decoration-none list-group-item border-0 p-0  ${
              isActive ? "active" : ""
            }`}
            onClick={handleItemClick}
          >
            <div
              className={clsx("accordion-body p-0 appix-menu-margin", {
                "appix-menu-margin-sm": isSmall,
              })}
            >
              <div
                className={clsx("d-flex appix-menu-item sub-item align-items-start", {
                  "appix-menu-item-sm": isSmall,
                })}
              >
                <div className="appix-menu-item__icon-wrapper">
                  <RemixIcon icon={item.ico || null} />
                </div>
                <span>{item.title}</span>
              </div>
            </div>
          </a>
        </>
      )}
    </div>
  );
};

export default NavChildren;
