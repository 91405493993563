import { useEffect, useLayoutEffect, useState } from "react";

import { ChainEditor } from "@appix/chain-editor";
import "@appix/chain-editor/dist/styles.css";

import { widgetNodesToEditorNodes } from "./widgetNodeToEditorNode";
import { editorNodesToWidgetNodes, editorNodeToWidgetNode } from "./editorNodeToWidgetNode";

export function useChainEditor(
  container: HTMLElement | null,
  {
    id,
    nodes,
    readOnly,
  }: {
    id: string;
    nodes: AppixWidget.Chain.Node[];
    readOnly?: boolean;
  },
  {
    onNodesChange,
    onNodeRightClick,
    onNodeDoubleClick,
    onNodeDelete,
    onNodeSetting,
    onReservedSlotClick,
  }: {
    onNodesChange?(nodes: AppixWidget.Chain.Node[]): void;
    onNodeRightClick?(node: AppixWidget.Chain.Node, e: MouseEvent): void;
    onNodeDoubleClick?(node: AppixWidget.Chain.Node, e: MouseEvent): void;
    onNodeDelete?(node: AppixWidget.Chain.Node): void;
    onNodeSetting?(node: AppixWidget.Chain.Node): void;
    onReservedSlotClick?(a: {
      node: AppixWidget.Chain.Node;
      connection: AppixWidget.Chain.Connection;
    }): void;
  },
) {
  const [editor, setEditor] = useState<ChainEditor | null>(null);

  useLayoutEffect(() => {
    const _editor = new ChainEditor(container, {
      id,
      readOnly,
    });
    _editor.mount();
    setEditor(_editor);

    return () => {
      _editor?.unmount();
      setEditor(null);
    };
  }, [id, readOnly, container]);

  useLayoutEffect(() => {
    if (editor) {
      editor.onNodesChange = (nodes) => onNodesChange?.(editorNodesToWidgetNodes(nodes));
      editor.onNodeRightClick = (node, e) => onNodeRightClick?.(editorNodeToWidgetNode(node), e);
      editor.onNodeDoubleClick = (node, e) => onNodeDoubleClick?.(editorNodeToWidgetNode(node), e);
      editor.onNodeDelete = (node) => onNodeDelete?.(editorNodeToWidgetNode(node));
      editor.onNodeSetting = (node) => onNodeSetting?.(editorNodeToWidgetNode(node));
      editor.onReservedSlotClick = ({ node, connection }) =>
        onReservedSlotClick?.({ node, connection });
    }
  }, [
    editor,
    onNodesChange,
    onNodeRightClick,
    onNodeDoubleClick,
    onNodeDelete,
    onNodeSetting,
    onReservedSlotClick,
  ]);

  useEffect(() => {
    editor?.setNodes(widgetNodesToEditorNodes(nodes));
  }, [editor, nodes]);

  return editor;
}
