import React, { useMemo, useCallback } from "react";

import HistogramMonitorWidget from "./HistogramMonitorWidget";
import LinesMonitorWidget from "./LinesMonitorWidget";
import LinesMonitorWidgetBreakdown from "./LinesMonitorWidgetBreakdown";
import TableMonitorWidget from "./TableMonitorWidget";
import TableMonitorWidgetBreakdown from "./TableMonitorWidgetBreakdown";
import TableMonitorWidgetExpandableSeries from "./TableMonitorWidgetExpandableSeries";
import TableMonitorWidgetTimeseries from "./TableMonitorWidgetTimeseries";
import TimeSeriesMonitorWidget from "./TimeSeriesMonitorWidget";
import ValueMonitorWidget from "./ValueMonitorWidget";
import {
  MonitorWidgetClass,
  INITIAL_MONITOR_WIDGET_STATE,
  MonitorWidgetExpandableSeriesValueFieldData,
} from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import GaugeMonitorWidget from "./GaugeMonitorWidget";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const MonitorWidget: React.FC<AppixWidget.Monitor> = (props) => {
  const monitorWidget = useWidget<AppixWidget.Monitor>(
    props,
    INITIAL_MONITOR_WIDGET_STATE,
    MonitorWidgetClass,
    [props.monitorCrud, props.monitorData],
  );

  const handleExpSeriesDoubleClick = useCallback(
    (data: MonitorWidgetExpandableSeriesValueFieldData) => {
      monitorWidget.expSeriesDoubleClick(data);
    },
    [monitorWidget],
  );

  const monitor = useMemo(() => {
    if (!monitorWidget.state.displayed) {
      return <></>;
    }

    if (monitorWidget.state.isLoading) {
      return <Spinner />;
    }
    if (
      monitorWidget.state.monitorData &&
      (monitorWidget.state.monitorExtendedData || monitorWidget.state.monitorExtendedData === 0)
    ) {
      switch (monitorWidget.state.monitorData.visualizationType) {
        case "SIMPLE_VALUE":
          return (
            <ValueMonitorWidget
              {...monitorWidget.state.monitorData}
              id={monitorWidget.state.id}
              extendedData={monitorWidget.state.monitorExtendedData}
            />
          );
        case "SIMPLE_LINES":
          switch (monitorWidget.state.monitorData.dataType) {
            case "TIME_SERIES":
              return (
                <TimeSeriesMonitorWidget
                  // {...monitorWidget.state.monitorData}
                  id={monitorWidget.state.id}
                  // extendedData={monitorWidget.state.monitorExtendedData}
                  state={monitorWidget.state}
                />
              );
            case "CATEGORIES":
              return (
                <LinesMonitorWidget
                  // {...monitorWidget.state.monitorData}
                  id={monitorWidget.state.id}
                  // extendedData={monitorWidget.state.monitorExtendedData}
                  state={monitorWidget.state}
                />
              );
            case "CATEGORIES_BREAKDOWN":
              return (
                <LinesMonitorWidgetBreakdown
                  // {...monitorWidget.state.monitorData}
                  id={monitorWidget.state.id}
                  // extendedData={monitorWidget.state.monitorExtendedData}
                  state={monitorWidget.state}
                />
              );
            default:
              break;
          }
          break;
        case "SIMPLE_HISTOGRAM":
        case "HORIZONTAL_HISTOGRAM":
        case "SIMPLE_PIE":
          return (
            <HistogramMonitorWidget
              // {...monitorWidget.state.monitorData}
              id={monitorWidget.state.id}
              // extendedData={monitorWidget.state.monitorExtendedData}
              state={monitorWidget.state}
            />
          );
        case "TABLE":
          switch (monitorWidget.state.monitorData.dataType) {
            case "TIME_SERIES":
              return (
                <TableMonitorWidgetTimeseries
                  {...monitorWidget.state.monitorData}
                  id={monitorWidget.state.id}
                  extendedData={monitorWidget.state.monitorExtendedData}
                />
              );
            case "CATEGORIES":
              return (
                <TableMonitorWidget
                  {...monitorWidget.state.monitorData}
                  id={monitorWidget.state.id}
                  extendedData={monitorWidget.state.monitorExtendedData}
                />
              );
            case "CATEGORIES_BREAKDOWN":
              return (
                <TableMonitorWidgetBreakdown
                  {...monitorWidget.state.monitorData}
                  id={monitorWidget.state.id}
                  extendedData={monitorWidget.state.monitorExtendedData}
                />
              );
            case "EXPANDABLE_SERIES":
              return (
                <TableMonitorWidgetExpandableSeries
                  {...monitorWidget.state.monitorData}
                  id={monitorWidget.state.id}
                  handleCustomAction={monitorWidget.handleCustomAction}
                  extendedData={monitorWidget.state.monitorExtendedData}
                  ctx={monitorWidget.state.ctx}
                  onDoubleClick={handleExpSeriesDoubleClick}
                />
              );
            default:
              break;
          }
          break;
        case "SIMPLE_GAUGE":
          return (
            <GaugeMonitorWidget
              {...monitorWidget.state.monitorData}
              id={monitorWidget.state.id}
              extendedData={monitorWidget.state.monitorExtendedData}
            />
          );
        default:
      }
    }
  }, [monitorWidget.handleCustomAction, monitorWidget.state, handleExpSeriesDoubleClick]);

  return monitor ? (
    <div className="position-relative h-100">
      <CurrentWidgetInfo widget={monitorWidget} />

      {monitor}
    </div>
  ) : (
    <p>&lt;Unknown monitor&gt;</p>
  );
};
export default MonitorWidget;
