import React, { FC, DetailedHTMLProps, InputHTMLAttributes, useCallback } from "react";
import clsx from "clsx";
import { AppixBaseInputProps, AppixSize, AppixValidation } from "ui";
import moment from "moment";
import { AppixWidgetValidator } from "@appix/core";
import Appix from "Appix";

export interface IAppixDateTimePickerProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    AppixBaseInputProps {
  value: string | number;
  label?: string;
  sizing?: keyof typeof AppixSize;
  validation?: keyof typeof AppixValidation;
  helper?: string;
  isRequired?: boolean;
  validator?: AppixWidgetValidator;
  copy?: boolean;
}

export const AppixDateTimePicker: FC<IAppixDateTimePickerProps> = ({
  value = "",
  id,
  className,
  label = "",
  sizing = "md",
  validation,
  helper = "",
  isRequired = false,
  type = "datetime-local",
  validator,
  onChange,
  copy,
  ...inputProps
}) => {
  const handleCopy = useCallback(
    (e) => {
      e.preventDefault();
      value &&
        navigator.clipboard
          .writeText(
            moment(value).format(`DD.MM.YYYY${type === "datetime-local" ? ", HH:mm:ss" : ""}`),
          )
          .then(() => {
            Appix.showSuccess("дата скопирована в буфер обмена!", true);
          })
          .catch((e) => {
            Appix.showError("Не удалось скопировать дату!", true);
          });
    },
    [type, value],
  );

  const getValue = () =>
    type === "datetime-local"
      ? value?.toString()?.replace("Z", "")
      : value?.toString().split("T")[0];

  return (
    <>
      <div className="input-group">
        <input
          type={type}
          autoComplete="off"
          max={
            validator?.maxDate?.value
              ? validator.maxDate.value.replaceAll("Z", "")
              : `9999-12-31${type === "datetime-local" ? "T23:59:59" : ""}`
          }
          min={
            validator?.minDate?.value
              ? validator.minDate.value.replaceAll("Z", "")
              : `1900-01-01${type === "datetime-local" ? "T00:00" : ""}`
          }
          onChange={onChange}
          onBlur={onChange}
          {...inputProps}
          id={id}
          value={getValue()}
          className={
            className
              ? className
              : clsx("form-control", {
                  "form-control-sm": sizing === AppixSize.sm,
                  "form-control-lg": sizing === AppixSize.lg,
                  "is-valid": validation === AppixValidation.success,
                  "is-warning": validation === AppixValidation.warning,
                  "is-invalid": validation === AppixValidation.error,
                })
          }
        />
        {copy && (
          <button className="input-group-text" onClick={handleCopy}>
            <i className="ri-file-copy-line"></i>
          </button>
        )}
      </div>
    </>
  );
};
