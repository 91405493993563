import { FC, useCallback } from "react";

import { AppixModal } from "ui";

import FormWidget from "../FormWidget";

interface ChainConnectionEditModalProps {
  id: string;
  node: AppixWidget.Chain.Node;
  connection: AppixWidget.Chain.Connection;
  isShown: boolean;
  onClose(): void;
  onSubmit(id: string, node: AppixWidget.Chain.Node): void;
}

const ChainConnectionEditModal: FC<ChainConnectionEditModalProps> = ({
  id,
  node,
  connection,
  isShown,
  onClose,
  onSubmit,
}) => {
  const handleSubmit = useCallback<(data: Record<string, any>) => void>(
    (data) => {
      const nextData: AppixWidget.Chain.Node = JSON.parse(JSON.stringify(node));
      nextData.connections?.forEach((conn, index) => {
        if (conn.nodeId === connection.nodeId) {
          nextData.connections[index].details = {
            ...nextData.connections[index].details,
            data,
          };
        }
      });
      onSubmit(node.id!, nextData);
    },
    [node, connection, onSubmit],
  );

  return (
    <AppixModal
      id={`${id}_MODAL`}
      isShown={isShown}
      type="MODAL"
      size={null}
      onClose={onClose}
      caption={connection.details.title ?? "Редактирование связи"}
    >
      <FormWidget
        {...connection.details.formWidget}
        id={`${id}_FORM`}
        data={connection.details.data}
        form={{
          ...connection.details.formWidget?.form,
          actions: [
            {
              text: "Применить",
              event: "nodeChanged",
              postUrl: "",
              customAction: handleSubmit,
            },
          ],
          dataDefaults: {
            ...connection.details.formWidget?.form.dataDefaults,
            ...connection.details.data,
          },
        }}
      />
    </AppixModal>
  );
};

export default ChainConnectionEditModal;
