import React, { useCallback } from "react";
import { collapseSlashes } from "utils";
import { ICrumb } from ".";

export interface AppixBreadcrumbsItemProps {
  item: ICrumb;
  i: number;
  onClick(path: string): void;
  isLink: boolean;
  baseUrl: string;
}

export const AppixBreadcrumbsItem: React.FC<AppixBreadcrumbsItemProps> = ({
  item,
  i,
  onClick,
  isLink,
  baseUrl,
}) => {
  const nextRoute = collapseSlashes(baseUrl + (item.value ? `/content/${item.value}` : "/"));

  const handleCrumbClick = useCallback(
    (e: React.SyntheticEvent): void => {
      e.preventDefault();
      onClick(nextRoute);
    },
    [onClick, nextRoute],
  );

  return (
    <li className="breadcrumb-item">
      {isLink && item.hasPage ? (
        <a href={nextRoute} onClick={handleCrumbClick}>
          {item.label}
        </a>
      ) : (
        item.label
      )}
    </li>
  );
};
