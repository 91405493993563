import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";

import { AppixPopoverProps, AppixPopoverCoord } from "./types";

function getOffsetRect(elem: HTMLElement) {
  const box = elem.getBoundingClientRect();

  const body = document.body;
  const docElem = document.documentElement;

  const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;

  const clientTop = docElem.clientTop || body.clientTop || 0;
  const clientLeft = docElem.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

export const AppixPopover: React.FC<AppixPopoverProps> = ({
  id,
  placement = "right-start",
  trigger = "hover",
  withArrow,
  PopoverContent,
  children,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const popoverRootRef = useRef<HTMLDivElement>(null);

  // координаты относительно body
  const [popoverCoord, setPopoverCoord] = useState<AppixPopoverCoord | null>();
  // координаты относительно popover
  const [popoverArrowCoord, setPopoverArrowCoord] = useState<AppixPopoverCoord | null>();
  const [isShowed, setIsShowed] = useState<boolean>(false);

  const close = useCallback(() => {
    setIsShowed(false);
    setPopoverCoord(null);
  }, []);

  // вычисление координат поповера
  const makePopoverCoord = useCallback(() => {
    const { current: $root } = rootRef;
    const { current: $popover } = popoverRootRef;

    if (!$root || !$popover) return null;

    const rect = getOffsetRect($root);
    let x = rect.left;
    let y = rect.top;

    switch (placement) {
      case "top-start":
        y -= $popover.clientHeight;
        setPopoverArrowCoord([$root.clientWidth / 2, $popover.clientHeight]);
        break;
      case "top-center":
        x += ($root.clientWidth - $popover.clientWidth) / 2;
        y -= $popover.clientHeight;
        setPopoverArrowCoord([$popover.clientWidth / 2, $popover.clientHeight]);
        break;
      case "top-end":
        x -= $popover.clientWidth - $root.clientWidth;
        y -= $popover.clientHeight;
        setPopoverArrowCoord([$popover.clientWidth - $root.clientWidth / 2, $popover.clientHeight]);
        break;
      case "right-start":
        x += $root.clientWidth;
        setPopoverArrowCoord([0, $root.clientHeight / 2]);
        break;
      case "right-center":
        x += $root.clientWidth;
        y += ($root.clientHeight - $popover.clientHeight) / 2;
        setPopoverArrowCoord([0, $popover.clientHeight / 2]);
        break;
      case "right-end":
        x += $root.clientWidth;
        y -= $popover.clientHeight - $root.clientHeight;
        setPopoverArrowCoord([0, $popover.clientHeight - $root.clientHeight / 2]);
        break;
      case "bottom-start":
        y += $root.clientHeight;
        setPopoverArrowCoord([$root.clientWidth / 2, 0]);
        break;
      case "bottom-center":
        x += ($root.clientWidth - $popover.clientWidth) / 2;
        y += $root.clientHeight;
        setPopoverArrowCoord([$popover.clientWidth / 2, 0]);
        break;
      case "bottom-end":
        x -= $popover.clientWidth - $root.clientWidth;
        y += $root.clientHeight;
        setPopoverArrowCoord([$popover.clientWidth - $root.clientWidth / 2, 0]);
        break;
      case "left-start":
        x -= $popover.clientWidth;
        setPopoverArrowCoord([$popover.clientWidth, $root.clientHeight / 2]);
        break;
      case "left-center":
        x -= $popover.clientWidth;
        y += ($root.clientHeight - $popover.clientHeight) / 2;
        setPopoverArrowCoord([$popover.clientWidth, $popover.clientHeight / 2]);
        break;
      case "left-end":
        x -= $popover.clientWidth;
        y -= $popover.clientHeight - $root.clientHeight;
        setPopoverArrowCoord([
          $popover.clientWidth,
          $popover.clientHeight - $root.clientHeight / 2,
        ]);
        break;
      default:
    }

    setPopoverCoord([x, y]);
  }, [placement]);

  const handleHover = useCallback<React.MouseEventHandler<HTMLDivElement>>(() => {
    if (trigger === "hover") {
      setIsShowed(true);
    }
  }, [trigger]);

  const handleUnhover = useCallback<React.MouseEventHandler<HTMLDivElement>>(() => {
    if (trigger === "hover") {
      setIsShowed(false);
    }
  }, [trigger]);

  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(() => {
    if (trigger === "click") {
      setIsShowed((prev) => !prev);
    }
  }, [trigger]);

  // при открытии, высчитываем координаты
  useLayoutEffect(() => {
    if (isShowed && popoverRootRef?.current) {
      makePopoverCoord();
    }
  }, [isShowed, makePopoverCoord]);

  // закрываем поповер при клике вне его
  useLayoutEffect(() => {
    const handleClickAway = (e: MouseEvent) => {
      if (
        rootRef.current &&
        e?.target &&
        !rootRef.current.contains(e.target as Node) &&
        // и если то, на что кликнули, еще есть на странице,
        // а не пропало (например item в селекте, который закрылся)
        document.body.contains(e.target as Node)
      ) {
        close();
      }
    };

    if (trigger === "click") {
      window.document.addEventListener("click", handleClickAway);
    }

    return () => {
      window.document.removeEventListener("click", handleClickAway);
    };
  }, [trigger, close]);

  // убираем поповер при любом скролле и клике на скрол
  useLayoutEffect(() => {
    const handleScroll = (e: Event) => {
      close();
    };

    const mouseDown = (e: Event) => {
      if (
        popoverRootRef.current &&
        e?.target &&
        !popoverRootRef.current.contains(e.target as Node)
      ) {
        close();
      }
    };

    document.addEventListener("wheel", handleScroll, true);
    document.addEventListener("mousedown", mouseDown, true);
    return () => {
      document.addEventListener("mousedown", mouseDown, true);
      document.addEventListener("wheel", handleScroll, true);
    };
  }, [close]);

  // пересчет координат при изменении размера поповера
  useLayoutEffect(() => {
    const { current: $popover } = popoverRootRef;
    let obs: ResizeObserver;
    if ($popover) {
      const handleResize = () => {
        makePopoverCoord();
      };
      handleResize();
      obs = new ResizeObserver(handleResize);
      obs.observe($popover);
    }
    return () => {
      if (obs && $popover) {
        obs.unobserve($popover);
      }
    };
  }, [isShowed, makePopoverCoord]);

  return (
    <div ref={rootRef} onMouseOver={handleHover} onMouseOut={handleUnhover} onClick={handleClick}>
      {children}
      {isShowed && PopoverContent
        ? ReactDOM.createPortal(
            <div
              id={id}
              ref={popoverRootRef}
              className={clsx("appix-popover", {
                "appix-popover_with-arrow": withArrow,
                [`appix-popover_${placement}`]: !!placement,
              })}
              style={{
                left: popoverCoord ? `${popoverCoord[0]}px` : undefined,
                top: popoverCoord ? `${popoverCoord[1]}px` : undefined,
              }}
            >
              {PopoverContent}

              {withArrow ? (
                <div
                  className="appix-popover__arrow"
                  style={{
                    left: popoverArrowCoord ? `${popoverArrowCoord[0]}px` : undefined,
                    top: popoverArrowCoord ? `${popoverArrowCoord[1]}px` : undefined,
                  }}
                />
              ) : null}
            </div>,
            window.document.body,
          )
        : null}
    </div>
  );
};
