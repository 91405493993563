import React from "react";
import clsx from "clsx";

export type AppixRadioValue = string | number;

export interface AppixRadioItem
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  value: AppixRadioValue;
}

export interface AppixRadioProps {
  id?: string;
  options: Array<AppixRadioItem>;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: AppixRadioValue;
  inlined?: boolean;
}

export const AppixRadio: React.FC<AppixRadioProps> = ({
  id,
  options,
  name,
  onChange,
  value,
  inlined,
}) => {
  const draftId = React.useMemo<string>(() => {
    return id || `radio-${Math.random()}`;
  }, [id]);

  const draftName = React.useMemo<string>(() => {
    return name || `radio-name-${Math.random()}`;
  }, [name]);

  return (
    <>
      {options.map(({ label, ...radioItemProps }, index) => (
        <div
          key={radioItemProps.id || index}
          className={clsx("form-check", {
            "form-check-inline": Boolean(inlined),
          })}
        >
          <input
            onChange={onChange}
            checked={value === radioItemProps.value}
            {...radioItemProps}
            id={draftId + "-" + index}
            className="form-check-input"
            type="radio"
            name={draftName}
          />
          <label htmlFor={draftId + "-" + index} className="form-check-label">
            {label}
          </label>
        </div>
      ))}
    </>
  );
};
