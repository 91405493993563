import { FC, useCallback, useMemo } from "react";
import { AppixButton, AppixButtonColor, AppixSize, AppixTooltip } from "ui";
import { ButtonWidgetClass, INITIAL_BUTTON_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

interface ButtonWidgetProps extends AppixWidget.Button {
  size?: AppixSize;
}

const ButtonWidget: FC<ButtonWidgetProps> = (props) => {
  const buttonWidget = useWidget<ButtonWidgetProps, ButtonWidgetClass>(
    props,
    INITIAL_BUTTON_WIDGET_STATE,
    ButtonWidgetClass,
    [props.customAction, props.text],
  );

  const { displayed } = buttonWidget.state;
  const buttonSize = props.size || "sm";
  const buttonHint = buttonWidget.state.customAction?.hint;

  const getButtonColor = useCallback(() => {
    return buttonWidget.state.customAction?.color
      ? AppixButtonColor[buttonWidget.state.customAction?.color?.toLowerCase()]
      : "primary";
  }, [buttonWidget.state.customAction?.color]);

  const Button = useMemo(
    () => (
      <AppixButton
        id={props.id}
        onClick={buttonWidget.handleClick}
        size={buttonSize}
        color={getButtonColor()}
        beforeIconCode={buttonWidget.state.customAction?.ico}
        counter={buttonWidget.state.counter}
      >
        {buttonWidget.state.text}
      </AppixButton>
    ),
    [
      buttonSize,
      buttonWidget.handleClick,
      buttonWidget.state.counter,
      buttonWidget.state.customAction?.ico,
      buttonWidget.state.text,
      getButtonColor,
      props.id,
    ],
  );

  return displayed ? (
    <div className="position-relative">
      <CurrentWidgetInfo widget={buttonWidget} />

      {buttonWidget.state.isLoading && <Spinner />}
      {!buttonWidget.state.isLoading && !buttonHint && Button}
      {!buttonWidget.state.isLoading && buttonHint && (
        <AppixTooltip placement="top" label={buttonHint}>
          {Button}
        </AppixTooltip>
      )}
    </div>
  ) : null;
};

export default ButtonWidget;
