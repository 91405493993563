import { FC, MouseEventHandler, useCallback, useMemo } from "react";

import RemixIcon from "components/RemixIcon";

import { FormControlProps } from "../types";
import { FormWidget } from "widgets";

interface ListValueItemProps extends FormControlProps {
  index: number;
  value: Record<string, any>;
  onRemove: (index: number) => void;
  data?: Record<string, any>;
  dependencies?: string[];
}

const ListValueItem: FC<ListValueItemProps> = ({
  subFields,
  index,
  value,
  onChange,
  onRemove,
  data,
  dependencies,
  ...otherProps
}) => {
  const { id, ctx, dynamicProperties } = otherProps;

  const handleRemove = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
    onRemove(index);
  }, [index, onRemove]);

  const handleChange = useCallback<(a: Record<string, any>) => void>(
    (nextData) => {
      onChange({
        ...value,
        ...nextData,
      });
    },
    [value, onChange],
  );

  const mappedFields: any =
    subFields?.map((field) => ({
      ...field,
      mapping: { first: field.name, second: field.label },
    })) || [];

  const dependencyData = useMemo(() => {
    return dependencies?.reduce((acc, dep) => {
      acc[dep] = data?.[dep];
      return acc;
    }, {});
  }, [dependencies, data]);

  return (
    <>
      <div className="list-value__item">
        <div className="list-value__item__header">
          <span className="list-value__item__title">Вложенная форма</span>
          <div
            role="button"
            id={`${id}X${index}`}
            onClick={handleRemove}
            className="list-value__item__delete"
          >
            <RemixIcon icon="delete-bin-6" />
          </div>
        </div>
        <div className="list-value__item__fields">
          <FormWidget
            id={id + index}
            form={{
              columns: mappedFields,
              presence: mappedFields.map((field) => field.name),
              dynamicProperties,
              ctx: ctx,
            }}
            isSubForm={true}
            data={value}
            parentData={data}
            isLoading={false}
            type="FORM"
            displayed={true}
            onChange={handleChange}
            ctx={{ ...ctx, dependencies: dependencyData }}
          />
        </div>
      </div>
    </>
  );
};

ListValueItem.defaultProps = {
  value: {},
};

export default ListValueItem;
