import Prism from "prismjs";
import React, { useEffect } from "react";
import clsx from "clsx";

import "prismjs/components/prism-yaml";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-kotlin";
import "prismjs/components/prism-python";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-java";
import "prismjs/components/prism-json";
import { CodeWidgetClass, INITIAL_CODE_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const CodeWidget: React.FC<AppixWidget.Code> = (props) => {
  const codeWidget = useWidget<AppixWidget.Code>(
    props,
    INITIAL_CODE_WIDGET_STATE,
    CodeWidgetClass,
    [props.code, props.language],
  );

  useEffect(() => {
    Prism.highlightAll();
  }, [codeWidget.state]);

  return (
    <div className="w-100 position-relative">
      <CurrentWidgetInfo widget={codeWidget} />

      {codeWidget.state.isLoading && <Spinner />}
      {!codeWidget.state.isLoading && (
        <div
          className={clsx("pb-1 code-widget", {
            "d-none": !codeWidget.state.displayed,
            "d-block": codeWidget.state.displayed,
          })}
        >
          <pre className={"m-0 rounded-2"}>
            <code
              id={props.id}
              className={`language-${
                codeWidget.state.language === "sh" ? "shell" : codeWidget.state.language
              }`}
            >
              {codeWidget.state.code?.trim()}
            </code>
          </pre>
        </div>
      )}
    </div>
  );
};

export default CodeWidget;
