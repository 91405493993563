import React from "react";
import clsx from "clsx";
import { VideoWidgetClass, INITIAL_VIDEO_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const VideoWidget: React.FC<AppixWidget.Video> = (props) => {
  const videoWidget = useWidget<AppixWidget.Video>(
    props,
    INITIAL_VIDEO_WIDGET_STATE,
    VideoWidgetClass,
    [props.h, props.w, props.url],
  );

  return (
    <div
      className={clsx("my-1 position-relative", {
        "d-none": !videoWidget.state.displayed,
        "d-block": videoWidget.state.displayed,
      })}
      style={{
        height: videoWidget.state.h ? `${videoWidget.state.h + "px"}` : "500px",
        width: videoWidget.state.w ? `${videoWidget.state.w + "px"}` : "auto",
      }}
    >
      <CurrentWidgetInfo widget={videoWidget} />

      {videoWidget.state.isLoading && <Spinner />}
      {!videoWidget.state.isLoading && (
        <iframe
          title={props.id}
          src={videoWidget.state.url}
          height={videoWidget.state.h || "100%"}
          width={videoWidget.state.w || "100%"}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default VideoWidget;
