import Appix from "Appix";
import clsx from "clsx";
import RemixIcon from "components/RemixIcon";
import { FC, useCallback, useEffect, useState, useMemo } from "react";

import { AppixButton, AppixSize, ClickAwayListener } from "ui";
import { FormWidget } from "widgets";

interface KeyValueSubProps {
  id: string;
  fields: any[];
  size?: keyof typeof AppixSize;
  data: any;
  disabled: boolean;
  onChange(nextValue: any): void;
  ctx?: Record<string, any>;
  keyValue?: any;
  dynamicProperties?: AppixWidget.Form.State["dynamicProperties"];
  formData?: Record<string, any>;
  dependencies?: string[];
}

const KeyValueSub: FC<KeyValueSubProps> = ({
  id,
  fields,
  size,
  disabled,
  data,
  onChange,
  ctx,
  keyValue,
  dynamicProperties,
  formData,
  dependencies,
  ...props
}) => {
  const [value, setValue] = useState<any>(data);
  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    setValue(data);
  }, [data]);

  const onReset = useCallback(() => {
    onChange && onChange(null);
  }, [onChange]);

  const onSubmit = useCallback(() => {
    onChange && onChange(value);
    window.document.body.click();
  }, [onChange, value]);

  const handleChange = useCallback<(a: Record<string, any>) => void>((nextData) => {
    setValue((prev) => ({ ...prev, ...nextData }));
  }, []);

  const mappedFields = fields.map((field) => ({
    ...field,
    mapping: { first: field.name, second: field.label },
  }));

  useEffect(() => {
    Appix.getListeners()?.[id]?.[0]?.({ receiveFields: null });
  }, [id, keyValue]);

  const toggleOpened = useCallback(() => {
    keyValue && setOpened((opened) => !opened);
  }, [keyValue]);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const hasValue = useMemo(() => {
    return fields?.filter((item) => !!data[item.name]).length > 0;
  }, [data, fields]);

  const dependencyData = useMemo(() => {
    return dependencies?.reduce((acc, dep) => {
      acc[dep] = formData?.[dep];
      return acc;
    }, {});
  }, [dependencies, formData]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className="appix-form-keyvalue__sub position-relative">
        <div className="appix-form-keyvalue__button">
          <AppixButton
            id={id}
            variant={"icon"}
            color={"secondary"}
            onClick={toggleOpened}
            size="md"
            disabled={!keyValue}
          >
            <RemixIcon
              className={clsx({ "text-primary": hasValue })}
              iconStyle="fill"
              icon="list-settings"
            />
          </AppixButton>
        </div>

        {opened && (
          <div
            className="appix-form-keyvalue__fields dropdown-menu appix-dropdown d-block"
            id={`${id}DROPDOWN`}
            style={{ zIndex: 2000, right: 0 }}
          >
            <FormWidget
              id={id}
              form={{
                columns: mappedFields,
                presence: mappedFields.map((field) => field.name),
                dynamicProperties: dynamicProperties,
              }}
              data={value}
              isSubForm={true}
              parentData={formData}
              ctx={{ ...ctx, key: keyValue, dependencies: dependencyData }}
              isLoading={false}
              type="FORM"
              displayed={true}
              onChange={handleChange}
            />
            <div className="appix-form-keyvalue__actions">
              <AppixButton color="secondary" size={size} onClick={onReset} fullWidth>
                Сбросить всё
              </AppixButton>
              <AppixButton color="primary" size={size} onClick={onSubmit} fullWidth>
                Сохранить
              </AppixButton>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

KeyValueSub.defaultProps = {
  data: {},
};

export default KeyValueSub;
