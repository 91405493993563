import { AppixMonitorResponse } from "@appix/core";
import { AppixCharts } from "ui";
import React, { useState, useEffect } from "react";
import { optionHandler } from "ui/AppixCharts";

interface LinesMonitorWidgetBreakdownProps extends Partial<AppixMonitorResponse.Result> {
  state: any;
}

const LinesMonitorWidgetBreakdown: React.FC<LinesMonitorWidgetBreakdownProps> = ({ id, state }) => {
  const [option, setOption] = useState({});

  useEffect(() => {
    setOption(optionHandler(state?.monitorExtendedData, { monitorData: state?.monitorData }));
  }, [state]);

  return (
    <div id={id} className="h-100">
      <AppixCharts style={{ height: "400px" }} option={option} />
    </div>
  );
};

export default LinesMonitorWidgetBreakdown;
