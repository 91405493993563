export default function getUnit(unit?: string) {
  if (!unit) return "";
  switch (unit) {
    case "PIECE":
      return "шт";
    case "PERCENT":
      return "%";
    case "MILLILITER":
      return "мл";
    case "LITER":
      return "л";
    case "METER":
      return "м";
    case "KILOMETER":
      return "км";
    case "GRAM":
      return "г";
    case "KILOGRAM":
      return "кг";
    case "RUB":
      return "₽";
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "CNY":
      return "¥";
    default:
      return unit;
  }
}
