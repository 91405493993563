import { AppixPage } from "@appix/core";
import RemixIcon from "components/RemixIcon";
import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { collapseSlashes } from "utils";

interface SecondaryMenuProps {
  items?: Array<AppixPage.Layout.MenuItem>;
  baseUrl?: string;
}

const SecondaryMenu: React.FC<SecondaryMenuProps> = ({ items, baseUrl = "" }) => {
  const handleItemClick = useCallback((hasPage) => {
    return hasPage ? () => {} : (e) => e.preventDefault();
  }, []);

  return items?.length ? (
    <nav className="nav ms-3 ps-1 mt-4">
      {items.map(({ fullpath, title, hasPage, ico }, index) =>
        title ? (
          <NavLink
            key={index}
            className="list-group-item nav-link px-2 py-1 d-flex align-items-center me-2 border-1 btn-light"
            to={collapseSlashes(baseUrl + "/content" + fullpath)}
            onClick={handleItemClick(hasPage)}
            end
          >
            <RemixIcon icon={ico} style={{ marginRight: "0.25rem" }} /> {title}
          </NavLink>
        ) : null,
      )}
    </nav>
  ) : null;
};

export default SecondaryMenu;
