import React, { useCallback, useMemo, useState, useEffect } from "react";
import clsx from "clsx";

import Appix from "Appix";

import RemixIcon from "components/RemixIcon";

import { AppixSelect, IAppixSelectItem, AppixSelectOnChange } from "../AppixSelect";
import { AppixFormControl, AppixTextField } from "../AppixFormControl";
import { AppixButton } from "../AppixButton";

import { AppixSize } from "../types";
import { AppixTableColumn, AppixTableProps, AppixTableBaseRow } from "./types";
import { ClickAwayListener } from "ui/ClickAwayListener";
import { AppixDatePicker } from "ui/AppixDatePicker";
import { Dropdown } from "react-bootstrap";
import FilterListValue from "components/FormControl/FilterListValue";

function getAvailableMethods(method: AppixWidget.EqualityOperator) {
  switch (method) {
    case "GT":
      return "Больше";
    case "GTE":
      return "Больше или равно";
    case "LT":
      return "Меньше";
    case "LTE":
      return "Меньше или равно";
    case "EQ":
      return "Равно";
    case "NEQ":
      return "Не равно";
    case "IN":
      return "Включить";
    case "NIN":
      return "Не включать";
    case "LIKE":
      return "Содержит";
    case "PREFIX":
      return "Начинается с";
    // case 'EX':
    //   return '!='
    // case 'NEX':
    //   return '!='
    default:
      return "?";
  }
}

interface TableSearchItemProps<ROW> {
  id: string;
  column: AppixTableColumn<ROW>;
  onChange: AppixTableProps<any>["setFilterState"];
  onChangeSorting?: AppixTableProps<any>["setSortingState"];
  className?: string;
  size?: keyof typeof AppixSize;
}

interface IMultiSelectValue extends IAppixSelectItem {
  error?: string;
}

function TableSearchItem<ROW extends AppixTableBaseRow>({
  id,
  column,
  onChange,
  onChangeSorting,
  className,
}: TableSearchItemProps<ROW>) {
  const defaultOperator = useMemo<AppixWidget.EqualityOperator>(
    () => column.search?.[0] ?? "EQ",
    [column],
  );

  const [operator, setOperator] = useState<AppixWidget.EqualityOperator | null>(
    column.search?.[0] || defaultOperator,
  );
  const [value, setValue] = useState<string>();
  const [multiSelectValue, setMultiSelectValue] = useState<IMultiSelectValue[]>();
  const [validationError, setValidationError] = useState<string>("");
  const [opened, setOpened] = useState<boolean>(false);

  const operatorOptions = useMemo<IAppixSelectItem[]>(
    () =>
      column.search?.map((operator) => ({
        label: getAvailableMethods(operator),
        value: operator,
      })) || [],
    [column],
  );

  const isDate = useMemo<boolean>(() => ["DATE", "DATETIME"].includes(column.dataType), [column]);

  const isNotSupported = useMemo<boolean>(
    () => !operator || ["BLANK"].includes(operator),
    [operator],
  );

  const hookForScroll = useCallback((e) => {
    setOpened(false);
  }, []);

  const handleToggleFilterDialog = useCallback(() => {
    if (!opened) {
      window.addEventListener("scroll", hookForScroll, true);
    }
    setOpened(!opened);
  }, [opened, hookForScroll]);

  useEffect(
    () => () => {
      window.removeEventListener("scroll", hookForScroll, true);
    },
    [hookForScroll],
  );

  const handleCloseFilterDialog = useCallback(() => {
    window.removeEventListener("scroll", hookForScroll, true);
    setOpened(false);
  }, [hookForScroll]);

  const confirm = useCallback(() => {
    if (onChange && operator && (value || value === "" || multiSelectValue) && column.validator) {
      if (["IN", "NIN"].includes(operator)) {
        const newMultiSelectValue = multiSelectValue?.map((item) => {
          const dataError = Appix.validateInputValue(column.validator ?? {}, item.value);
          return { ...item, error: dataError?.errorText };
        });

        setMultiSelectValue(newMultiSelectValue);

        if (newMultiSelectValue?.some(({ error }) => error)) {
          return;
        }
        onChange({
          [column.dataIndex]: {
            operator,
            value: multiSelectValue?.map(({ value }) => value),
          },
        });
        handleCloseFilterDialog();
      } else {
        const validationResult = Appix.validateInputValue(column.validator, value);
        if (value && validationResult.error) {
          setValidationError(validationResult.errorText);
        } else {
          setValidationError("");
          onChange({
            [column.dataIndex]: {
              operator,
              value,
            },
          });
          handleCloseFilterDialog();
        }
      }
    }
  }, [operator, value, multiSelectValue, column, onChange, handleCloseFilterDialog]);

  const handleTextChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    setValue(e.target.value);
  }, []);

  const handleEnterPress = useCallback<React.KeyboardEventHandler<HTMLInputElement>>((e) => {
    if (e.key === "Enter") {
      setValue(e.currentTarget.value);
    }
  }, []);

  const handleOperatorChange = useCallback<AppixSelectOnChange>((operator) => {
    setValue("");
    setMultiSelectValue([]);
    setOperator(operator as AppixWidget.EqualityOperator);
  }, []);

  const handleDateChange = useCallback<any>((e) => {
    setValue(e.target.value.startDate);
  }, []);

  const handleSelectChange = useCallback<AppixSelectOnChange>((value) => {
    setValue(String(value));
  }, []);
  const handleMultiSelectChange = useCallback<(badges: IAppixSelectItem[]) => void>((badges) => {
    setMultiSelectValue(badges);
  }, []);

  const handleIconSortClick = useCallback(() => {
    if (onChangeSorting) {
      onChangeSorting(column);
    }
  }, [column, onChangeSorting]);

  const reset = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if ((operator && operator !== defaultOperator) || value) {
        onChange &&
          onChange({
            [column.dataIndex]: null,
          });
        setOperator(defaultOperator);
        setValue("");
        setMultiSelectValue([]);
      }
      handleCloseFilterDialog();
    },
    [column.dataIndex, handleCloseFilterDialog, onChange, operator, value, defaultOperator],
  );

  const setFilter = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      confirm();
    },
    [confirm],
  );

  const ValueField = useMemo(() => {
    if (operator && isNotSupported) return <div className="text-center">Not supported yet</div>;

    if (operator && column.options?.length! > 0 && ["IN", "NIN"].includes(operator)) {
      return (
        <AppixSelect
          options={column.options}
          multiSelectValue={multiSelectValue}
          onMultiselectChange={handleMultiSelectChange}
          id={id + column.dataIndex + operator}
          withBadges={true}
          value={multiSelectValue?.map((item) => item.value)}
        />
      );
    }

    if (operator && !column.options?.length && ["IN", "NIN"].includes(operator))
      return (
        <FilterListValue
          value={multiSelectValue}
          onChange={handleMultiSelectChange}
          id={id + column.dataIndex + operator}
          isDate={isDate}
        />
      );

    if (operator && column.options?.length! > 0 && !["IN", "NIN"].includes(operator))
      return (
        <AppixSelect
          options={column.options}
          value={value}
          onChange={handleSelectChange}
          id={id + column.dataIndex + operator}
        />
      );

    if (operator && isDate)
      return (
        <AppixDatePicker
          value={value?.replace("Z", "")}
          onChange={handleDateChange}
          selectRange={false}
          withTime={true}
          defaultView="month"
          id={id + column.dataIndex + operator}
        />
      );

    return (
      <AppixTextField
        id={id + column.dataIndex + operator}
        onChange={handleTextChange}
        onKeyPress={handleEnterPress}
        value={value}
        validation={validationError ? "error" : undefined}
        helper={validationError}
      />
    );
  }, [
    column.dataIndex,
    column.options,
    handleDateChange,
    handleEnterPress,
    handleMultiSelectChange,
    handleSelectChange,
    handleTextChange,
    id,
    isDate,
    isNotSupported,
    multiSelectValue,
    operator,
    validationError,
    value,
  ]);

  return (
    <ClickAwayListener onClickAway={handleCloseFilterDialog}>
      <Dropdown>
        <div className={clsx("table-cell table-cell-head ", className)}>
          <div className="table-cell-head__title">
            <span>{column.title}</span>
            <div className={clsx("table-header-button-wrapper d-flex align-items-center")}>
              {column.search && (
                <Dropdown.Toggle
                  as="div"
                  id={id + "toggler"}
                  bsPrefix="toggler"
                  onClick={handleToggleFilterDialog}
                >
                  <div
                    id={`${id}${column.dataIndex}filter`}
                    className="filters-button position-relative"
                  >
                    <RemixIcon
                      className={clsx({
                        "text-primary": opened || value || multiSelectValue?.length,
                      })}
                      icon="filter"
                    />
                    {opened && <div className="dropdown-fixed-wrapper__arrow"></div>}
                  </div>
                </Dropdown.Toggle>
              )}

              <Dropdown.Menu
                style={{
                  marginTop: window.scrollY ? `-${window.scrollY - 10}px` : "10px",
                }}
                show={opened}
                renderOnMount={true}
                bsPrefix={clsx("appix-dropdown dropdown-fixed-wrapper", {
                  "d-block": opened,
                  "d-none": !opened,
                })}
              >
                {opened && (
                  <>
                    <div className="search-header">
                      <div className="search-header__operator">
                        <AppixFormControl label="Оператор">
                          <AppixSelect
                            options={operatorOptions}
                            value={operator || ""}
                            onChange={handleOperatorChange}
                            id={id + column.dataIndex}
                          />
                        </AppixFormControl>
                      </div>
                      <div className="search-header__field">
                        <AppixFormControl
                          label={
                            operator && !column.options?.length && ["IN", "NIN"].includes(operator)
                              ? ""
                              : "Значение"
                          }
                        >
                          <>{ValueField}</>
                        </AppixFormControl>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end search-header__action-wrapper">
                      <div className="search-header__action">
                        <AppixButton
                          variant="link"
                          size={"xs"}
                          onClick={reset}
                          disabled={!value && !multiSelectValue}
                          className={clsx({
                            "text-dark": value || multiSelectValue,
                          })}
                        >
                          Очистить
                        </AppixButton>
                      </div>
                      <div className="search-header__action">
                        <AppixButton
                          variant="link"
                          size={"xs"}
                          onClick={setFilter}
                          disabled={!value && !multiSelectValue}
                        >
                          Применить
                        </AppixButton>
                      </div>
                    </div>
                  </>
                )}
              </Dropdown.Menu>

              {onChangeSorting && column.sort && (
                <button
                  id={`${id}${column.dataIndex}sorting`}
                  className={clsx(
                    "table-sorting-btn",

                    {
                      "table-sorting-btn_active": column.sort.applied,
                      "table-sorting-btn_asc": column.sort.direction === "ASC",
                      "table-sorting-btn_desc": column.sort.direction === "DESC",
                    },
                  )}
                  onClick={handleIconSortClick}
                >
                  <RemixIcon iconStyle="none" icon="sort-asc" />
                </button>
              )}
            </div>
          </div>
        </div>
      </Dropdown>
    </ClickAwayListener>
  );
}

export default TableSearchItem;
