import React, { FC, useCallback, useEffect, useState } from "react";
import { AppixSelect, AppixSelectProps, AppixSelectValue, IAppixSelectItem } from "ui";

export const MultiSelectContext = React.createContext<IAppixSelectItem[] | undefined>(undefined);

interface SelectInputProps {
  options: AppixSelectProps["options"];
  value: IAppixSelectItem[];
  onChange: (e: { target: { value: AppixSelectValue[] } }) => void;
  disabled?: boolean;
  clear?: boolean;
  id: string;
  setFormFields?: () => Promise<void>;
  treeData?: any;
  tableData?: any;
  handleSearch?: (query: string, name?: string) => void;
  name?: string;
  helper?: string;
  optionsApi?: Record<string, any>;
}

const MultiselectInput: FC<SelectInputProps> = ({
  options,
  onChange,
  value,
  disabled,
  clear,
  id,
  setFormFields,
  treeData,
  tableData,
  handleSearch,
  name,
  helper,
  optionsApi,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [selectValue, setSelectValue] = useState<any[]>([]);
  useEffect(() => {
    if (Array.isArray(value) && Object.keys(selectValue).length) {
      setSelectValue((prev) => {
        // eslint-disable-next-line eqeqeq
        return prev.filter((item) => value.find((id) => id == item.value));
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (value && Array.isArray(value) && !treeData && !tableData) {
      const getLabel = (value) => {
        if (options?.length) {
          // eslint-disable-next-line eqeqeq
          return options.find((option) => option.value == value)?.label;
        }
        return value;
      };

      const newValue = value.map((item) => ({
        value: item.toString(),
        label: getLabel(item.toString()),
      }));

      setSelectValue(newValue);
    } else if (!value) {
      setSelectValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = useCallback(
    (e) => {
      const newValue = e.map((item) => (isNaN(+item.value) ? item.value : +item.value));
      onChange({ target: { value: newValue } });
    },
    [onChange],
  );

  const handleStringArrayChange = useCallback(
    (e) => {
      const newValue = e.map((item) => item.value);
      onChange({ target: { value: newValue } });
    },
    [onChange],
  );

  const handleInputChange = useCallback((value) => {
    setInputValue(value);
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (
        e.code === "Enter" &&
        e.target.value &&
        // eslint-disable-next-line eqeqeq
        !selectValue?.find((item) => item.value == e.target.value)
      ) {
        setSelectValue((prev) => {
          if (!prev) {
            prev = [];
          }
          const newState = [...prev, { label: inputValue, value: inputValue }];
          const value = newState.map((item) => item.value);
          onChange({ target: { value } });
          return newState;
        });
        onChange({ target: { value: e.target.value } });

        setInputValue("");
      }
    },
    [inputValue, onChange, selectValue],
  );

  const handleTreeChange = useCallback(
    (value: IAppixSelectItem[]) => {
      handleChange(value);
      setSelectValue(value);
    },
    [handleChange],
  );

  // для списка произвольных строк
  if (!options?.length && !optionsApi) {
    return (
      <AppixSelect
        multiSelectValue={selectValue}
        withBadges={true}
        onMultiselectChange={handleStringArrayChange}
        onInputChange={handleSearch}
        isCustom={true}
        handleKeyDown={handleKeyDown}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        withSearch={true}
        id={id}
        helper={helper}
        value={value}
        clear={clear}
      />
    );
  }

  return (
    <MultiSelectContext.Provider value={selectValue}>
      <AppixSelect
        multiSelectValue={selectValue}
        withBadges={true}
        withSearch={true}
        onMultiselectChange={handleChange}
        onInputChange={handleSearch}
        options={options}
        id={id}
        treeData={treeData}
        tableData={tableData}
        onTreeChange={handleTreeChange}
        name={name}
        helper={helper}
        value={value}
        clear={clear}
      />
    </MultiSelectContext.Provider>
  );
};

export default MultiselectInput;
