import RemixIcon from "components/RemixIcon";
import React, { useCallback } from "react";
import NavChildren from "./NavChildren";
import { MenuItemProps } from "./types";
import Appix from "Appix";
import { collapseSlashes } from "utils";
import clsx from "clsx";

const MenuItem: React.FC<MenuItemProps> = ({
  item,
  index,
  onItemClick,
  currentLocation,
  customLink,
  id,
  handleSetExpanded,
  expandedItemsData,
  paddingClass,
  lastChild,
  isSmall,
  className,
  collapsed,
}) => {
  const { title, hasPage, fullpath, expanded, ico } = item;

  const baseUrl = Appix.baseUrl;
  const nextRoute = collapseSlashes(baseUrl + "/content" + item.fullpath);
  const itemSaveId = id;

  const isActive = window.location.pathname.includes(nextRoute);
  const hasActivePage = fullpath && window.location.pathname.includes(nextRoute);

  const handleItemClick = useCallback(
    (e) => {
      e.preventDefault();
      item.fullpath && onItemClick(nextRoute);
    },
    [item.fullpath, onItemClick, nextRoute],
  );

  const isExpanded = expandedItemsData?.[itemSaveId] ?? expanded;

  const handleSetExpandedCallback = useCallback(() => {
    handleSetExpanded && handleSetExpanded(itemSaveId, Boolean(!isExpanded));
    if (collapsed && item.children?.length) {
      const firstChild = item.children[0];
      const nextRoute = collapseSlashes(baseUrl + "/content" + firstChild.fullpath);
      firstChild.fullpath && onItemClick(nextRoute);
    }
  }, [handleSetExpanded, baseUrl, collapsed, item.children, onItemClick, itemSaveId, isExpanded]);

  if (!title) {
    return null;
  }

  const link = customLink ?? (
    <a
      href={nextRoute}
      className="text-decoration-none list-group-item border-0 p-0 rounded-2"
      onClick={handleItemClick}
    >
      <div className={`accordion-body p-0 d-flex`}>
        <RemixIcon icon={ico || null} />
        {title}
      </div>
    </a>
  );

  return (
    <div
      key={id}
      id={id}
      className={clsx(`accordion-item border-0 rounded-0`, {
        "accordion-item-sm": isSmall,
      })}
    >
      {item.children?.length ? (
        <>
          <h2
            className={clsx("accordion-header", {
              active: hasActivePage,
            })}
          >
            <div
              id={id}
              className={clsx(`accordion-button bg-transparent appix-menu-item`, {
                collapsed: !!isExpanded,
                "appix-menu-margin": paddingClass && !isSmall,
                "appix-menu-margin-sm": paddingClass && isSmall,
                "appix-menu-item-sm": isSmall,
              })}
              role="button"
              aria-expanded={expanded}
              aria-controls={`collapse${id}`}
              onClick={handleSetExpandedCallback}
            >
              {hasPage ? (
                link
              ) : (
                <>
                  <RemixIcon icon={ico || null} />
                  <span>{title}</span>
                </>
              )}
            </div>
          </h2>
          <div
            id={`collapse${id}`}
            className={clsx(`accordion-collapse collapse`, {
              show: !!isExpanded,
              "appix-menu-margin": paddingClass && !isSmall,
              "appix-menu-margin-sm": paddingClass && isSmall,
            })}
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              <div className="accordion">
                {item.children?.length
                  ? item.children.map((child, index) => {
                      return (
                        <NavChildren
                          key={index}
                          item={child}
                          index={index}
                          currentLocation={currentLocation}
                          onItemClick={onItemClick}
                          className={isSmall ? "appix-menu-margin-sm" : "appix-menu-margin"}
                          id={child.fullpath ?? id + index}
                          handleSetExpanded={handleSetExpanded}
                          expandedItemsData={expandedItemsData}
                          lastChild={item.children && item.children.length - 1 === index}
                          isSmall={isSmall}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <a
          href={nextRoute}
          className={clsx("text-decoration-none list-group-item border-0 p-0", {
            active: isActive,
          })}
          onClick={handleItemClick}
        >
          <div className={`accordion-body p-0`}>
            <div
              className={clsx(`d-flex appix-menu-item`, className, {
                "appix-menu-margin": paddingClass && !isSmall,
                "appix-menu-margin-sm": paddingClass && isSmall,
                "appix-menu-item-sm": isSmall,
              })}
            >
              <RemixIcon icon={ico || null} />
              <span>{title}</span>
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

export default MenuItem;
