import { AppixPage } from "@appix/core";
import { FC } from "react";
import Column from "./LayoutColumn";

const LayoutRow: FC<AppixPage.Container.Row> = ({ columns, id, hr, height, backgroundColor }) => {
  return hr ? (
    <hr />
  ) : (
    <div
      id={id}
      className={`row position-relative ${
        backgroundColor ? `bg-${backgroundColor.toLowerCase()}` : ""
      }`}
      style={{ height: height || "auto" }}
    >
      {columns.map((column, columnIndex) => (
        <Column
          fixedHeight={!!height}
          key={column.id || columnIndex}
          siblingsAmount={columns.length}
          {...column}
        />
      ))}
    </div>
  );
};

export default LayoutRow;
