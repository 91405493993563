import clsx from "clsx";
import RemixIcon from "components/RemixIcon";
import React, { useMemo } from "react";
import { AppixButton } from "ui/AppixButton";
import { AppixTableAction } from "ui/AppixTable/types";
import { AppixTooltip } from "ui/AppixTooltip";
import { CardFooterProps } from "./types";

const CardFooter: React.FC<CardFooterProps> = ({ columns, data, primaryKey, rowActions }) => {
  const timeCellIconData = columns[3]?.ico(data);
  const timeCellColoring = columns[3]?.color(data);
  const rowActionsArray = useMemo<AppixTableAction[]>(() => {
    const currentRowActions = rowActions?.[data[primaryKey]];
    return currentRowActions
      ? Object.keys(currentRowActions).map((actionKey) => ({
          actionKey,
          ...currentRowActions[actionKey],
        }))
      : [];
  }, [rowActions, data, primaryKey]);
  return (
    <div className="appix-cards__card__footer">
      <div className="appix-cards__card__footer__time">
        {timeCellIconData ? (
          <RemixIcon
            className={clsx({
              [`text-${timeCellIconData.color?.toLowerCase()}`]: !!timeCellIconData.color,
            })}
            icon={timeCellIconData.ico}
          />
        ) : null}
        <span
          className={clsx({
            [`text-${timeCellColoring?.toLowerCase()}`]: !!timeCellColoring,
          })}
        >
          {data[columns[3]?.dataIndex]?.toString()}
        </span>
      </div>
      <div className="appix-cards__card__footer__actions">
        {rowActionsArray.length > 0 &&
          rowActionsArray.map((rowAction, index) => (
            <AppixTooltip key={index} placement="top" label={rowAction.title || ""}>
              <AppixButton
                beforeIconCode={rowAction.ico}
                variant="icon"
                size="xs"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                  rowAction.cb(data);
                }}
              />
            </AppixTooltip>
          ))}
      </div>
    </div>
  );
};

export default CardFooter;
