import { ChainEditorNodeState } from "@appix/chain-editor";

import { getColor } from "utils";

export function widgetNodeToEditorNode(node: AppixWidget.Chain.Node): ChainEditorNodeState {
  return {
    id: String(node.id),
    category: node.category,
    color: getColor(node.color),
    confirmationOnDelete: node.confirmationOnDelete ?? undefined,
    connections: node.connections.map((conn) => ({
      nodeId: conn.nodeId,
      idx: conn.details.idx ?? null,
      editable: conn.details.editable,
      removable: conn.details.removable,
      ico: conn.details.ico ?? undefined,
      title: conn.details.title ?? undefined,
      isSetting: !!conn.details.formWidget,
      raw: conn,
    })),
    ico: node.ico ?? undefined,
    outConnectionsPolicy: node.outConnectionsPolicy ?? undefined,
    inConnectionsPolicy: node.inConnectionsPolicy ?? undefined,
    isSettings: !!node.formWidget,
    hasCustomContent: !!node.customContent,
    position: [node.position?.x ?? 0, node.position?.y ?? 0],
    size: node.size ?? undefined,
    slots: node.outConnectionsPolicy?.slots?.options.map((slot, idx) => ({
      idx,
      ico: slot.ico ?? undefined,
      isSetting: !!slot.formWidget,
      mode: node.outConnectionsPolicy?.slots?.mode,
      position: slot.position,
      title: slot.title ?? undefined,
      options:
        node.outConnectionsPolicy?.slots?.mode === "CHOICE"
          ? node.outConnectionsPolicy?.slots?.options.map((option) => ({
              ico: option.ico ?? undefined,
              title: option.title ?? undefined,
            }))
          : undefined,
    })),
    subtitle: node.subtitle ?? undefined,
    title: node.title ?? undefined,
    type: node.type ?? undefined,
    updated: node.updated,
    label: node.state
      ? {
          color: getColor(node.state.level as AppixWidget.Color),
          text: node.state.text,
        }
      : undefined,
    raw: node,
  };
}

export function widgetNodesToEditorNodes(nodes: AppixWidget.Chain.Node[]): ChainEditorNodeState[] {
  return nodes.map(widgetNodeToEditorNode);
}
