import React, { useCallback, useEffect } from "react";

import { FormWidget } from "widgets";

interface TableFormModalProps {
  id: string;
  form?: AppixWidget.Form;
  formMode: string;
  formBroadcast: any;
  onClose(): void;
  isShown?: boolean;
}

const TableFormModal: React.FC<TableFormModalProps> = ({
  id,
  form,
  formBroadcast,
  formMode,
  onClose,
  isShown,
}) => {
  useEffect(() => {
    isShown &&
      !document.body.classList.contains("modal-open") &&
      document.body.classList.add("modal-open");
    !isShown && document.body.classList.remove("modal-open");
    return () => {
      document.body.classList.contains("modal-open") &&
        document.body.classList.remove("modal-open");
    };
  }, [isShown]);

  const handleCloseDialogFromBack = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose],
  );

  const defaultCaption = formMode === "ADD" ? "Добавить" : "Редактировать";
  const formCaption = form?.form.caption || defaultCaption;

  return (
    <div
      className="modal fade show d-block bg-dark bg-opacity-75 "
      aria-modal="true"
      role="dialog"
      onClick={handleCloseDialogFromBack}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-close d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
              id={id + "CLOSE"}
            />
          </div>
          <div className="modal-header">
            <h5 className="modal-title">{formCaption}</h5>
          </div>
          <div className="modal-body">
            <FormWidget broadcast={formBroadcast} {...form} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFormModal;
