import React, { useCallback, useMemo, useRef, useState } from "react";
import moment from "moment";

interface TimeInputProps {
  className?: string;
  max: number;
  value: string | number;
  onChange: (value: string) => void;
  id?: string;
}

const TimeInput: React.FC<TimeInputProps> = ({ className = "", max, value, onChange, id = "" }) => {
  const [inputValue, setInputValue] = useState<any>(undefined);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (e) => {
      const number = e.target.value?.match(/^-?(?:[0-9])*/m)[0];
      if (number <= max) {
        setInputValue(number);

        onChange(number);
      }
    },
    [onChange, max],
  );

  const checkFormat = useCallback(() => {
    const currentValue = inputRef?.current?.value!;
    if (currentValue.length < 2) {
      setInputValue(moment({ minute: +currentValue }).format("mm"));
      onChange(moment({ minute: +currentValue }).format("mm"));
    }
  }, [onChange]);

  const defaultValue = useMemo(() => {
    return ["number", "string"].includes(typeof value) && !isNaN(+value)
      ? moment({ minute: +value }).format("mm")
      : "00";
  }, [value]);

  const handleClick = useCallback(() => {
    if (value !== "0") {
      setInputValue(defaultValue);
    }
  }, [defaultValue, value]);

  return (
    <div className={`time-input-container`}>
      <input
        id={id}
        autoComplete="off"
        className={`time-input ${className}`}
        maxLength={2}
        onChange={handleChange}
        value={inputValue}
        onBlur={checkFormat}
        ref={inputRef}
        type={"text"}
        placeholder={defaultValue}
        onClick={handleClick}
      />
    </div>
  );
};

export default TimeInput;
