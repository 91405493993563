import { FC, PropsWithChildren } from "react";

import { AppixLabel, AppixSize } from "ui";

interface FormGroupProps {
  id: string;
  label?: string;
  size?: keyof typeof AppixSize;
  isUpperLabel?: boolean;
  description?: string;
}

const FormGroup: FC<PropsWithChildren<FormGroupProps>> = ({
  id,
  label,
  size = "md",
  isUpperLabel,
  children,
  description,
}) => {
  return (
    <div className="form-group">
      {label ? (
        <AppixLabel htmlFor={id} size={size} isUpper={isUpperLabel}>
          {label}
        </AppixLabel>
      ) : null}
      {children}
      {description && <span className="appix-input-description">{description}</span>}
    </div>
  );
};

export default FormGroup;
