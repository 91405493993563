import LayoutContent from "containers/Layout/LayoutContent";
import clsx from "clsx";
import { ContainerWidgetClass, INITIAL_CONTAINER_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const GridContainerWidget: React.FC<AppixWidget.Container> = (props) => {
  const containerWidget = useWidget<AppixWidget.Container>(
    props,
    INITIAL_CONTAINER_WIDGET_STATE,
    ContainerWidgetClass,
    [props.rows, props.backgroundColor, props.url],
  );

  return containerWidget.state.isLoading ? (
    <Spinner />
  ) : (
    <div className="position-relative">
      <CurrentWidgetInfo widget={containerWidget} />

      <LayoutContent
        className={clsx(
          `container-widget ${
            props.backgroundColor ? `bg-${props.backgroundColor?.toLowerCase()}` : ""
          }`,
          {
            "d-none": !containerWidget.state.displayed,
            "d-block": containerWidget.state.displayed,
            colored: props.backgroundColor,
          },
        )}
        id={props.id}
        rows={containerWidget.state.rows}
      />
    </div>
  );
};

export default GridContainerWidget;
