import { useEffect, useMemo, useState } from "react";

import Appix from "Appix";

export default function useWidget<STATE, WIDGET_CLASS = any>(
  initialProps,
  initialState,
  WidgetClass,
  dependencies: any[] = [],
): WIDGET_CLASS {
  const [, setState] = useState<STATE>(initialState);

  const appixWidget = useMemo(() => {
    const appixWidget = new WidgetClass(initialProps.id, Appix);
    appixWidget.setState = (nextState: STATE) => {
      appixWidget.state = {
        ...appixWidget.state,
        ...nextState,
      };
      setState(nextState);
    };
    appixWidget.initialize(initialProps);
    return appixWidget;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProps.id, ...dependencies]);

  useEffect(() => () => appixWidget.cleanup(), [appixWidget]);

  return appixWidget;
}
