import React from "react";

import { MonitorWidgetExpandableSeriesResponse } from "@appix/core";

import MultiHeaderRow from "./MultiHeaderRow";

interface MultiHeaderProps {
  data?: Array<MonitorWidgetExpandableSeriesResponse.HeaderNode>;
}

const MultiHeader: React.FC<MultiHeaderProps> = ({ data }) => {
  const headerData: Record<string, Record<string, any>> = {};

  const countLastChildrens = (obj) => {
    const count = obj.children.reduce((acc, item) => {
      if (item.children.length) {
        acc += countLastChildrens(item);
        return acc;
      }
      return obj.children.length;
    }, 0);
    return count;
  };

  const prepareData = (array, level = 1) => {
    array.forEach((item) => {
      if (!headerData[level]) {
        headerData[level] = [];
      }
      headerData[level].push({
        label: item.text,
        childrenCount: countLastChildrens(item),
      });
      prepareData(item.children, level + 1);
    });
  };

  prepareData(data);

  return (
    <>
      {Object.values(headerData).map((item, index) => {
        return <MultiHeaderRow key={index} index={index} data={item} />;
      })}
    </>
  );
};

export default MultiHeader;
