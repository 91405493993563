import { Dispatch, ReactElement, useMemo, useCallback } from "react";

import { FormGroup, SelectTable, SelectTree } from "components";
import {
  AppixCheckbox,
  AppixDatePicker,
  AppixMDEditor,
  AppixSelectValue,
  AppixTextArea,
  AppixTextField,
  AppixCodeEditor,
} from "ui";

import MultiselectInput from "./MultiselectInput";
import ListValue from "./ListValue";
import AppixFileUploadInput from "./AppixFileUploadInput";
import KeyValue from "./KeyValue";
import SelectInput from "./SelectInput";

import { FormControlProps } from "./types";
import { FormWidget } from "widgets";
import InlineSelectInput from "./InlineSelectInput";

function getInputType(dataSubType: AppixWidget.DataSubType) {
  switch (dataSubType) {
    case "PASSWORD":
      return "password";
    case "EMAIL":
      return "email";
    default:
      return "text";
  }
}

function FormControl(props: FormControlProps) {
  const {
    copy,
    placeholder,
    clear,
    dataType,
    dataSubType,
    disabled,
    errorText,
    id,
    keyField,
    label,
    name,
    options,
    optionsApi,
    size,
    subFields,
    tableData,
    treeData,
    validator,
    value,
    valueField,
    onChange,
    onSearch,
    ctx,
    dynamicProperties,
    dependencies,
  } = props;
  const outputLabel = useMemo<string>(() => {
    return label ? `${label} ${validator?.required?.value ? "*" : ""}` : "";
  }, [label, validator]);

  const required = useMemo(() => !!validator?.required?.value, [validator]);

  const dateTimeValue = useMemo(() => {
    if (typeof value === "string") {
      return value.replace("Z", "");
    }
    if (typeof value === "object") {
      return {
        ...value,
        startDate: value?.startDate?.replace("Z", ""),
        endDate: value?.endDate?.replace("Z", ""),
      };
    }
  }, [value]);

  const defaultChangeHandler = useCallback(
    (e: { target: { value: any } }) => {
      onChange && onChange(e.target.value);
    },
    [onChange],
  );

  const handleOnClear = useCallback(() => {
    onChange && onChange("");
  }, [onChange]);

  const inputElement = useMemo<ReactElement>(() => {
    if (tableData) {
      const selectModeSingle = tableData.selectMode.selectionMaximumQty === 1;
      const handleChange: Dispatch<(string | number)[]> = (nextValue) =>
        onChange(selectModeSingle ? nextValue[0] : nextValue);
      return (
        <SelectTable
          id={id + name}
          tableData={tableData}
          helper={errorText}
          values={value ? (Array.isArray(value) ? value : [value]) : []}
          // eslint-disable-next-line
          onChange={handleChange}
        />
      );
    }
    if (treeData) {
      const selectModeSingle = treeData.selectMode.selectionMaximumQty === 1;
      const handleChange: Dispatch<(string | number)[]> = (nextValue) =>
        onChange(selectModeSingle ? nextValue[0] : nextValue);
      return (
        <SelectTree
          id={id + name}
          treeData={treeData}
          helper={errorText}
          values={value ? (Array.isArray(value) ? value : [value]) : []}
          // eslint-disable-next-line
          onChange={handleChange}
        />
      );
    }

    switch (dataType) {
      case "BOOLEAN": {
        return (
          <AppixCheckbox
            id={id + name}
            label={outputLabel}
            helper={errorText}
            disabled={disabled}
            isToggler={dataSubType === "TOGGLER"}
            checked={value}
            onCheckboxChange={onChange}
          />
        );
      }
      case "DATE": {
        return (
          <AppixDatePicker
            validator={validator}
            value={dateTimeValue}
            onChange={defaultChangeHandler}
            selectRange={false}
            withTime={false}
            defaultView="month"
            helper={errorText}
            disabled={disabled}
            isRequired={required}
            name={name}
            id={id}
          />
        );
      }
      case "DATE_INTERVAL": {
        return (
          <AppixDatePicker
            validator={validator}
            value={dateTimeValue}
            onChange={defaultChangeHandler}
            selectRange={true}
            defaultView="month"
            helper={errorText}
            disabled={disabled}
            isRequired={required}
            name={name}
            id={id}
          />
        );
      }
      case "DATETIME": {
        return (
          <AppixDatePicker
            validator={validator}
            value={dateTimeValue}
            onChange={defaultChangeHandler}
            selectRange={false}
            withTime={true}
            defaultView="month"
            helper={errorText}
            disabled={disabled}
            isRequired={required}
            name={name}
            id={id}
          />
        );
      }
      case "DATETIME_INTERVAL": {
        return (
          <AppixDatePicker
            validator={validator}
            value={dateTimeValue}
            onChange={defaultChangeHandler}
            selectRange={true}
            withTime={true}
            defaultView="month"
            helper={errorText}
            disabled={disabled}
            isRequired={required}
            name={name}
            id={id}
          />
        );
      }
      case "ENUM": {
        switch (dataSubType) {
          case "INLINE":
            return (
              <InlineSelectInput
                id={id + name}
                options={options}
                value={value}
                onChange={defaultChangeHandler}
              />
            );

          default:
            return (
              <SelectInput
                id={id + name}
                options={options}
                disabled={disabled}
                placeholder={placeholder}
                clear={clear}
                treeData={treeData}
                tableData={tableData}
                handleSearch={onSearch}
                name={name}
                helper={errorText}
                searchable={optionsApi?.mode === "SEARCH"}
                value={value}
                onChange={defaultChangeHandler}
              />
            );
        }
      }
      case "FILE": {
        return (
          <AppixFileUploadInput
            onChange={onChange}
            value={value}
            disabled={disabled}
            id={id + name}
            validation={errorText ? "error" : undefined}
            isRequired={required}
            copy={copy}
            clear={clear}
            helper={errorText}
            type={dataSubType}
            validator={validator}
            dataSubType={dataSubType}
          />
        );
      }
      case "KEY_VALUE": {
        return (
          <KeyValue
            id={id + name}
            name={name}
            data={value}
            keyField={keyField}
            valueField={valueField}
            subFields={subFields}
            helper={errorText}
            disabled={disabled}
            onChange={onChange}
            size={size}
            ctx={ctx}
            dynamicProperties={dynamicProperties}
            formData={props.data}
            dependencies={dependencies}
          />
        );
      }
      case "LIST_VALUE": {
        if (subFields?.length) {
          return <ListValue {...props} id={id + name} />;
        }
        const handleChange: (e: {
          target: {
            value: AppixSelectValue[];
          };
        }) => void = (e) => onChange && onChange(e.target.value);

        const handleSearch = (q, name) => {
          if (validator?.selectOptionsFetchMode === "SEARCH") {
            return onSearch && onSearch(q, name);
          }
        };
        return (
          <MultiselectInput
            id={id + name}
            name={name}
            options={options || []}
            value={typeof value === "object" ? value : []}
            disabled={disabled}
            treeData={treeData}
            tableData={tableData}
            helper={errorText}
            clear={clear}
            // eslint-disable-next-line
            handleSearch={handleSearch}
            // eslint-disable-next-line
            onChange={handleChange}
            optionsApi={props.optionsApi}
          />
        );
      }

      case "OBJECT": {
        const handleChange: (a: Record<string, any>) => void = (nextData) =>
          onChange &&
          onChange({
            ...value,
            ...nextData,
          });
        const mappedFields: any =
          subFields?.map((field) => ({
            ...field,
            mapping: { first: field.name, second: field.label },
          })) || [];
        const formData = { ...props.data, ...value };
        const dependencyData = dependencies?.reduce((acc, dep) => {
          acc[dep] = formData?.[dep];
          return acc;
        }, ctx?.dependencies || {});

        return (
          <FormWidget
            id={id + name}
            form={{
              columns: mappedFields,
              presence: mappedFields.map((field) => field.name),
              dynamicProperties: dynamicProperties,
              ctx: ctx,
            }}
            isSubForm={true}
            data={formData}
            isLoading={false}
            type="FORM"
            displayed={true}
            parentData={formData}
            // eslint-disable-next-line
            onChange={handleChange}
            ctx={{ ...ctx, dependencies: dependencyData }}
          />
        );
      }
      case "DOUBLE":
      case "INTEGER":
      case "STRING": {
        switch (dataSubType) {
          case "TEXT": {
            return (
              <AppixTextArea
                onChange={defaultChangeHandler}
                value={value}
                disabled={disabled}
                id={id + name}
                validation={errorText ? "error" : undefined}
                isRequired={required}
                copy={copy}
                helper={errorText}
              />
            );
          }
          case "MD": {
            return <AppixMDEditor onChange={defaultChangeHandler} value={value} id={id + name} />;
          }
          case "HTML":
          case "JAVASCRIPT":
          case "JSON": {
            return (
              <AppixCodeEditor
                language={dataSubType}
                onChange={onChange}
                value={value}
                id={id + name}
              />
            );
          }
          default:
        }
      }
      default: {
        return (
          <AppixTextField
            id={id + name}
            validation={errorText ? "error" : undefined}
            isRequired={required}
            type={getInputType(dataSubType)}
            copy={copy}
            placeholder={placeholder}
            clear={clear}
            helper={errorText}
            disabled={disabled}
            value={value}
            ico={validator?.ico || undefined}
            onChange={defaultChangeHandler}
            onClear={handleOnClear}
          />
        );
      }
    }
    // используются все пропсы
    // eslint-disable-next-line
  }, [props]);

  return (
    <FormGroup
      id={id}
      label={dataType !== "BOOLEAN" ? outputLabel : undefined}
      size={size}
      isUpperLabel={!!subFields?.length}
      description={validator?.description}
    >
      {inputElement}
    </FormGroup>
  );
}

FormControl.defaultProps = {
  size: "md",
};

export default FormControl;
