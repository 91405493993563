import React, { useCallback, useEffect, useMemo } from "react";
import {
  FormWidgetClass,
  IFormWidgetAction,
  IFormWidgetState,
  INITIAL_FORM_WIDGET_STATE,
} from "@appix/core";
import { AppixButton } from "ui";
import Appix from "Appix";
import moment from "moment";
import FormContent from "./FormContent";
import clsx from "clsx";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

interface FormWidgetProps extends Partial<AppixWidget.Form> {
  data?: any;
  broadcast?: any;
  columns?: number;
  rows?: number;
  onChange?: (a: Record<string, any>) => void;
  onAction?(config: IFormWidgetAction): void;
  isSubForm?: boolean;
  parentData?: any;
}
const FormWidget: React.FC<FormWidgetProps> = (props) => {
  const { onAction } = props;
  const formWidget = useWidget<IFormWidgetState>(props, INITIAL_FORM_WIDGET_STATE, FormWidgetClass);
  const { data, formErrors, globalError } = formWidget.state;
  const actions = formWidget.actions;
  const { handleSearch } = formWidget;

  useEffect(() => {
    props.isSubForm && formWidget.setState({ data: props.data });
  }, [formWidget, props.data, props.isSubForm]);

  useEffect(() => {
    formWidget.setState({ data: { ...formWidget.state.data, ...props.form?.dataDefaults } });
  }, [formWidget, props.form?.dataDefaults]);

  useEffect(() => {
    if (props.ctx && JSON.stringify(props.ctx) !== JSON.stringify(formWidget.state.ctx)) {
      formWidget.handleSetContext(props.ctx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ctx]);

  useEffect(() => {
    if (props.isSubForm) {
      if (JSON.stringify(formWidget.state.form) !== JSON.stringify(props.form)) {
        formWidget.setState({ form: props.form });
      }
      if (JSON.stringify(formWidget.state.parentData) !== JSON.stringify(props.parentData)) {
        formWidget.setState({ parentData: props.parentData });
      }
      if (JSON.stringify(formWidget.state.form?.columns) !== JSON.stringify(props.form?.columns)) {
        formWidget.receiveFields(props.form?.columns, props.form?.presence);
      }
    }
  }, [
    formWidget,
    props.form,
    props.form?.columns,
    props.form?.presence,
    props.isSubForm,
    props.parentData,
  ]);

  const handleInputChange = useCallback(
    async (
      value,
      name: string,
      validator?: Record<string, any>,
      isDate?: boolean,
      isDateTime?: boolean,
      isInterval?: boolean,
    ) => {
      let finalValue;

      if ((isDate || isDateTime) && value?.startDate) {
        finalValue = moment
          .utc(new Date(value?.startDate))
          .format(`YYYY-MM-DD${isDateTime ? "THH:mm:ss" : ""}`);
        if (isDateTime) {
          finalValue += "Z";
        }
      } else if ((isDate || isDateTime || isInterval) && !value?.startDate) {
        finalValue = null;
      } else if (["DOUBLE", "INTEGER"].includes(validator?.dataType)) {
        finalValue = /\d+/.test(value) ? parseFloat(value) : value;
      } else {
        finalValue = value;
      }

      validator && Appix.validateInputValue(validator, finalValue, formWidget, name);

      const changedData = await formWidget.setDataState({
        [name]: finalValue,
      });
      props.onChange?.(changedData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formWidget, props.onChange],
  );

  const handleInputChangeCallback = useCallback(
    (name, validator, isDate, isDateTime, isInterval) => (nextValue) =>
      handleInputChange(nextValue, name, validator, isDate, isDateTime, isInterval),
    [handleInputChange],
  );

  const isFormErrors = useMemo(() => {
    return Object.keys(formWidget.state.formErrors).length > 0;
  }, [formWidget.state.formErrors]);

  const handleAction = useCallback(
    (actionConfig) => () => {
      if (actionConfig.action && !isFormErrors) {
        onAction?.(actionConfig);
        actionConfig.action();
      }
    },
    [onAction, isFormErrors],
  );

  const setFormFields = useCallback(async () => {
    await formWidget.receiveFields();
  }, [formWidget]);

  const allocatedFields = useMemo(() => {
    const size = props.columns || props.rows || 1;
    const maximumItemsCount = Math.ceil(formWidget.state.fields?.length / size);
    const result: any = [];

    for (let i = 0; i < size; i++) {
      result[i] = [...formWidget.state.fields].slice(
        i * maximumItemsCount,
        i * maximumItemsCount + maximumItemsCount,
      );
    }
    return result;
  }, [formWidget.state.fields, props.columns, props.rows]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.target?.cols) {
        return;
      }
      if (e.key === "Enter") {
        // Задаем дефолтные данные для "нажатия" кнопки сабмита
        let api = "";
        let event = "submitted";

        // Если в форме есть кнопки - берем первую из primary
        // (или просто первую попавшуюся при отсутствии primary-кнопок)
        // "крадем" ее данные и "жмем" ее
        if (formWidget.form?.actions?.length) {
          const primaryButton =
            formWidget.form?.actions.find((action) => action.color === "PRIMARY") ||
            formWidget.form?.actions[0];
          api = primaryButton.postUrl;
          event = primaryButton.event;
        }
        formWidget.performAction(api, event);
      }
    },
    [formWidget],
  );

  return formWidget.state.isLoading ? (
    <Spinner />
  ) : (
    <div
      onKeyDown={handleKeyPress}
      className={clsx({
        "d-none": !formWidget.state.displayed,
        "d-block": formWidget.state.displayed,
        "d-flex": props.rows === 1,
        "form-rows-container": props.rows,
      })}
      id={props.id}
    >
      <CurrentWidgetInfo widget={formWidget} />
      {globalError ? <h6 className="text-danger">{globalError}</h6> : null}
      {(props.columns || props.rows) && (
        <div className={props.columns ? "form-columns" : "w-100"}>
          {allocatedFields?.map((fieldArray, index) => (
            <div key={index} className={props.rows ? "form-rows" : "w-100"}>
              <FormContent
                columns={formWidget.state.columns}
                rows={formWidget.state.rows}
                fields={fieldArray}
                allocatedFields={allocatedFields}
                onChange={handleInputChangeCallback}
                handleInputChange={handleInputChange}
                data={data}
                formErrors={formErrors}
                id={props.id}
                handleBlur={setFormFields}
                handleSearch={handleSearch}
                ctx={formWidget.state.ctx}
                dynamicProperties={formWidget.state.form.dynamicProperties}
              />
            </div>
          ))}
        </div>
      )}
      {!props.columns && !props.rows && (
        <FormContent
          columns={formWidget.state.columns}
          rows={formWidget.state.rows}
          fields={formWidget.state.fields}
          allocatedFields={allocatedFields}
          onChange={handleInputChangeCallback}
          handleInputChange={handleInputChange}
          data={data}
          formErrors={formErrors}
          id={props.id}
          handleBlur={setFormFields}
          handleSearch={handleSearch}
          ctx={formWidget.state.ctx}
          dynamicProperties={formWidget.state.form.dynamicProperties}
        />
      )}

      {actions.length ? (
        <div
          className={clsx("action-buttons", {
            "action-buttons_with-fields": formWidget.state.fields?.length,
          })}
        >
          {actions.map((action) => (
            <AppixButton
              key={action.key}
              id={props.id + action.key}
              beforeIconCode={action.ico}
              color={action.color?.toLowerCase() || "primary"}
              size={"sm"}
              disabled={isFormErrors || formWidget.state.isSendingData}
              isLoading={formWidget.state.isSendingData}
              onClick={handleAction(action)}
              className="action-buttons__item"
            >
              {action.title}
            </AppixButton>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default FormWidget;
