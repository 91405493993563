import React from "react";
import clsx from "clsx";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import RemixIcon from "components/RemixIcon";

interface AppixBlockquoteProps {
  text: string;
  level?: string;
  displayed?: boolean;
  id?: string;
  isClosable?: boolean;
  onClose?: () => void;
  badge?: boolean;
  isNano?: boolean;
}

enum LevelIcon {
  PRIMARY = "information",
  SECONDARY = "information",
  INFO = "information",
  DANGER = "alert",
  WARNING = "error-warning",
  SUCCESS = "checkbox-circle",
  LIGHT = "information",
  DARK = "information",
}

export const AppixBlockquote: React.FC<AppixBlockquoteProps> = ({
  text,
  level,
  displayed,
  id,
  isClosable = true,
  onClose,
  badge: isBadge,
  isNano,
}) => {
  return (
    <blockquote
      id={id}
      className={clsx(`alert text-wrap text-break alert-dismissible fade show`, {
        "d-none": !displayed,
        "d-block": displayed,
        "alert-primary": !level,
        [`alert-${level?.toLowerCase()}`]: level,
        "appix-badge": isBadge || isNano,
        "appix-badge__nano": isNano,
      })}
      role="alert"
    >
      <div className="d-flex align-items-start">
        {!isNano && (
          <RemixIcon icon={level ? LevelIcon[level] : "information"} className="d-flex" />
        )}
        <div className="align-self-center">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
        </div>
      </div>
      {isClosable && !isBadge && !isNano && (
        <button
          type="button"
          className="btn-close me-1"
          data-bs-dismiss={onClose ? undefined : "alert"}
          aria-label="Закрыть"
          onClick={onClose}
        ></button>
      )}
    </blockquote>
  );
};
