import { FC, useCallback, useState } from "react";

import { AppixButton } from "ui";

import ListValueItem from "./ListValueItem";

import { FormControlProps } from "../types";

const ListValueInput: FC<FormControlProps> = (props) => {
  const { id, size, subFields, value, onChange } = props;

  const [items, setItems] = useState<Record<string, any>[]>(
    value?.length ? value.map(() => ({})) : [{}],
  );

  const addItem = useCallback(() => {
    setItems((prevItems) => [...prevItems, {}]);
    const newValue = [...value];
    newValue.push({});
    onChange(newValue);
  }, [onChange, value]);

  const removeItem = useCallback<(index: number) => void>(
    (index) => {
      setItems((prevItems) => {
        const nextItems = [...prevItems];
        nextItems.splice(index, 1);
        return nextItems;
      });
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    },
    [value, onChange],
  );

  const handleChange = useCallback<(index: number) => (nextValue: any) => void>(
    (index) => (nextValue) => {
      const newValue = value ? [...value] : [];
      newValue[index] = nextValue;
      onChange(newValue);
    },
    [value, onChange],
  );

  return subFields?.length ? (
    <div className="list-value">
      {items.map((_item, index) => (
        <ListValueItem
          {...props}
          key={index}
          index={index}
          value={value?.[index] || {}}
          onRemove={removeItem}
          onChange={handleChange(index)}
        />
      ))}

      <div className="list-value__more appix-form-keyvalue__more button">
        <AppixButton
          id={`${id}ADD_MORE`}
          size={size}
          beforeIconCode="add"
          variant="link"
          onClick={addItem}
        >
          Добавить еще
        </AppixButton>
      </div>
    </div>
  ) : null;
};

ListValueInput.defaultProps = {
  value: [],
};

export default ListValueInput;
