import { FC, ChangeEventHandler, useRef, useCallback, useEffect } from "react";

import { AppixCheckboxProps } from "./AppixCheckbox";

interface AppixCheckboxInputProps extends AppixCheckboxProps {}

const AppixCheckboxInput: FC<AppixCheckboxInputProps> = ({
  indeterminate,
  checked,
  isToggler,
  onCheckboxChange,
  ...inputProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onCheckboxChange && onCheckboxChange(e.target.checked);
    },
    [onCheckboxChange],
  );

  useEffect(() => {
    if (inputRef?.current && indeterminate !== undefined) {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);
  return (
    <>
      <input
        ref={inputRef}
        {...inputProps}
        onChange={onChange}
        checked={checked}
        className="form-check-input"
        type="checkbox"
        role="button"
      />
    </>
  );
};

export default AppixCheckboxInput;
