import {
  AppixMonitorResponse,
  MonitorWidgetExpandableSeriesResponse,
  MonitorWidgetExpandableSeriesValueFieldData,
} from "@appix/core";
import React, { useMemo } from "react";
import DataRow from "./DataRow";
import MultiHeader from "./MultiHeader";
import { AppixNoContent } from "ui";

export interface TableMonitorWidgetExpandableSeriesProps
  extends Partial<AppixMonitorResponse.Result> {
  extendedData: MonitorWidgetExpandableSeriesResponse;
  selectedValueFields?: string[];
  ctx?: Record<string, any>;
  handleCustomAction(action: AppixWidget.CustomAction, data: any): void;
  onDoubleClick(data: MonitorWidgetExpandableSeriesValueFieldData): void;
}

const TableMonitorWidgetExpandableSeries: React.FC<TableMonitorWidgetExpandableSeriesProps> = (
  props,
) => {
  const {
    id,
    name,
    description,
    extendedData,
    selectedValueFields,
    ctx,
    handleCustomAction,
    onDoubleClick,
  } = props;

  const monitor = useMemo(() => {
    const { extendedData, ...monitor } = props;
    return monitor;
  }, [props]);

  return (
    <div id={id} className="p-2">
      <div className="h2">{name}</div>
      <p className="mb-2">{description}</p>
      <div className="table-responsive-container">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {extendedData?.header.metrics.map((metric, index) => (
                  <th key={index} scope="col" rowSpan={0}>
                    {metric.text}
                  </th>
                ))}
                {selectedValueFields && selectedValueFields?.length > 1 && (
                  // это колонка для метрик
                  <th rowSpan={0}></th>
                )}
              </tr>

              <MultiHeader data={extendedData?.header.values} />
            </thead>
            <tbody>
              {extendedData?.data?.length ? (
                extendedData?.data.map((dataItem, index) => (
                  <DataRow
                    monitor={monitor}
                    key={index}
                    id={`${id}${index}`}
                    allProps={props}
                    actions={extendedData.actions}
                    header={extendedData.header}
                    data={dataItem}
                    nextItem={extendedData.data[index + 1]}
                    selectedValueFields={selectedValueFields}
                    ctx={ctx}
                    handleCustomAction={handleCustomAction}
                    onDoubleClick={onDoubleClick}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={extendedData?.header?.metrics?.length + 2}>
                    <AppixNoContent />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableMonitorWidgetExpandableSeries;
