import { useCallback, useMemo } from "react";

import { AppixCheckbox } from "../AppixCheckbox";

import TableSearchItem from "./TableSearchItem";

import { AppixTableProps, AppixTableColumn } from "./types";

interface TableHeadProps extends AppixTableProps<any> {}

function TableHead(props: TableHeadProps) {
  const {
    id,
    primaryKey,
    data,
    columns,
    size,
    selectable,
    selectableCount,
    selectedRows,
    setFilterState,
    setSortingState,
    setSelectedRows,
    rowActions,
  } = props;

  const isAllChecked = useMemo<boolean>(() => {
    if (!selectedRows?.length) return false;
    const selectedRowsIds = selectedRows.map((item) => item[primaryKey]);
    return data.every((item) => selectedRowsIds.includes(item[primaryKey]));
  }, [data, primaryKey, selectedRows]);

  const isEmptyActions = useMemo(() => {
    if (rowActions && Object.keys(rowActions).length) {
      return !Object.values(rowActions).some(
        (rowAction) => rowAction && Object.keys(rowAction).length,
      );
    }
    return true;
  }, [rowActions]);

  const handleChangeFilterState = useCallback<
    (nextState: AppixTableProps<any>["filterState"]) => void
  >(
    (nextState) => {
      nextState && setFilterState && setFilterState(nextState);
    },
    [setFilterState],
  );

  const handleCheckboxChange = useCallback<(checked: boolean) => void>(
    (checked) => {
      setSelectedRows && setSelectedRows(!checked ? [] : [...data]);
    },
    [data, setSelectedRows],
  );

  const handleChangeSorting = useCallback(
    (column: AppixTableColumn<any>) => {
      if (setSortingState) {
        setSortingState(column);
      }
    },
    [setSortingState],
  );

  return (
    <>
      <thead>
        <tr>
          {selectable && (
            <th style={{ width: 1, verticalAlign: "bottom" }}>
              <div className="table-cell-header">
                {!selectableCount && (
                  <AppixCheckbox
                    id="select-ALL"
                    checked={isAllChecked}
                    indeterminate={!isAllChecked && !!selectedRows?.length}
                    onCheckboxChange={handleCheckboxChange}
                    onlyCheckbox
                  />
                )}
              </div>
            </th>
          )}

          {columns.map((column, index) => (
            <th key={column.dataIndex} className="align-center">
              <div className="table-cell-header">
                <TableSearchItem
                  id={id}
                  column={column}
                  onChange={handleChangeFilterState}
                  size={size}
                  onChangeSorting={handleChangeSorting}
                />
              </div>
            </th>
          ))}
          {!isEmptyActions && <th style={{ width: 1 }}></th>}
        </tr>
      </thead>
    </>
  );
}

export default TableHead;
