import { ITableWidgetActions } from "@appix/core";
import React from "react";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";
import CardHeader from "./CardHeader";

interface CardProps {
  data: Record<string, any>;
  columns: Record<string, any>[];
  coloringRules: AppixWidget.ColoringRule[];
  rowActions: ITableWidgetActions;
  primaryKey: string;
  id: string;
}

const Card: React.FC<CardProps> = ({
  data,
  columns,
  coloringRules,
  rowActions,
  primaryKey,
  id,
}) => {
  const cardColor = coloringRules
    .find((rule) => {
      return columns.find((column) => {
        const value = data[column.dataIndex];

        // Если значение совпадает со значением в правиле, то оно нам подходит
        if (rule.property === column.dataIndex && rule.value === value) {
          return true;
        }

        // Если есть ограничения по min/max и значение является числом
        // и если значение попадает в промежуток между min и max, то оно нам подходит
        if (
          rule.max &&
          rule.min &&
          typeof value === "number" &&
          rule.max > value &&
          value >= rule.min
        ) {
          return true;
        }
        return false;
      });
    })
    ?.color?.replace("SECONDARY", "DARK");

  return (
    <div className="appix-cards__card__wrapper">
      {cardColor && <div className={`appix-cards__card__color bg-${cardColor.toLowerCase()}`} />}
      <CardHeader data={data} columns={columns} />
      <CardBody data={data} columns={columns} />
      <CardFooter columns={columns} data={data} primaryKey={primaryKey} rowActions={rowActions} />
    </div>
  );
};

export default Card;
