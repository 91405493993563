import { AppixMonitorResponse } from "@appix/core";
import React from "react";

import TableData from "./TableData";

interface TableMonitorValue {
  name: string;
  data: Array<{ value: string | number; datetime: string }>;
}

interface TableMonitorWidgetTimeseriesProps extends Partial<AppixMonitorResponse.Result> {
  extendedData: TableMonitorValue[];
}

const TableMonitorWidgetTimeseries: React.FC<TableMonitorWidgetTimeseriesProps> = (props) => {
  const { id, name, description, granularity, extendedData } = props;

  return (
    <>
      <div className="h2">{name}</div>
      <p className="mb-2">{description}</p>
      <div className="table-responsive-container">
        <div id={id} className="table-responsive h-100">
          <table className="table">
            <tbody>
              <TableData data={extendedData} granularity={granularity} />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TableMonitorWidgetTimeseries;
