import React, { useCallback, useEffect, useMemo, useState } from "react";
import Tree from "../Tree";
import CheckableTreeNodeLabel from "./CheckableTreeNodeLabel";

import {
  tree2list,
  getNextAllChecked,
  getHalfOnFromChecked,
  getOnlyRootNodes,
  getNodesFromKeys,
} from "./utils";
import { AppixCheckableTreeProps, AppixTreeNodeWithKey } from "../types";
import { AppixButton } from "ui/AppixButton";

function CheckableTree<RAW>(props: AppixCheckableTreeProps<RAW>) {
  const {
    id,
    tree,
    isTreeForSelect,
    maxCheckedCount,
    isClearControl,
    isSelectAllControl,
    isSubmitControl,
    checkedKeys,
    labelComponent,
    onChange,
    onSubmit,
    onClear,
    onSelectAll,
  } = props;

  const [draftCheckedKeys, setDraftCheckedKeys] = useState<string[]>([]);
  const [halfOnKeys, setHalfOnKeys] = useState<string[]>([]);
  const [disabledKeys, setDisabledKeys] = React.useState<string[]>([]);

  const nodesList = useMemo(() => tree2list<AppixTreeNodeWithKey<RAW>>(tree), [tree]);

  const makeDisabled = useCallback<(keys: string[]) => void>(
    (checkedKeys) => {
      if (maxCheckedCount && isTreeForSelect) {
        const checkedNodes = getNodesFromKeys<RAW>(nodesList, checkedKeys);
        const onlyRootCheckedKeys = getOnlyRootNodes<RAW>(checkedNodes).map(({ key }) => key);
        if (onlyRootCheckedKeys.length >= maxCheckedCount) {
          const allDisabledKeys = nodesList
            .map(({ key }) => key)
            // кроме выбранных
            .filter((key) => !onlyRootCheckedKeys.includes(key));
          setDisabledKeys(allDisabledKeys);
        } else {
          setDisabledKeys([]);
        }
      }
    },
    [nodesList, maxCheckedCount, isTreeForSelect],
  );

  const handleCheckboxChange = useCallback(
    (node: AppixTreeNodeWithKey<RAW>) => {
      const actionType = draftCheckedKeys.includes(node.key) ? "uncheck" : "check";
      const nextCheckedKeys = getNextAllChecked<RAW>({
        keys: [node.key],
        actionType,
        nodesList,
        allCheckedKeys: draftCheckedKeys,
      });
      const checkedNodes = getNodesFromKeys<RAW>(nodesList, nextCheckedKeys);
      const onlyRootCheckedKeys = getOnlyRootNodes<RAW>(checkedNodes).map(({ key }) => key);

      const nextHalfOnKeys = getHalfOnFromChecked<RAW>({
        allCheckedKeys: nextCheckedKeys,
        nodesList,
      });
      setHalfOnKeys(nextHalfOnKeys);
      makeDisabled(nextCheckedKeys);
      if (onChange) {
        onChange(onlyRootCheckedKeys, {
          changedNodeKey: node.key,
        });
      } else {
        setDraftCheckedKeys(nextCheckedKeys);
      }
    },
    [nodesList, draftCheckedKeys, onChange, makeDisabled],
  );

  const labelCheckableComponent = useCallback(
    ({
      node,
      label,
      depth,
    }: {
      node: AppixTreeNodeWithKey<RAW>;
      label: React.ReactElement;
      depth?: number;
    }) => {
      const checked = halfOnKeys.includes(node.key)
        ? "halfOn"
        : draftCheckedKeys.includes(node.key);
      function onChange() {
        handleCheckboxChange(node);
      }
      return (
        <CheckableTreeNodeLabel
          id={id}
          node={node}
          label={label}
          disabled={disabledKeys.includes(node.key)}
          labelComponent={labelComponent}
          // eslint-disable-next-line
          onChange={onChange}
          checked={checked}
          depth={depth!}
        />
      );
    },
    [halfOnKeys, handleCheckboxChange, draftCheckedKeys, labelComponent, id, disabledKeys],
  );

  const submit = useCallback(() => {
    if (onSubmit) {
      onSubmit();
    }
  }, [onSubmit]);

  useEffect(() => {
    if (checkedKeys) {
      const nextCheckedKeys = getNextAllChecked<RAW>({
        keys: checkedKeys,
        actionType: "check",
        nodesList,
        allCheckedKeys: [],
      });
      const nextHalfOnKeys = getHalfOnFromChecked({
        allCheckedKeys: nextCheckedKeys,
        nodesList,
      });
      setHalfOnKeys(nextHalfOnKeys);
      setDraftCheckedKeys(nextCheckedKeys);
      makeDisabled(nextCheckedKeys);
    }
  }, [checkedKeys, nodesList, makeDisabled]);

  const clear = useCallback(() => {
    setHalfOnKeys([]);
    setDraftCheckedKeys([]);
    if (onClear) {
      onClear();
    }
  }, [onClear]);

  const selectAll = useCallback(() => {
    const onlyRootCheckedKeys = getOnlyRootNodes<RAW>(nodesList).map(({ key }) => key);
    const nodesKeys = nodesList.map(({ key }) => key);
    setDraftCheckedKeys([...nodesKeys, ...onlyRootCheckedKeys]);
    if (onSelectAll) {
      onSelectAll(onlyRootCheckedKeys);
    }
  }, [nodesList, onSelectAll]);

  return (
    <>
      <Tree {...props} labelComponent={labelCheckableComponent} />
      <div className="d-flex justify-content-end">
        {isClearControl && (
          <AppixButton onClick={clear} title="Сбросить выбор" color="primary"></AppixButton>
        )}
        {isSelectAllControl && (
          <AppixButton
            onClick={selectAll}
            title="Выбрать все"
            className="ms-1"
            color="primary"
          ></AppixButton>
        )}
        {isSubmitControl && (
          <AppixButton
            onClick={submit}
            title="Применить"
            color="primary"
            className="ms-1"
          ></AppixButton>
        )}
      </div>
    </>
  );
}

export default CheckableTree;
