import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

import { Widget } from "containers";

const getNodeBodyContainer = (nodeId?: string | null) =>
  document.querySelector(`[data-nodeid="${nodeId}"] .nodes-editor__node__body`);

interface ChainCustomContentProps {
  nodes: AppixWidget.Chain.Node[];
}

const ChainCustomContent: React.FC<ChainCustomContentProps> = ({ nodes }) => {
  const [, setAllowRender] = useState<boolean>();

  // эта штука ждет когда появится див с кастом контентом
  // и ререндерит
  useEffect(() => {
    const timer = setInterval(() => {
      if (nodes.some(({ id }) => !!getNodeBodyContainer(id))) {
        setAllowRender(true);
        clearInterval(timer);
      }
    }, 40);

    return () => {
      setAllowRender(false);
      clearInterval(timer);
    };
  }, [nodes]);

  return (
    <>
      {nodes.map((node) => {
        if (!node.customContent) return null;

        const $container = getNodeBodyContainer(node.id);

        if (!$container) return null;

        return (
          <React.Fragment key={node.id}>
            {createPortal(<Widget {...node.customContent} />, $container)}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ChainCustomContent;
