import { FC } from "react";

export enum AppixDropdownPosition {
  topLeft = "top-left",
  topRight = "top-right",
  topCenter = "top-center",
  bottomLeft = "bottom-left",
  bottomRight = "bottom-right",
  bottomCenter = "bottom-center",
}

export type AppixDropdownToggleOpened = (flag?: boolean) => void;

export interface AppixDropdownViewProps {
  opened: boolean;
  toggleOpened: AppixDropdownToggleOpened;
}

export interface AppixDropdownProps {
  dropdownId: string;
  withAngle?: boolean;
  position?: AppixDropdownPosition;
  ViewComponent: FC<AppixDropdownViewProps>;
  className?: string;
}
