import { FC } from "react";
import clsx from "clsx";

import RemixIcon from "components/RemixIcon";

import { AppixSize } from "../types";
import { AppixBlockquote } from "ui/AppixBlockquote/AppixBlockquote";

export enum AppixButtonVariant {
  default = "default",
  outline = "outline",
  link = "link",
  icon = "icon",
}

export enum AppixButtonColor {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
  light = "light",
  dark = "dark",
}

const getCounterColor = (color) => {
  switch (color) {
    case "primary":
      return "light";
    case "info":
      return "primary";
    case "light":
    case "dark":
      return "secondary";
    default:
      return color;
  }
};

export interface AppixButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title?: string;
  color?: keyof typeof AppixButtonColor;
  variant?: keyof typeof AppixButtonVariant;
  size?: keyof typeof AppixSize;
  active?: boolean;
  fullWidth?: boolean;
  beforeIconCode?: string | null;
  afterIconCode?: string | null;
  isLoading?: boolean;
  counter?: AppixWidget.Button.Counter | null;
}

export const AppixButton: FC<AppixButtonProps> = ({
  title,
  color,
  variant,
  size = "sm",
  active,
  fullWidth,
  beforeIconCode,
  afterIconCode,
  children,
  isLoading,
  className,
  counter,
  ...buttonProps
}) => {
  return (
    <button
      {...buttonProps}
      className={clsx("btn", className, {
        [`btn-${color}`]: !!color,
        [`btn-${size}`]: size,
        [`btn-outline-${color}`]: variant === "outline",
        "btn-link": variant === "link",
        "btn-icon": variant === "icon",
        "btn-block": fullWidth,
        active: !!active,
      })}
      type="button"
    >
      {isLoading && (
        <span
          className={`spinner-border spinner-border-btn-${size} me-2`}
          role="status"
          aria-hidden="true"
        ></span>
      )}
      {beforeIconCode && (
        <span
          className={clsx("btn__icon", {
            btn__icon_before: children || title,
          })}
        >
          <RemixIcon icon={beforeIconCode} />
        </span>
      )}
      <span className="btn__content">{children || title}</span>

      {afterIconCode && (
        <span
          className={clsx("btn__icon", {
            btn__icon_after: children || title,
          })}
        >
          <RemixIcon icon={afterIconCode} />
        </span>
      )}
      {counter && (
        <AppixBlockquote
          badge={true}
          isNano={true}
          displayed={true}
          text={counter.value.toString()}
          level={getCounterColor(color)}
        />
      )}
    </button>
  );
};
