import { AppixMonitorResponse } from "@appix/core";
import React from "react";

import TableData from "./TableData";

export interface TableMonitorValue {
  data: { value: number };
}

interface TableMonitorWidgetBreakdownProps extends Partial<AppixMonitorResponse.Result> {
  extendedData: TableMonitorValue;
}

const TableMonitorWidget: React.FC<TableMonitorWidgetBreakdownProps> = (props) => {
  const { id, name, description, extendedData } = props;

  return (
    <>
      <div className="h2">{name}</div>
      <p className="mb-2">{description}</p>
      <div className="table-responsive-container">
        <div id={id} className="table-responsive h-100">
          <table className="table">
            <tbody>
              <TableData data={extendedData} />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TableMonitorWidget;
