import React from "react";

import { AppixCheckbox } from "ui";

import { AppixTreeProps, AppixTreeNodeWithKey } from "../types";

interface CheckableTreeNodeLabelProps<RAW> {
  id: string;
  node: AppixTreeNodeWithKey<RAW>;
  label: React.ReactElement;
  labelComponent: AppixTreeProps<RAW>["labelComponent"];
  onChange: (flag: boolean) => void;
  checked: "halfOn" | boolean;
  disabled?: boolean;
  depth: number;
}

function CheckableTreeNodeLabel<RAW>({
  id,
  node,
  label,
  labelComponent,
  onChange,
  checked,
  disabled,
  depth,
}: CheckableTreeNodeLabelProps<RAW>) {
  const isChecked = checked === true;

  const handleCheckboxChange = React.useCallback<(checked: boolean) => void>(
    (checked) => {
      onChange(checked);
    },
    [onChange],
  );

  return (
    <label className="d-flex">
      <AppixCheckbox
        id={`${id}CHECKBOX${node.value}`}
        onCheckboxChange={handleCheckboxChange}
        checked={isChecked}
        indeterminate={checked === "halfOn"}
        disabled={node.disabled || disabled}
      />
      {labelComponent ? labelComponent({ node, label, depth }) : <span>{label}</span>}
    </label>
  );
}

export default CheckableTreeNodeLabel;
