import React, { useCallback } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { isJson } from "utils";

interface AppixCodeEditorProps {
  id: string;
  value: string;
  language?: string;
  readOnly?: boolean;
  onChange?: (e: { target: any }) => void;
}

export const AppixCodeEditor: React.FC<AppixCodeEditorProps> = ({
  id,
  value,
  language = "javascript",
  readOnly,
  onChange,
}) => {
  const handleChange = useCallback(
    (value) => {
      onChange && onChange(value);
    },
    [onChange],
  );

  return (
    <div data-color-mode="light">
      <CodeEditor
        id={id}
        value={
          language.toLocaleLowerCase() === "json" && isJson(value) && value
            ? JSON.stringify(JSON.parse(value), null, 2)
            : value ?? ""
        }
        language={language.toLocaleLowerCase()}
        // eslint-disable-next-line
        onChange={(evn) => handleChange(evn.target.value)}
        placeholder=""
        readOnly={readOnly}
        minHeight={150}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
        }}
      />
    </div>
  );
};
