import TableRow from "./TableRow";

import { AppixTableBaseRow, AppixTableProps } from "./types";

function TableBody<ROW extends AppixTableBaseRow>({ data, ...props }: AppixTableProps<ROW>) {
  return (
    <tbody>
      {data.map((row, index) => (
        <TableRow<ROW> key={index} {...props} row={row} />
      ))}
    </tbody>
  );
}

export default TableBody;
