import React, { useCallback } from "react";
import Editor from "md-editor-rt";
import "md-editor-rt/lib/style.css";

interface AppixMDEditorProps {
  id: string;
  value: string;
  onChange: (e: { target: any }) => void;
}

export const AppixMDEditor: React.FC<AppixMDEditorProps> = ({ id, onChange, value }) => {
  const handleChange = useCallback(
    (value) => {
      onChange({ target: { value } });
    },
    [onChange],
  );

  return (
    <Editor
      editorId={id}
      className=" bg-opacity-25 rounded-2"
      // != Check for both undefined and null values.
      modelValue={value != null ? value : ""}
      language="en-US"
      onChange={handleChange}
      previewTheme="github"
    />
  );
};
