import React from "react";

import Tree from "./Tree";
import CheckableTree from "./CheckableTree";

import {
  AppixTreeProps,
  AppixCheckableTreeProps,
  AppixTreeNodeState,
  AppixTreeNodeWithKey,
} from "./types";

type BaseTreeProps<RAW> = AppixTreeProps<RAW> &
  AppixCheckableTreeProps<RAW> & {
    checkable?: boolean;
  };

function BaseTree<RAW>(props: BaseTreeProps<RAW>) {
  const { tree, checkable, onChange, checkedKeys } = props;

  const draftTree = React.useMemo<AppixTreeNodeState<RAW>[]>(() => {
    const getDraftTree = (tree: AppixTreeNodeWithKey<RAW>[]): AppixTreeNodeState<RAW>[] => {
      return tree.map((node) => {
        if (node.children?.length) {
          return {
            opened: true,
            hidden: false,
            ...node,
            children: getDraftTree(node.children),
          };
        }
        return {
          opened: false,
          hidden: false,
          ...node,
        };
      });
    };

    return getDraftTree(tree);
  }, [tree]);

  return (
    <>
      {checkable && onChange ? (
        <CheckableTree {...props} tree={draftTree} onChange={onChange} checkedKeys={checkedKeys} />
      ) : (
        <Tree<RAW> {...props} tree={draftTree} />
      )}
    </>
  );
}

BaseTree.defaultProps = {
  tree: [],
  checkedKeys: [],
};

export default BaseTree;
