import clsx from "clsx";
import RemixIcon from "components/RemixIcon";
import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { AppixSize } from "../types";
import { IAppixSelectItem } from "../AppixSelect/types";

import moment from "moment";

interface AppixTagsRowProps {
  id: string;
  tagsId?: string;
  withSearch?: boolean;
  tags: IAppixSelectItem[];
  size?: keyof typeof AppixSize;
  disabled?: boolean;
  readOnly?: boolean;
  removable?: boolean;
  placeholder?: string;
  searchValue?: string;
  onClick?(): void;
  onAddTag?(tag: string): void;
  onRemoveTag?(index: number): void;
  onSearchChange?(value: string): void;
}

export const AppixTagsRow: FC<AppixTagsRowProps> = ({
  id,
  tags,
  tagsId,
  withSearch,
  size = "md",
  disabled,
  readOnly,
  removable,
  placeholder = "Введите тег",
  searchValue,
  onClick,
  onRemoveTag,
  onAddTag,
  onSearchChange,
}) => {
  const tagsRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState<boolean>();
  const [inputValue, setInputValue] = useState<string>("");
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target?.value ?? "";
      onSearchChange && onSearchChange(value);
    },
    [onSearchChange],
  );

  const addTag = useCallback(() => {
    onAddTag && onAddTag(inputValue);
    setInputValue("");
  }, [inputValue, onAddTag]);

  const removeLastTag = useCallback(() => {
    if (tags?.length) {
      onRemoveTag && onRemoveTag(tags.length - 1);
    }
  }, [tags, onRemoveTag]);

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick && onClick();
    }
  }, [disabled, onClick]);

  const onWheel = useCallback<(e: WheelEvent) => void>((e) => {
    e.preventDefault();

    if (tagsRef.current) {
      tagsRef.current.scrollLeft += 20 * (e.deltaY < 0 ? -1 : 1);
      setScrollLeft(tagsRef.current.scrollLeft);
    }

    return false;
  }, []);

  const onLeftScroll = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.stopPropagation();
    if (tagsRef.current) {
      tagsRef.current.scrollLeft -= 20;
      setScrollLeft(tagsRef.current.scrollLeft);
    }
  }, []);

  const onRightScroll = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      if (tagsRef.current) {
        tagsRef.current.scrollLeft += 20;
        setScrollLeft(tagsRef.current.scrollLeft);
      }
      if (!disabled && !tags?.length) {
        onClick && onClick();
      }
    },
    [onClick, disabled, tags],
  );

  const onTagRemove = useCallback<(index: number) => MouseEventHandler<HTMLButtonElement>>(
    (index) => (e) => {
      e.stopPropagation();
      onRemoveTag && onRemoveTag(index);
    },
    [onRemoveTag],
  );

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleInputFocus = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
    setFocused(true);
  }, []);

  const handleInputBlur = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
    setFocused(false);
  }, []);

  const handleInputKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      e.stopPropagation();
      if (e.key === "Enter") {
        addTag();
      } else if (e.key === "Delete") {
        removeLastTag();
      }
    },
    [addTag, removeLastTag],
  );

  useEffect(() => {
    if (tagsRef.current) {
      setScrollLeft(tagsRef.current.scrollLeft);
    }
  }, [tags]);

  useEffect(() => {
    const ref = tagsRef.current;
    if (ref) {
      ref.addEventListener("wheel", onWheel, { passive: false });
    }
    return () => {
      if (ref) {
        ref.removeEventListener("wheel", onWheel);
      }
    };
  }, [onWheel]);

  return (
    <div
      id={tagsId ?? id}
      className={clsx("tag-input", {
        [`tag-input-${size}`]: !!size,
        focused: !!focused,
        disabled: !!disabled,
      })}
      onClick={handleClick}
    >
      {scrollLeft ? (
        <button
          className="tag-input__scroll-btn tag-input__scroll-btn_left"
          type="button"
          onClick={onLeftScroll}
        >
          <RemixIcon icon="arrow-left-s" />
        </button>
      ) : null}

      <div
        ref={tagsRef}
        className={clsx("tag-input__tags", {
          "tag-input__tags_notleft": !scrollLeft,
        })}
      >
        {tags.map((tag, index) => {
          let label = tag.label?.toString();
          // определяем, что нам пришла дата
          if (label?.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/gm)?.[0]) {
            label = moment.utc(tag.label).format("DD.MM.YYYY");
          }
          return (
            <div
              key={index}
              title={tag.label}
              className={clsx("tag-input__tag", {
                "tag-input__tag_inactive": tag.value === false,
              })}
            >
              {label}

              {removable ? (
                <button
                  id={`${id}CLOSE${index}`}
                  type="button"
                  className="tag-input__remove"
                  onClick={onTagRemove(index)}
                >
                  <RemixIcon icon="close" />
                </button>
              ) : null}
            </div>
          );
        })}

        {!readOnly && (
          <input
            autoComplete="off"
            id={`${id}SEARCH`}
            className="tag-input__input"
            type="text"
            placeholder={placeholder}
            disabled={disabled}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
          />
        )}
        {readOnly && withSearch && (
          <input
            autoComplete="off"
            id={`${id}SEARCH`}
            className="tag-input__input"
            type="text"
            disabled={disabled}
            value={searchValue}
            onChange={handleSearchChange}
          />
        )}
      </div>
      <button
        className="tag-input__scroll-btn tag-input__scroll-btn_right"
        type="button"
        onClick={onRightScroll}
      >
        <RemixIcon icon="arrow-right-s" />
      </button>
    </div>
  );
};
