import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import RemixIcon from "components/RemixIcon";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import { CalendarSelector } from "./CalendarSelector";
import { ClickAwayListener } from "../ClickAwayListener";
import clsx from "clsx";

export interface AppixDatePickerProps {
  boxed?: boolean;
  selectRange?: boolean;
  onChange: (e: { target: { value: Record<string, any> } }) => void;
  defaultView: "month" | "year" | "decade";
  validator?: any;
  withTime?: boolean;
  value?: any;
  id?: string;
  helper?: string;
  disabled?: boolean;
  isRequired?: boolean;
  name?: string;
}

interface ChosenDateState {
  startDate: Date | null;
  endDate: Date | null;
  granularity: string | null;
}

export const AppixDatePicker: React.FC<AppixDatePickerProps> = ({
  boxed = true,
  selectRange,
  onChange,
  defaultView,
  validator,
  withTime,
  value,
  id,
  helper,
  disabled,
  isRequired,
  name = "",
  ...props
}) => {
  const availableGranularity = validator?.availableGranularity;
  const [opened, setOpened] = useState(false);
  const [chosenDate, setChosenDate] = useState<ChosenDateState>({
    startDate: value?.startDate ? moment(value?.startDate).toDate() : null,
    endDate: value?.endDate ? moment(value?.endDate).toDate() : null,
    granularity: availableGranularity?.[0] || "DAY",
  });

  useEffect(() => {
    if (typeof value === "object") {
      value &&
        setChosenDate({
          startDate: value?.startDate ? moment(value?.startDate).toDate() : null,
          endDate: value?.endDate ? moment(value?.endDate).toDate() : null,
          granularity: availableGranularity?.[0] || "DAY",
        });
    } else if (value) {
      setChosenDate({
        startDate: moment(value).toDate(),
        endDate: moment(value).toDate(),
        granularity: availableGranularity?.[0] || "DAY",
      });
    }
  }, [availableGranularity, value]);

  const getNavigationLabel = useCallback(({ label }) => {
    label = label[0].toUpperCase() + label.slice(1);
    return label.replace("г.", "");
  }, []);

  const handleToggleCalendar = useCallback(() => {
    !disabled && setOpened((prev) => !prev);
  }, [disabled]);

  const onChangeHandler = useCallback((e, granularity) => {
    if (Array.isArray(e)) {
      setChosenDate({
        startDate: e[0] ? moment(e[0]).startOf("day").toDate() : null,
        endDate: e[1] ? moment(e[1]).startOf("day").toDate() : null,
        granularity,
      });
    } else {
      setChosenDate({
        startDate: moment(e).toDate(),
        endDate: moment(e).toDate(),
        granularity,
      });
    }
  }, []);

  const handleSelectDate = useCallback(() => {
    const finalDate: Record<string, any> = { ...chosenDate };
    if (!withTime) {
      finalDate.startDate = moment(finalDate.startDate).format("YYYY-MM-DD");
      finalDate.endDate = moment(finalDate.endDate).format("YYYY-MM-DD");
    } else {
      finalDate.startDate = moment(finalDate.startDate).format("YYYY-MM-DDTHH:mm:ss") + "Z";
      finalDate.endDate = moment(finalDate.endDate).format("YYYY-MM-DDTHH:mm:ss") + "Z";
    }
    chosenDate &&
      onChange({
        target: {
          value: finalDate,
        },
      });
    setOpened(false);
  }, [chosenDate, onChange, withTime]);

  const handleStartChange = useCallback((e) => {
    setChosenDate((prev) => {
      return { ...prev, startDate: e };
    });
  }, []);

  const handleEndChange = useCallback((e) => {
    setChosenDate((prev) => {
      return { ...prev, endDate: e };
    });
  }, []);

  const inputDate = useMemo(() => {
    if (value && typeof value === "string") {
      return `${moment(value).format(`DD.MM.YYYY${withTime ? " HH:mm" : ""}`)}`;
    }
    if (value && typeof value === "object") {
      if (value.startDate && value.endDate) {
        if (selectRange) {
          return `${moment(value?.startDate).format(
            `DD.MM.YYYY${withTime ? " HH:mm" : ""}`,
          )} - ${moment(value?.endDate).format(`DD.MM.YYYY${withTime ? " HH:mm" : ""}`)}`;
        } else {
          return `${moment(value?.startDate).format(`DD.MM.YYYY${withTime ? " HH:mm" : ""}`)}`;
        }
      }
    }
    return `Укажите дату ${withTime ? "и время" : ""}`;
  }, [value, selectRange, withTime]);

  const handleReset = useCallback(
    (e) => {
      e.stopPropagation();
      if (disabled) {
        return;
      }
      setChosenDate({
        startDate: null,
        endDate: null,
        granularity: null,
      });
      onChange({
        target: {
          value: {
            startDate: null,
            endDate: null,
            granularity: null,
          },
        },
      });
      opened && setOpened(false);
    },
    [disabled, onChange, opened],
  );

  const handleClickAway = useCallback((e) => {
    // if (
    //   (containerRef?.current &&
    //     (containerRef?.current as Element)?.contains(e.target as Node)) ||
    //   e.target.nodeName === "ABBR" ||
    //   (e.target.nodeName === "BUTTON" &&
    //     e.target.classList.contains("react-calendar__tile"))
    // ) {
    //   return;
    // }
    setOpened(false);
  }, []);

  const isEmpty = typeof value === "object" ? !value.startDate : !value;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Dropdown>
        <div>
          <Dropdown.Toggle
            as="div"
            id={id + name}
            bsPrefix={clsx("position-relative input-group", {
              "is-invalid": helper,
              disabled,
              active: opened,
            })}
          >
            <div
              className={clsx("form-control rangepicker-input", {
                disabled,
                "rangepicker-input_empty": isEmpty,
              })}
              onClick={handleToggleCalendar}
            >
              <div className="rangepicker-input__date">
                <span>{inputDate}</span>
                <div className="rangepicker-input__reset">
                  {!isEmpty && !disabled && !isRequired && (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 0, hide: 100 }}
                      overlay={<Tooltip id="button-tooltip">Очистить</Tooltip>}
                    >
                      <div className={"rangepicker-input__reset-icon"}>
                        <RemixIcon
                          icon="close"
                          iconSize="fw"
                          iconStyle="fill"
                          isButton={true}
                          onClick={handleReset}
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                  <RemixIcon icon="calendar" iconSize="1x" iconStyle="line" />
                </div>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={opened}
            renderOnMount={true}
            bsPrefix={clsx("calendar-dropdown appix-dropdown__menu", {
              "d-block": opened,
              "d-none": !opened,
            })}
          >
            <div className="calendar-selector">
              <CalendarSelector
                chosenDate={chosenDate}
                getNavigationLabel={getNavigationLabel}
                onChangeHandler={onChangeHandler}
                handleSelectDate={handleSelectDate}
                handleStartChange={handleStartChange}
                handleEndChange={handleEndChange}
                selectRange={selectRange}
                defaultView={defaultView}
                withTime={withTime}
                value={value}
                boxed={boxed}
                validator={validator}
                id={id}
                handleToggleCalendar={handleToggleCalendar}
                availableGranularity={availableGranularity}
                name={name}
              />
            </div>
          </Dropdown.Menu>
          {helper && (
            <div
              className={clsx({
                "invalid-feedback": helper,
              })}
            >
              {helper}
            </div>
          )}
        </div>
      </Dropdown>
    </ClickAwayListener>
  );
};
