import React from "react";

import clsx from "clsx";

import RemixIcon from "components/RemixIcon";

import { AppixTreeProps, AppixTreeNodeState } from "./types";

interface TreeNodeProps<RAW> extends Omit<AppixTreeProps<RAW>, "tree"> {
  node: AppixTreeNodeState<RAW>;
  depth: number;
}

function TreeNode<RAW>({ node, depth, ...props }: TreeNodeProps<RAW>) {
  const { id, childrenOpened, labelComponent } = props;

  const [opened, setOpened] = React.useState<boolean>(!!childrenOpened);

  const handleToggle = React.useCallback((e) => {
    e.stopPropagation();
    if (!e.target.className.includes("apx-tree__row")) {
      return;
    }
    setOpened((prevOpened) => !prevOpened);
  }, []);

  const draftLabel = React.useMemo<React.ReactElement | null>(() => {
    let label = (
      <div className="flex-grow-1 apx-tree__row___label" role="button">
        {node.label || node.value}
      </div>
    );

    if (labelComponent) {
      return labelComponent({
        node,
        label,
        depth,
        setOpened,
      });
    }

    return label;
  }, [labelComponent, node, depth]);

  React.useEffect(() => {
    if (childrenOpened !== undefined) {
      setOpened(childrenOpened);
    }
  }, [childrenOpened]);

  React.useEffect(() => {
    if (node.opened !== undefined) {
      setOpened(Boolean(node.opened));
    }
  }, [node.opened]);

  return !node.hidden ? (
    <div
      id={`${id}${node.value}`}
      className={clsx("apx-tree__item", {
        "apx-tree__item--root": depth === 0,
        "apx-tree__item--opened": opened,
      })}
    >
      <div
        className="apx-tree__row"
        onClick={node.children?.length ? handleToggle : undefined}
        role="button"
      >
        <div className="apx-tree__row__content">{draftLabel}</div>

        {node.children?.length ? (
          <RemixIcon className="apx-tree__row__toggler" icon="arrow-down-s" />
        ) : null}
      </div>

      {opened && node.children && (
        <div className="apx-tree__children">
          {node.children.map((node, index) => (
            <TreeNode<RAW> {...props} key={index} node={node} depth={depth + 1} />
          ))}
        </div>
      )}
    </div>
  ) : null;
}

export default TreeNode;
