import React, { useCallback, useState } from "react";
import clsx from "clsx";

interface AccordionItemProps extends AppixWidget.Accordion.Section {}

const AccordionItem: React.FC<AccordionItemProps> = ({ isExpanded, caption, sectionContent }) => {
  const [isShow, setIsShow] = useState<boolean>(isExpanded);

  const handleClick = useCallback(() => {
    setIsShow((isShow) => !isShow);
  }, []);

  return (
    <div className="accordion-item bg-transparent">
      <p className="accordion-header">
        <button
          className={clsx("accordion-button bg-transparent", {
            collapsed: !isShow,
          })}
          onClick={handleClick}
          type="button"
        >
          {caption}
        </button>
      </p>
      <div
        className={clsx("accordion-collapse collapse", {
          show: isShow,
        })}
      >
        <div className="accordion-body">{sectionContent?.text}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
