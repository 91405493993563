import { AppixBlockquote } from "ui/AppixBlockquote/AppixBlockquote";

export const ConcatenatedNames: React.FC<any> = ({
  placeholder = "Выберите значение",
  onClick,
  tags = [],
  opened,
}) => {
  return (
    <div
      className={`c-appixselect-concatenatednames form-select  ${opened ? "opened" : ""}`}
      onClick={onClick}
    >
      <div
        className={`c-appixselect-concatenatednames__content ${
          tags?.length > 0 ? "" : "iplaceholder"
        }`}
      >
        {tags?.map((tag) => tag.label).join(", ") || placeholder}
      </div>

      {tags?.length > 1 && (
        <div className="c-appixselect-concatenatednames__counter">
          <AppixBlockquote text={tags?.length.toString()} isNano displayed level="secondary" />
        </div>
      )}
    </div>
  );
};
