import React, { useEffect } from "react";
import clsx from "clsx";
import { INITIAL_VIDEO_WIDGET_STATE, WebViewWidgetClass } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const WebviewWidget: React.FC<AppixWidget.WebView> = (props) => {
  const webViewWidget = useWidget<AppixWidget.WebView>(
    props,
    INITIAL_VIDEO_WIDGET_STATE,
    WebViewWidgetClass,
    [props.h, props.w, props.initData, props.url],
  );

  useEffect(() => {
    webViewWidget.handleFrameLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webViewWidget.iframe]);

  return (
    <div
      className={clsx("my-1 position-relative", {
        "d-none": !webViewWidget.state.displayed,
        "d-block": webViewWidget.state.displayed,
      })}
      style={{
        height: webViewWidget.state.h ? `${webViewWidget.state.h + "px"}` : "500px",
        width: webViewWidget.state.w ? `${webViewWidget.state.w + "px"}` : "auto",
      }}
    >
      <CurrentWidgetInfo widget={webViewWidget} />

      {webViewWidget.state.isLoading && <Spinner />}
      {!webViewWidget.state.isLoading && (
        <iframe
          title={props.id}
          id={props.id}
          src={webViewWidget.state.url}
          height={webViewWidget.state.h || "100%"}
          width={webViewWidget.state.w || "100%"}
        ></iframe>
      )}
    </div>
  );
};

export default WebviewWidget;
