import React, { useEffect, useRef, useState } from "react";
import { AppixSize } from "../types";
import { Tooltip } from "bootstrap/dist/js/bootstrap.bundle.js";
import clsx from "clsx";

export enum Placement {
  "auto-start",
  "auto",
  "auto-end",
  "top-start",
  "top",
  "top-end",
  "right-start",
  "right",
  "right-end",
  "bottom-end",
  "bottom",
  "bottom-start",
  "left-end",
  "left",
  "left-start",
}

export interface AppixTooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  placement?: keyof typeof Placement;
  label: string;
  children: any;
  size?: keyof typeof AppixSize;
  isHidden?: boolean;
  style?: Record<string, string>;
  className?: string;
}

export const AppixTooltip: React.FC<AppixTooltipProps> = ({
  placement,
  label,
  children,
  size = "md",
  isHidden,
  style,
  className,
  ...props
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = useState<any>();

  useEffect(() => {
    if (window !== undefined && tooltipRef?.current) {
      setTooltip(new Tooltip(tooltipRef.current, { boundary: document.body, trigger: "hover" }));
    }
  }, []);

  useEffect(() => {
    if (tooltip) {
      if (!isHidden) {
        tooltip.enable();
      }
      if (isHidden) {
        tooltip.disable();
      }
      tooltip.update();
    }
  }, [isHidden, tooltip]);

  return (
    <div
      className={clsx(className)}
      ref={tooltipRef}
      style={{ width: "fit-content", ...style }}
      data-bs-toggle="tooltip"
      data-bs-placement={placement}
      title={label}
    >
      {children}
    </div>
  );
};
