import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppixPage } from "@appix/core";

import Appix from "Appix";

import { UserWidget } from "widgets";
import { AppixNavigation, AppixSidebar } from "ui";
import { collapseSlashes } from "utils";

interface IMenuData {
  siteName?: string;
  mainMenuItems?: Array<AppixPage.Layout.MenuItem>;
  secondaryMenuItems?: Array<AppixPage.Layout.MenuItem>;
  loginInfoLocation?: AppixPage.Layout["loginInfoLocation"];
  loginPageLocation?: AppixPage.Layout["loginPageLocation"];
  authToken?: string;
}

interface SidebarMenuProps {
  menuData: IMenuData;
  sidebarCollapsed: boolean;
  setSidebarCollapsed: (collapse: boolean) => void;
}

const SidebarMenu: FC<SidebarMenuProps> = ({ menuData, sidebarCollapsed, setSidebarCollapsed }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const changeRoute = useCallback(
    (path: string) => {
      navigate(collapseSlashes(Appix.baseUrl + path));
    },
    [navigate],
  );

  return (
    <AppixSidebar
      siteName={menuData?.siteName || ""}
      bottomSlot={
        <UserWidget
          loginInfoLocation={menuData?.loginInfoLocation || undefined}
          loginPageLocation={menuData?.loginPageLocation || undefined}
          baseUrl={Appix.baseUrl}
          collapsed={sidebarCollapsed}
        />
      }
      collapsed={sidebarCollapsed}
      onCollapse={setSidebarCollapsed}
      sticky
    >
      <AppixNavigation
        id="SideMenu"
        items={menuData?.mainMenuItems || []}
        currentLocation={pathname}
        onItemClick={changeRoute}
        collapsed={sidebarCollapsed}
      />
    </AppixSidebar>
  );
};

export default SidebarMenu;
