import clsx from "clsx";
import moment from "moment";
import "moment/locale/ru"; // without this line it didn't work
import RemixIcon from "components/RemixIcon";

import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import Calendar from "react-calendar";
import { AppixButton } from "../AppixButton";
import { AppixTimePicker } from "../AppixTimePicker";
import { AppixWidgetValidator } from "@appix/core";
import InlineSelectInput from "components/FormControl/InlineSelectInput";
import { createPortal } from "react-dom";

enum Granularities {
  TIMESTAMP = "Временная метка",
  SECOND = "Секунда",
  MINUTE = "Минута",
  HOUR = "Час",
  DAY = "День",
  WEEK = "Неделя",
  MONTH = "Месяц",
  QUARTER = "Квартал",
  YEAR = "Год",
}

interface CalendarSelectorPorps {
  chosenDate: {
    startDate: Date | null;
    endDate: Date | null;
    granularity: string | null;
  };
  getNavigationLabel: ({ label }: { label: any }) => any;
  onChangeHandler: (e: any, granularity: any) => void;
  handleSelectDate: () => void;
  handleStartChange: (e: any) => void;
  handleEndChange: (e: any) => void;
  selectRange?: boolean;
  defaultView: "month" | "year" | "decade";
  validator?: AppixWidgetValidator;
  withTime?: boolean;
  boxed?: boolean;
  value: any;
  id?: string;
  availableGranularity?: AppixWidget.Granularity[];
  handleToggleCalendar: (e?: any) => void;
  name?: string;
}

export const CalendarSelector: React.FC<CalendarSelectorPorps> = (props) => {
  const {
    value,
    name = "",
    chosenDate,
    handleSelectDate,
    handleStartChange,
    selectRange,
    withTime,
    handleEndChange,
    id,
    getNavigationLabel,
    defaultView,
    handleToggleCalendar,
    availableGranularity,
    onChangeHandler,
  } = props;
  const [granularity, setGranularity] = useState(
    value?.granularity || availableGranularity?.[0] || "DAY",
  );
  const [isDomLoaded, setIsDomLoaded] = useState(false);

  useEffect(() => {
    setIsDomLoaded(true);
  }, []);

  const ref = useRef<HTMLInputElement>(null);

  const granularityOptions = useMemo(
    () =>
      availableGranularity?.map((item) => ({
        label: Granularities[item],
        value: item,
      })),
    [availableGranularity],
  );
  const getCalendarView = useCallback(() => {
    switch (granularity) {
      case "DAY":
      case "WEEK":
        return "month";
      case "MONTH":
        return "year";
      case "YEAR":
        return "decade";

      default:
        break;
    }
  }, [granularity]);

  const handleChange = useCallback(
    (e) => {
      onChangeHandler(e, granularity);
    },
    [granularity, onChangeHandler],
  );

  const handleChangeGranularity = useCallback(
    (e) => {
      const value = e.target.value;
      setGranularity(value);
      onChangeHandler(
        [
          chosenDate?.startDate ? moment(chosenDate?.startDate).toDate() : null,
          chosenDate?.endDate ? moment(chosenDate?.endDate).toDate() : null,
        ],
        value,
      );
    },
    [chosenDate?.endDate, chosenDate?.startDate, onChangeHandler],
  );

  const handleCancel = useCallback(() => {
    if (typeof value === "string") {
      onChangeHandler(value, availableGranularity?.[0] || null);
    } else {
      onChangeHandler(
        [
          value?.startDate ? moment(value?.startDate).toDate() : null,
          value?.endDate ? moment(value?.endDate).toDate() : null,
        ],
        value?.granularity || availableGranularity?.[0] || null,
      );
    }
    handleToggleCalendar();
  }, [availableGranularity, handleToggleCalendar, onChangeHandler, value]);

  return (
    <div
      className={clsx(" bg-white appix-datepicker__wrapper ", {
        "granularity-week": granularity === "WEEK",
      })}
      style={{
        zIndex: 999999,
        minWidth: "100%",
        left: 0,
        top: "110%",
      }}
    >
      <div id={id + name + "CALENDAR"} className="appix-datepicker__content">
        <div className="d-flex justify-content-center">
          <Calendar
            className={`datepicker view-${defaultView} `}
            onChange={handleChange}
            inputRef={ref}
            locale={"ru-RU"}
            // eslint-disable-next-line react/jsx-no-bind
            formatMonth={(locale, date) => {
              return moment(date).locale("ru").format("MMM").replace(".", "");
            }}
            value={
              value && chosenDate.startDate && chosenDate.endDate
                ? [
                    moment(chosenDate.startDate).startOf("day").toDate(),
                    moment(chosenDate.endDate).startOf("day").toDate(),
                  ]
                : undefined
            }
            navigationLabel={getNavigationLabel}
            selectRange={selectRange}
            showDoubleView={false}
            showNeighboringMonth={false}
            defaultView={getCalendarView()}
            maxDetail={getCalendarView()}
            minDetail={getCalendarView()}
            next2Label={<RemixIcon icon="arrow-right" iconSize="1x" />}
            prev2Label={<RemixIcon icon="arrow-left" iconSize="1x" />}
          />
          {isDomLoaded &&
            granularityOptions?.length &&
            granularityOptions?.length > 1 &&
            createPortal(
              <div className="d-flex justify-content-end granularity-picker">
                <InlineSelectInput
                  id={id + name + "GRANULARITY"}
                  options={granularityOptions}
                  // placeholder="choose granularity"
                  value={granularity}
                  onChange={handleChangeGranularity}
                />
              </div>,
              ref.current!,
            )}
        </div>
        <div>
          {withTime && selectRange && (
            <div className="appix-datepicker__timePicker-wrapper d-block">
              <div className="d-flex align-items-center justify-content-between mb-3">
                {chosenDate?.startDate && (
                  <span>{moment(chosenDate?.startDate).format("DD.MM.YYYY")}</span>
                )}
                {!chosenDate?.startDate && (
                  <span className="appix-datepicker__empty-date">ДД.ММ.ГГ</span>
                )}
                <AppixTimePicker
                  onChange={handleStartChange}
                  boxed={false}
                  value={chosenDate?.startDate}
                  className={clsx({ disabled: !chosenDate?.startDate })}
                  inputId={id + name + "START"}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                {chosenDate?.endDate && (
                  <span>{moment(chosenDate?.endDate).format("DD.MM.YYYY")}</span>
                )}
                {!chosenDate?.endDate && (
                  <span className="appix-datepicker__empty-date">ДД.ММ.ГГ</span>
                )}
                <AppixTimePicker
                  onChange={handleEndChange}
                  boxed={false}
                  value={chosenDate?.endDate}
                  className={clsx({ disabled: !chosenDate?.endDate })}
                  inputId={id + name + "END"}
                />
              </div>
            </div>
          )}
          {withTime && !selectRange && (
            <div className="appix-datepicker__timePicker-wrapper">
              Время
              <AppixTimePicker
                onChange={handleStartChange}
                boxed={false}
                value={chosenDate?.startDate}
                className={clsx({ disabled: !chosenDate?.startDate })}
                inputId={id + name}
              />
            </div>
          )}
          <div className="appix-datepicker__actionButtons-wrapper d-flex justify-content-end">
            <AppixButton
              onClick={handleCancel}
              title="Отменить"
              size="md"
              variant="link"
              className="appix-datepicker__cancel-btn"
            />
            <AppixButton
              onClick={handleSelectDate}
              title="Применить"
              variant="link"
              size="md"
              disabled={
                (value &&
                  moment(chosenDate.startDate).isSame(moment(value.startDate)) &&
                  moment(chosenDate.endDate).isSame(moment(value.endDate)) &&
                  granularity === value.granularity) ||
                !chosenDate?.startDate ||
                !chosenDate?.endDate
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
