import { useMemo } from "react";
import clsx from "clsx";

import Spinner from "components/Spinner/Spinner";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import { AppixTableAction, AppixTableProps, AppixTableBaseRow } from "./types";

import { AppixButton } from "../AppixButton";
import { AppixNoContent } from "ui";

function AppixTable<ROW extends AppixTableBaseRow = AppixTableBaseRow>(
  props: AppixTableProps<ROW>,
) {
  const {
    id,
    caption,
    striped,
    size,
    isLoading,
    globalActions,
    isClearControl,
    isSelectAllControl,
    isSubmitControl,
    onClear,
    onSelectAll,
    onSubmit,
    getButtonColor,
    getButtonVariant,
  } = props;

  const globalActionsArray = useMemo<AppixTableAction[]>(() => {
    if (globalActions) {
      return Object.keys(globalActions).map((actionKey) => ({
        actionKey,
        ...globalActions[actionKey],
      }));
    }
    return [];
  }, [globalActions]);

  return (
    <>
      {(globalActionsArray.length > 0 || caption) && (
        <div className="appix-table__header">
          <h2>{caption}</h2>
          {globalActionsArray.length > 0 ? (
            <div className="appix-table__global-actions">
              {globalActionsArray.map(({ actionKey, cb, ico, title }, index) => (
                <div key={index} className="appix-table__global-actions-item">
                  <AppixButton
                    id={id + actionKey?.toUpperCase()}
                    key={actionKey}
                    size="sm"
                    fullWidth={false}
                    variant={(actionKey && getButtonVariant?.(actionKey)) || "default"}
                    color={(actionKey && getButtonColor?.(actionKey)) || "primary"}
                    onClick={cb}
                    beforeIconCode={ico}
                  >
                    {title}
                  </AppixButton>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
      <div className="table-responsive-container">
        <div className="table-responsive">
          <table
            id={id}
            className={clsx("table table-hover ", {
              "table-striped": striped,
              [`table-${size}`]: !!size,
            })}
          >
            <TableHead {...props} />
            {isLoading && (
              <tbody>
                <tr>
                  <td className="position-relative" colSpan={props.columns.length + 1}>
                    <Spinner />
                  </td>
                </tr>
              </tbody>
            )}

            {!isLoading ? (
              props.data?.length ? (
                <TableBody<ROW> {...props} />
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={props.columns.length + 1}>
                      <AppixNoContent />
                    </td>
                  </tr>
                </tbody>
              )
            ) : null}
          </table>
        </div>
      </div>
      <div className="appix-table__controls">
        {isClearControl && (
          <AppixButton onClick={onClear} title="Сбросить выбор" color="primary"></AppixButton>
        )}
        {isSelectAllControl && (
          <AppixButton
            onClick={onSelectAll}
            title="Выбрать все"
            className="ms-1"
            color="primary"
          ></AppixButton>
        )}
        {isSubmitControl && (
          <AppixButton
            onClick={onSubmit}
            title="Применить"
            color="primary"
            className="ms-1"
          ></AppixButton>
        )}
      </div>
    </>
  );
}

AppixTable.defaultProps = {
  data: [],
  selectedRows: [],
  size: "sm",
};

export default AppixTable;
