import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import {
  MonitorWidgetExpandableSeriesResponse,
  MonitorWidgetExpandableSeriesValueFieldData,
} from "@appix/core";

import RemixIcon from "components/RemixIcon";
import Spinner from "components/Spinner/Spinner";

import { getWidgetData } from "utils/getWidgetData";

import { TableMonitorWidgetExpandableSeriesProps } from "./TableMonitorWidgetExpandableSeries";
import ValueField from "./ValueField";
import { getColor } from "utils";
import { AppixTooltip } from "ui";

interface DataRowProps {
  id: string;
  data: MonitorWidgetExpandableSeriesResponse.Row;
  actions: MonitorWidgetExpandableSeriesResponse.Actions;
  header: MonitorWidgetExpandableSeriesResponse.Header;
  level?: number;
  allProps: TableMonitorWidgetExpandableSeriesProps;
  nextItem: MonitorWidgetExpandableSeriesResponse.Row;
  selectedValueFields?: string[];
  ctx?: Record<string, any>;
  monitor?: any;
  handleCustomAction(action: AppixWidget.CustomAction, data: any): void;
  onDoubleClick(data: MonitorWidgetExpandableSeriesValueFieldData): void;
}

const DataRow: React.FC<DataRowProps> = ({
  id,
  data,
  actions,
  header,
  level = 0,
  allProps,
  nextItem,
  selectedValueFields,
  ctx,
  monitor,
  handleCustomAction,
  onDoubleClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [expandedData, setExpandedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRowClick = useCallback<
    (row: MonitorWidgetExpandableSeriesResponse.Row) => () => void
  >(
    (row) => async () => {
      if (!expandedData.length) {
        try {
          setIsLoading(true);
          const someData = await getWidgetData(allProps, row.meta.path, ctx);
          setExpandedData(someData.data);
          setExpanded(true);
        } catch {}

        setIsLoading(false);
      } else {
        setExpanded(true);
      }
    },
    [allProps, ctx, expandedData.length],
  );

  const toggleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  useEffect(() => {
    setExpanded(false);
    setExpandedData([]);
  }, [data]);

  return (
    <>
      <tr
        style={{
          backgroundColor: getColor(data.formatting.color),
        }}
      >
        {data.metrics?.map((metric, index) => {
          const isSameMetric = metric.name === nextItem?.metrics[index].name;
          return (
            <td
              key={index}
              className={clsx({
                "border-0": isSameMetric,
              })}
              colSpan={data.values ? 1 : 999999}
            >
              <div
                className={clsx("bg-transparent py-2 pe-1 d-flex align-items-center", {
                  "justify-content-center": !data.values,
                  [`text-${metric.formatting.color?.toLowerCase()}`]: metric?.formatting?.color,
                })}
                style={{
                  paddingLeft: `${5 + 20 * level}px`,
                }}
              >
                {isSameMetric ? null : (
                  <>
                    {index === 0 && data.meta?.hasNext && (
                      <RemixIcon
                        icon={expanded ? "arrow-up-s" : "arrow-down-s"}
                        iconSize={"xl"}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={
                          data.meta?.hasNext && !expanded
                            ? handleRowClick(data)
                            : data.meta?.hasNext && expanded
                            ? toggleExpand
                            : undefined
                        }
                      />
                    )}
                    <AppixTooltip label={metric.formatting.tooltip ?? ""}>
                      {metric.name}
                    </AppixTooltip>
                    {metric.formatting.icons &&
                      metric.formatting.icons.map((icon, index) => (
                        <RemixIcon key={index} icon={icon} iconSize="xl" />
                      ))}
                  </>
                )}
              </div>
            </td>
          );
        })}
        {selectedValueFields && selectedValueFields?.length > 1 && (
          <td>
            {selectedValueFields?.map((field, index) => (
              <div key={index}>{field}</div>
            ))}
          </td>
        )}
        {data.values &&
          data.values?.map((item, index) => (
            <td key={index} className="text-center">
              <ValueField
                id={id}
                data={data}
                valueFields={item.valueFields}
                selectedValueFields={selectedValueFields}
                monitor={monitor}
                actions={actions}
                handleCustomAction={handleCustomAction}
                onDoubleClick={onDoubleClick}
              />
            </td>
          ))}
      </tr>
      {isLoading && (
        <tr>
          <th colSpan={header.metrics.length + header.values.length}>
            <Spinner />
          </th>
        </tr>
      )}
      {expanded &&
        expandedData.map((dataItem, index) => (
          <DataRow
            monitor={monitor}
            key={index}
            id={`${id}${index}`}
            data={dataItem}
            actions={actions}
            header={header}
            level={level + 1}
            allProps={allProps}
            nextItem={expandedData[index + 1]}
            selectedValueFields={selectedValueFields}
            ctx={ctx}
            handleCustomAction={handleCustomAction}
            onDoubleClick={onDoubleClick}
          />
        ))}
    </>
  );
};

export default DataRow;
