import Appix from "Appix";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FallbackProps } from "react-error-boundary";
import { collapseSlashes } from "utils";

const FallbackComponent: FC<FallbackProps> = (props) => {
  const { resetErrorBoundary, error } = props;

  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (url?: string) => (e) => {
      e.preventDefault();
      if (url) {
        navigate(collapseSlashes(url));
      } else {
        navigate(-1);
      }

      // смещаем очередь чтобы успел выполниться navigate
      setTimeout(() => {
        resetErrorBoundary();
      }, 0);
    },
    [navigate, resetErrorBoundary],
  );

  const pageConfig = Appix.getPageConfig();

  return (
    <div className="container text-center">
      <p className="my-5">
        <img src="/logo.svg" alt="Appix" />
        <span className="appix-sidebar__logo__sitename">Appix</span>
      </p>
      <h1>Ошибка!</h1>
      <p>
        На сайте произошла внутренная ошибка: {error.message} <br />
        Попробуйте{" "}
        <a href="/" onClick={handleNavigate()}>
          вернуться назад
        </a>{" "}
        или перейдите на{" "}
        <a
          href={pageConfig?.layout?.routerPath || "/"}
          onClick={handleNavigate(pageConfig?.layout?.routerPath || "/")}
        >
          главную страницу
        </a>
        .
      </p>
    </div>
  );
};

export default FallbackComponent;
