import { FC, useMemo, useCallback } from "react";
import { CardsWidgetClass, INITIAL_CARDS_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";
import AppixCards from "ui/AppixCards/AppixCards";
import { AppixButton, AppixButtonColor } from "ui";
import TableFormModal from "widgets/TableWidget/TableFormModal";
import { Pagination } from "components";
import { AppixTableAction } from "ui/AppixTable/types";

const CardsWidget: FC<AppixWidget.Table> = (props) => {
  const cardsWidget = useWidget<AppixWidget.Table>(
    props,
    INITIAL_CARDS_WIDGET_STATE,
    CardsWidgetClass,
  );
  const { data, columns, id, formData, formMode, pageNum, table, total, rowActions } =
    cardsWidget.state;

  const { caption } = table;

  const primaryKey = cardsWidget.primaryKey || "id";

  const { globalActions } = cardsWidget;

  const globalActionsArray = useMemo<AppixTableAction[]>(() => {
    if (globalActions) {
      return Object.keys(globalActions).map((actionKey) => ({
        actionKey,
        ...globalActions[actionKey],
      }));
    }
    return [];
  }, [globalActions]);

  const getButtonColor = (action: string) => {
    if (action === "DELETE") {
      return AppixButtonColor.danger;
    } else if (action === "ADD") {
      return AppixButtonColor.primary;
    }
    return AppixButtonColor.dark;
  };

  const handleCloseFormModal = useCallback(() => {
    cardsWidget.setState({
      formData: null,
    });
  }, [cardsWidget]);

  const handleChangePage = useCallback<(nextPage: number, nextPageSize: number) => void>(
    (nextPage, nextPageSize) => {
      cardsWidget.setPaginationState({ pageNum: nextPage, pageSize: nextPageSize });
    },
    [cardsWidget],
  );

  const coloringRules = cardsWidget.state.table?.rowColoring?.[0]?.coloringRules;

  return (
    <div className="h-100 overflow-auto">
      <CurrentWidgetInfo widget={cardsWidget} />

      {(globalActionsArray.length > 0 || caption) && (
        <div className="appix-table__header">
          <h3>{caption}</h3>
          {globalActionsArray.length > 0 ? (
            <div className="appix-table__global-actions">
              {globalActionsArray.map(({ actionKey, cb, ico, title }, index) => (
                <div key={index} className="appix-table__global-actions-item">
                  <AppixButton
                    id={id + actionKey?.toUpperCase()}
                    key={actionKey}
                    size="sm"
                    fullWidth={false}
                    color={(actionKey && getButtonColor?.(actionKey)) || "primary"}
                    onClick={cb}
                    beforeIconCode={ico}
                  >
                    {title}
                  </AppixButton>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
      <AppixCards
        data={data}
        columns={columns}
        coloringRules={coloringRules}
        rowActions={rowActions}
        primaryKey={primaryKey}
        id={id}
      />
      {formData && (
        <TableFormModal
          id={id}
          form={cardsWidget.form || undefined}
          formBroadcast={cardsWidget.formBroadcast}
          formMode={formMode}
          onClose={handleCloseFormModal}
          isShown={!!formData}
        />
      )}
      {table?.pagination && (
        <Pagination
          id={id}
          page={pageNum}
          limit={table?.defaultPageSize || 10}
          total={total}
          onChangePage={handleChangePage}
          onlyPages={true}
        />
      )}
    </div>
  );
};

export default CardsWidget;
