import React from "react";
import clsx from "clsx";

interface MultiHeaderRowProps {
  data: Record<string, any>;
  index: number;
}

const MultiHeaderRow: React.FC<MultiHeaderRowProps> = ({ data, index }) => {
  return (
    <tr className={clsx("table__multi-header-tr", { "table__multi-header-tr_first": index === 0 })}>
      {data.map((item, index) => (
        <th
          colSpan={item.childrenCount ?? item.childrenCount}
          key={index}
          scope="col"
          className="table__multi-header-th"
        >
          {item.label}
        </th>
      ))}
    </tr>
  );
};

export default MultiHeaderRow;
