import React, { useCallback, useState } from "react";

import {
  MonitorWidgetExpandableSeriesResponse,
  AppixMonitorResponse,
  MonitorWidgetExpandableSeriesValueFieldData,
} from "@appix/core";
import { AppixDropdownMenu, AppixPopover, AppixTooltip } from "ui";
import RemixIcon from "components/RemixIcon";
import clsx from "clsx";

interface ValueFieldProps {
  id: string;
  data: MonitorWidgetExpandableSeriesResponse.Row;
  valueFields: Record<string, MonitorWidgetExpandableSeriesResponse.RowValueField>;
  selectedValueFields?: string[] | undefined;
  monitor: AppixMonitorResponse.Result;
  actions: MonitorWidgetExpandableSeriesResponse.Actions;
  handleCustomAction(action: AppixWidget.CustomAction, data: any): void;
  onDoubleClick(data: MonitorWidgetExpandableSeriesValueFieldData): void;
}

const ValueField: React.FC<ValueFieldProps> = ({
  id,
  valueFields,
  data,
  selectedValueFields,
  monitor,
  actions,
  handleCustomAction,
  onDoubleClick,
}) => {
  const [valueField, setValueField] = useState("");

  const getCustomEventsOptions = useCallback(
    (valueActions: string[] = []) => {
      return Object.keys(actions)
        .filter((action) => valueActions.includes(action))
        .map((actionKey) => ({
          title: actions[actionKey].text,
          value: actionKey,
          icon: actions[actionKey].ico || undefined,
          color: actions[actionKey].color,
          onClick: () => {
            window.document.body.click();
            handleCustomAction(actions[actionKey], {
              valueField,
              monitor,
              cell: valueFields[valueField],
              ...data,
            });
          },
        }));
    },
    [monitor, actions, data, valueField, valueFields, handleCustomAction],
  );

  const getIcons = useCallback(
    (field) => {
      if (!valueFields[field]) {
        return [];
      }
      return valueFields[field].formatting.icons ?? [];
    },
    [valueFields],
  );

  const getField = useCallback(
    (field, actions) => (
      <div
        style={{
          cursor: actions.length > 0 ? "pointer" : "text",
        }}
        className={clsx({
          [`text-${valueFields[field]?.formatting.color?.toLowerCase()}`]:
            valueFields[field]?.formatting?.color,
        })}
        // eslint-disable-next-line
        onClick={() => {
          setValueField(field);
        }}
      >
        {valueFields[field]?.value}
        {getIcons(field).map((icon, index) => (
          <RemixIcon key={`icon${id}${icon}${index}`} icon={icon} iconSize="xl" />
        ))}
      </div>
    ),
    [getIcons, id, valueFields],
  );

  const handleOnDoubleClick = useCallback(() => {
    if (onDoubleClick) {
      const valueFieldData: MonitorWidgetExpandableSeriesValueFieldData = {
        valueField,
        monitor,
        cell: valueFields[valueField],
        meta: data.meta,
      };
      onDoubleClick(valueFieldData);
    }
  }, [onDoubleClick, valueField, valueFields, monitor, data]);

  return (
    <>
      {selectedValueFields?.map((field, index) => {
        const actions = getCustomEventsOptions(valueFields[field]?.actions);
        return (
          <AppixTooltip key={index} label={valueFields[field]?.formatting.tooltip ?? ""}>
            <div onDoubleClick={handleOnDoubleClick}>
              {actions.length > 0 && (
                <AppixPopover
                  id={id + index + "POPOVER"}
                  trigger="click"
                  placement="bottom-end"
                  PopoverContent={<AppixDropdownMenu id={id + index} options={actions} />}
                  withArrow
                >
                  <div>{getField(field, actions)}</div>
                </AppixPopover>
              )}
              {actions.length === 0 && getField(field, actions)}
            </div>
          </AppixTooltip>
        );
      })}
    </>
  );
};

export default ValueField;
