import { FormControl } from "components";

const FormContent = ({ columns, rows, fields, onChange, data, formErrors, ...props }) => {
  return (
    <>
      {fields?.map((item) => {
        const isDate = item.dataType === "DATE";
        const isDateTime = item.dataType === "DATETIME";
        const isInterval = ["DATETIME_INTERVAL", "DATE_INTERVAL"].includes(item.dataType);
        return (
          <FormControl
            {...item}
            {...props}
            key={item.name}
            errorText={formErrors?.[item.name]}
            value={data?.[item.name]}
            disabled={item.readOnly}
            onChange={onChange(item.name, item.validator, isDate, isDateTime, isInterval)}
            onSearch={props.handleSearch}
            data={data}
          />
        );
      })}
    </>
  );
};

export default FormContent;
