import React, { useMemo } from "react";
import clsx from "clsx";
import { TitleWidgetClass, INITIAL_TITLE_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const TitleWidget: React.FC<AppixWidget.Title> = (props) => {
  const titleWidget = useWidget<AppixWidget.Title>(
    props,
    INITIAL_TITLE_WIDGET_STATE,
    TitleWidgetClass,
    [props.text, props.level],
  );

  const level = useMemo(() => {
    if (titleWidget.state.level > 6) return 6;
    return titleWidget.state.level;
  }, [titleWidget.state.level]);

  return (
    <div className="position-relative">
      <CurrentWidgetInfo widget={titleWidget} />
      {titleWidget.state.isLoading && <Spinner />}
      {!titleWidget.state.isLoading &&
        React.createElement(`h${level}`, {
          children: titleWidget.state.text,
          id: props.id,
          className: clsx("ps-0 m-0", {
            "d-none": !titleWidget.state.displayed,
            "d-block": titleWidget.state.displayed,
          }),
        })}
    </div>
  );
};

export default TitleWidget;
