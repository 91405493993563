import { FC } from "react";

import RemixIcon from "components/RemixIcon";

import { AppixDropdownMenuProps } from "./types";
import clsx from "clsx";

const AppixDropdownMenu: FC<AppixDropdownMenuProps> = ({ id, options }) => {
  return (
    <>
      {options.map((item) => (
        <button
          key={item.value}
          id={`${id}${item.value}`}
          type="button"
          className={clsx("appix-cell appix-cell_hovered appix-cell_btn", {
            [`text-${item.color?.toLowerCase()}`]: item.color,
          })}
          // eslint-disable-next-line
          onClick={(e) => item.onClick?.(item, e)}
        >
          {item.icon ? (
            <div className="appix-cell__icon">
              <RemixIcon icon={item.icon} />
            </div>
          ) : null}
          <div className="appix-cell__content">
            <div className="appix-cell__title">{item.title}</div>
            {item.description && <div className="appix-cell__description">{item.description}</div>}
          </div>
        </button>
      ))}
    </>
  );
};

export default AppixDropdownMenu;
