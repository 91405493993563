import getColor from "utils/getColor";
import { AxisFormatter, transformTextValue } from "ui";
import { Multigraph } from "@appix/core";
import { defaultColors } from ".";
import coloralpha from "color-alpha";

export const optionHandler = (preData, preMeta) => {
  try {
    const data: Multigraph.Data[] = !Array.isArray(preData) ? [preData] : preData;
    const metas: Multigraph.Monitor[] = !Array.isArray(preMeta) ? [preMeta] : preMeta;

    const graphNames: Array<string> = [];
    const graphDescs: Array<string> = [];
    const visualMaps: Array<Record<string, any>> = [];
    const seriesList: Array<Record<string, any>> = [];
    const xAxisPoints: Record<string, boolean> = {};

    const listOfYAxis: Array<Record<string, any>> = [];
    let axisNames: string[] = ["xAxis", "yAxis"];
    let showAxes = true; // для возможности скрытия осей для пай-чарта
    let globalSeriesIndex = 0;
    let hideTooltip = false;
    const breakDownStackingCounts = {};

    let exportImageButton: boolean = false;
    let showCustomLegend: boolean = false;
    const customLegendData: Record<string, any> = [];
    let colorIndex = 0; // ECharts пропускает базовый цвет, если для серии задан кастомный. Поэтому просто использовать индекс серии неправильно.
    let needToUseDefaultGauge = false;

    // сбор всех уникальных значений X
    data.forEach((reqResult) => {
      reqResult.data.forEach((seriesItem) => {
        seriesItem.values.forEach((item) => {
          xAxisPoints[item.key] = true;
        });
      });
    });

    const uniqXMarks = Object.keys(xAxisPoints);

    // основной цикл обхода всех запросов и серий в них.

    data.forEach((reqResult, reqIndex) => {
      const monitorData = metas[reqIndex].monitorData;

      const chartParams = reqResult.chartParams || monitorData?.chartParams || null;

      if (monitorData?.name) graphNames.push(monitorData.name);
      if (monitorData?.description) graphDescs.push(monitorData.description);

      if (chartParams?.export) exportImageButton = true;
      if (chartParams?.legend) showCustomLegend = true;

      const gaugeRelatedSeries = {}; // тут ключи по названию гауджей и внутри массивы с айдишниками подходяхих серий.

      reqResult.data.forEach((seriesItem, seriesIndex) => {
        const series = {
          ...seriesItem,
          chartParams, // - это свойство не может тут лежать, ибо содержит комплексные данные. Их нужно вынимать и класть в развернутом виде.

          globalSeriesIndex: globalSeriesIndex++,

          params: chartParams?.seriesParams?.[seriesItem.series] || null,

          visualizationType:
            chartParams?.seriesParams[seriesItem.series]?.visualizationType ||
            monitorData?.visualizationType,
        };

        if (series.params?.gaugeName) {
          if (!gaugeRelatedSeries[series.params?.gaugeName])
            gaugeRelatedSeries[series.params?.gaugeName] = [series.globalSeriesIndex];
          else gaugeRelatedSeries[series.params?.gaugeName].push(series.globalSeriesIndex);
        } else {
          needToUseDefaultGauge = true;
        }

        customLegendData.push({
          name: series.series,
          type: series.visualizationType === "SIMPLE_LINES" ? "circle" : "square",
          color: series.params?.color || defaultColors[colorIndex++] || "#000",
        });

        let graphType: any = {};
        switch (series.visualizationType) {
          case "SIMPLE_LINES":
            graphType = {
              type: "line",
              smooth: false,
              symbol: "emptyCircle",
              symbolSize: 6,
              label: {
                show: false,
              },
              itemStyle: {
                color: getColor(series.params?.color),
              },
            };
            break;
          case "SIMPLE_PIE":
            showAxes = false;
            hideTooltip = true;
            graphType = {
              type: "pie",
              radius: ["30%", "50%"],
              avoidLabelOverlap: false,
              labelLayout: function (params) {
                const points = params.labelLinePoints;
                points[2][0] =
                  params.align === "left"
                    ? params.labelRect.x
                    : params.labelRect.x + params.labelRect.width;
                return {
                  labelLinePoints: points,
                };
              },
              label: {
                alignTo: "edge",
                formatter: "{name|{b}}\n{value|{c}}",
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                  value: {
                    fontSize: 10,
                    color: "#999",
                  },
                },
              },
              itemStyle: {
                borderRadius: 0,
                borderColor: "#fff",
                borderWidth: 3,
              },
              center: ["50%", "50%"],
              emphasis: false,
            };
            break;
          case "SIMPLE_HISTOGRAM":
            graphType = {
              type: "bar",
              stack: series.params?.stackTag,
              barMaxWidth: 24,
              itemStyle: {
                borderRadius: [4, 4, 0, 0],
                color: getColor(series.params?.color),
              },
            };
            break;
          case "HORIZONTAL_HISTOGRAM":
            axisNames = ["yAxis", "xAxis"];
            graphType = {
              type: "bar",
              stack: series.params?.stackTag,
              barMaxWidth: 20,
              itemStyle: {
                borderRadius: [0, 4, 4, 0],
                color: getColor(series.params?.color),
              },
            };
            break;
        }

        // Вот тут разные варианты обработки в зависимости от разных типов графиков
        // Возможно, переделать свитч по типам на if/else, внутри которых помимо graphType обрабатывать еще и массив _data...

        const seriesValuesAsAnObj: any = series.values.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {});

        let currentStackingMark: string | number = "";
        const _data = uniqXMarks.map((xMark) => {
          if (currentStackingMark === "" && seriesValuesAsAnObj[xMark]) currentStackingMark = xMark;
          return { name: xMark, value: seriesValuesAsAnObj[xMark] ?? null };
        });

        if (breakDownStackingCounts[currentStackingMark] !== undefined) {
          breakDownStackingCounts[currentStackingMark]++;
        } else {
          breakDownStackingCounts[currentStackingMark] = 0;
        }

        seriesList.push({
          ...graphType,
          stack:
            graphType.stack ||
            (currentStackingMark ? `stack${breakDownStackingCounts[currentStackingMark]}` : null),
          name: series.series,
          data: _data,
        });
      });

      // making additional Y axis
      Object.keys(chartParams?.gauges || {}).forEach((gaugeName) => {
        listOfYAxis.push({
          show: showAxes,
          type: "value",
          position: "right",
          offset: listOfYAxis.length * 60,
          min: chartParams.gauges[gaugeName].minValue,
          max: chartParams.gauges[gaugeName].maxValue,
          splitLine: {
            show: true,
            lineStyle: {
              type: [3, 3],
            },
          },
          axisLine: {
            show: false,
          },

          name: gaugeName,
          // "z": 2
        });
      });

      Object.keys(gaugeRelatedSeries).forEach((gaugeName) => {
        const gaugeData = chartParams?.gauges?.[gaugeName];

        if (!gaugeData) throw new Error(`Отсутствует описание гауджи '${gaugeName}'.`);

        if (gaugeData.coloringMethod === "VALUES") {
          visualMaps.push({
            show: false,
            type: "piecewise",
            seriesIndex: gaugeRelatedSeries[gaugeName],
            pieces: gaugeData?.coloringRules?.map((rule) => {
              const res = {
                color: getColor(rule.color),
                gt: rule.min ?? 0,
                lte: rule.max ?? 99999999999999, // требует указать что-то. Инфинити не принимает.
              };
              return res;
            }),
          });
        } else {
          gaugeData?.coloringRules?.forEach((rule) => {
            const res = {
              type: "line",
              markArea: {
                itemStyle: { color: coloralpha(getColor(rule.color), 0.1) },
                data: [
                  [
                    { xAxis: -Infinity, yAxis: rule.min ?? 0 },
                    { xAxis: Infinity, yAxis: rule.max ?? 99999999999999 },
                  ],
                ],
              },
            };
            seriesList.push(res);
          });
        }
      });
    });

    if (needToUseDefaultGauge || listOfYAxis.length === 0) {
      listOfYAxis.unshift({
        show: showAxes,
        type: "value",
        position: "left",
        alignTicks: true,
        splitLine: {
          show: true,
          lineStyle: {
            type: [3, 3],
          },
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value) => AxisFormatter(value),
        },
      });
    } else {
      listOfYAxis[0].position = "left";
    }

    const finalName = graphNames.join(" / ");
    const finalDesctiption = graphDescs.join(" / ");

    // Все данные собраны. Далее компановка финального объекта option.

    const newOption = {
      animation: false,
      toolbox: exportImageButton
        ? {
            feature: {
              saveAsImage: {
                show: true,
                iconStyle: { color: "#4B5563" },
                icon: `path://M8.66671 6.66667H12L8.00004 10.6667L4.00004 6.66667H7.33337V2H8.66671V6.66667ZM2.66671 12.6667H13.3334V8H14.6667V13.3333C14.6667 13.5101 14.5965 13.6797 14.4714 13.8047C14.3464 13.9298 14.1769 14 14 14H2.00004C1.82323 14 1.65366 13.9298 1.52864 13.8047C1.40361 13.6797 1.33337 13.5101 1.33337 13.3333V8H2.66671V12.6667Z`,
              }, // кнопка для скачивания растра
            },
          }
        : false,

      tooltip: hideTooltip
        ? true
        : {
            trigger: "axis",
            axisPointer: { type: "shadow" },
            formatter: (itemList) => {
              const contentArray: string[] = [];
              contentArray.push(`<div class="c-charts-tooltip">
          <div class="c-charts-tooltip-title">${transformTextValue(
            itemList[0].axisValueLabel,
          )}</div>
          <div class="c-charts-tooltip-items">`);
              itemList.forEach((item: any) => {
                if (item.value !== undefined && item.value !== null)
                  contentArray.push(`<div class="c-charts-tooltip-listitem">
            <div
              style="--color: ${item.color}" 
              class="${item.seriesType === "bar" ? "square" : "circle"}">
            </div>
            <div>${item.seriesName}</div>
            <div>${item.value?.toLocaleString()}</div>
          </div>`);
              });
              contentArray.push("</div></div>");
              return contentArray.join("");
            },
          },

      legend: { show: false },
      __customLegendData: showCustomLegend ? customLegendData : false,

      title: {
        text: finalName,
        textStyle: {
          color: "#1F2937",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 16,
        },
        subtext: finalDesctiption,
        subtextStyle: {
          color: "#6B7280",
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 12,
        },
        show: finalName || finalDesctiption,
      },
      grid: {
        top:
          (finalName && finalDesctiption ? 60 : finalName ? 40 : 20) +
          (!needToUseDefaultGauge ? 20 : 0),
        bottom: 20,
        containsLabel: true,
        left: 60,
        right: (listOfYAxis.length - 1) * 60, // Отступ справа по количеству дополнительных гауджей
      },

      [axisNames[0]]: {
        // xAsix by default
        show: showAxes,
        axisLabel: {
          formatter: (value) => AxisFormatter(value),
          fontSize: 10,
          align: axisNames[0] === "xAxis" ? "center" : "right",
          hideOverlap: "true",
          showMinLabel: uniqXMarks.length < 20,
          showMaxLabel: uniqXMarks.length < 20,
        },
        type: "category",
        data: uniqXMarks,
        splitLine: {
          show: true,
          lineStyle: {
            type: [3, 3],
          },
        },
        axisLine: {
          show: true,
          onZero: false,
          lineStyle: {
            color: "#D1D5DB",
          },
        },

        axisTick: { show: false },
      },

      [axisNames[1]]: [
        // yAsix by default
        ...listOfYAxis,
      ],

      visualMap: visualMaps,

      series: seriesList,
    };

    return newOption;
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
    return {};
  }
};
