import { ITableWidgetActions, ITreeTableWidgetNode } from "@appix/core";
import { AppixDropdownMenu } from "../../ui/AppixDropdownMenu";
import { getColor } from "utils";
import { AppixTreeNodeWithKey } from "ui/AppixTree";
import { AppixButton } from "ui";
import { Dropdown } from "react-bootstrap";

interface TreeLabelProps {
  widgetId: string;
  rowActions: ITableWidgetActions;
  node: AppixTreeNodeWithKey<ITreeTableWidgetNode>;
  label: React.ReactElement;
  depth?: number;
}

function TreeLabelWithActions({ widgetId, rowActions, node, label }: TreeLabelProps) {
  return (
    <>
      {label}

      {rowActions && Object.keys(rowActions).length ? (
        <Dropdown>
          <Dropdown.Toggle as="div" bsPrefix="toggler">
            <AppixButton
              id={`${widgetId}SHOW_ACTIONS${node.value}`}
              className="dropdown-toggle apx-tree__btn-action"
              color="primary"
              size="xs"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="appix-dropdown appix-dropdown__menu">
            <AppixDropdownMenu
              id={`${widgetId}${node.value}`}
              options={Object.keys(rowActions).map((actionKey, index) => ({
                title: rowActions[actionKey].title || "",
                value: actionKey || String(index),
                icon: rowActions[actionKey].ico,
                color: getColor(rowActions[actionKey].color),
                onClick: (_item, e) => {
                  window.document.body.click();
                  e.stopPropagation();
                  rowActions[actionKey].cb(node.raw?.value);
                },
              }))}
            />
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </>
  );
}

export default TreeLabelWithActions;
