import { FC, PropsWithChildren, ReactElement, useCallback } from "react";

import clsx from "clsx";

import RemixIcon from "components/RemixIcon";

import { useNavigate } from "react-router-dom";
import Appix from "Appix";

interface AppixSidebarProps {
  siteName: string;
  sticky?: boolean;
  collapsed?: boolean;
  bottomSlot?: ReactElement;
  onCollapse(collapse: boolean): void;
}

const AppixSidebar: FC<PropsWithChildren<AppixSidebarProps>> = ({
  siteName,
  collapsed,
  sticky,
  bottomSlot,
  onCollapse,
  children,
}) => {
  const onToggleCollapse = useCallback(() => {
    onCollapse(!collapsed);
  }, [collapsed, onCollapse]);

  const navigate = useNavigate();
  const pageConfig = Appix.getPageConfig();

  const handleSiteNameClick = useCallback(() => {
    const homeUrl = pageConfig?.layout?.routerPath;
    homeUrl && navigate(homeUrl);
  }, [navigate, pageConfig?.layout?.routerPath]);

  return (
    <div className={clsx("appix-sidebar", { opened: !collapsed, collapsed })}>
      <div
        className={clsx("appix-sidebar__inner", {
          "appix-sidebar__inner_sticky": sticky,
          "appix-sidebar__inner_collapsed": collapsed,
        })}
      >
        <div className="appix-sidebar__top">
          <div className="appix-sidebar__logo">
            <img className="appix-sidebar__logo__img" src="/logo.svg" alt={siteName} />
            <span className="appix-sidebar__logo__sitename" onClick={handleSiteNameClick}>
              {siteName}
            </span>
          </div>
          <button
            className={clsx("appix-sidebar__toggler", {
              "appix-sidebar__toggler_collapsed": collapsed,
            })}
            onClick={onToggleCollapse}
          >
            <RemixIcon icon="arrow-left-s" />
            <RemixIcon icon="arrow-left-s" style={{ marginLeft: "-0.7em" }} />
          </button>
        </div>

        <div className="appix-sidebar__middle">{children}</div>

        <div className="appix-sidebar__bottom">{bottomSlot}</div>
      </div>
    </div>
  );
};

export default AppixSidebar;
