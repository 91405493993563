import React, { useCallback, useState } from "react";

import CollapsedMenuItem from "./CollapsedMenuItem";
import MenuItem from "./MenuItem";
import { AppixMenuProps } from "./types";

const getSavedExpandedData = (id: string) => {
  try {
    const json = localStorage.getItem(`menu-${id}`);
    return json ? JSON.parse(json) : {};
  } catch {
    return {};
  }
};

export const AppixNavigation: React.FC<AppixMenuProps> = ({
  items,
  onItemClick,
  currentLocation,
  id,
  className,
  isSmall,
  collapsed,
}) => {
  const [expandedItemsData, setExpandedItemsData] = useState<Record<string, boolean>>(
    getSavedExpandedData(id),
  );

  const saveExpandedData = useCallback(
    (data) => {
      try {
        localStorage.setItem(`menu-${id}`, JSON.stringify(data));
      } catch {}
    },
    [id],
  );

  const handleSetExpanded = useCallback(
    (itemId: string, value: boolean) => {
      const nextExpandedData = { ...expandedItemsData };

      for (const key in nextExpandedData) {
        // здесь мы закрываем все остальные
        // кроме родительских
        // и кроме активного пункта
        // (id складывается как сумма всех родительских id)
        if (!itemId.includes(key) && !currentLocation.includes(key)) {
          nextExpandedData[key] = false;
        }
      }

      nextExpandedData[itemId] = value;
      setExpandedItemsData(nextExpandedData);

      // сохраняем только выбранные пункты
      const savedData = { ...nextExpandedData };

      for (const key in savedData) {
        savedData[key] = currentLocation.includes(key);
      }
      saveExpandedData(savedData);
    },
    [currentLocation, expandedItemsData, saveExpandedData],
  );

  const handleItemClick = useCallback<(path: string) => void>(
    (path) => {
      // сворачиваем все пункты меню
      // кроме выбранного или его родителя
      const nextExpandedData = { ...expandedItemsData };

      for (const key in nextExpandedData) {
        if (!path.includes(key)) {
          nextExpandedData[key] = false;
        }
      }
      setExpandedItemsData(nextExpandedData);

      // сохраняем только выбранные пункты
      const savedData = { ...nextExpandedData };

      for (const key in savedData) {
        savedData[key] = path.includes(key);
      }
      saveExpandedData(savedData);

      onItemClick?.(path);
    },
    [expandedItemsData, saveExpandedData, onItemClick],
  );

  return (
    <div id={id} className={`accordion w-100 ${className}`}>
      {items?.map((item, index) =>
        React.createElement(collapsed ? CollapsedMenuItem : MenuItem, {
          key: id + index,
          item,
          index,
          onItemClick: handleItemClick,
          currentLocation,
          id: item.fullpath ?? id + index,
          handleSetExpanded,
          expandedItemsData,
          lastChild: items.length - 1 === index,
          isSmall,
          collapsed,
        }),
      )}
    </div>
  );
};
