import React from "react";

import { AppixValidation } from "../types";
import { AppixBaseInputProps } from "./types";

export interface AppixFormControlProps extends AppixBaseInputProps {
  inputId?: string;
  label?: string;
  validation?: keyof typeof AppixValidation;
  helper?: string;
  isRequired?: boolean;
  children?: JSX.Element;
}

export const AppixFormControl: React.FC<AppixFormControlProps> = ({
  id,
  inputId,
  label,
  validation,
  helper,
  children,
  isRequired,
}) => {
  return (
    <div className="appix-form-item" id={id}>
      {label ? (
        <label htmlFor={inputId} className={`form-label ${isRequired ? "required" : ""}`}>
          {label}
        </label>
      ) : null}

      {children}

      {helper && <small className="appix-form-item__helper">{helper}</small>}
    </div>
  );
};
