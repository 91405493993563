import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { AppixTagsRow, ClickAwayListener } from "ui";
import RemixIcon from "components/RemixIcon";
import clsx from "clsx";
import { AppixSelectProps, IAppixSelectItem } from "./types";
import { AppixValidation } from "../types";
import DropdownContent from "./DropdownContent";
import { ConcatenatedNames } from "./ConcatenatedNames";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

export const AppixSelect: React.FC<AppixSelectProps> = ({
  options,
  value,
  placeholder,
  isSmall,
  validation,
  helper,
  id,
  withBadges,
  withSearch,
  multiSelectValue,
  inputValue,
  isCustom,
  treeData,
  tableData,
  name,
  savedValues,
  disabled,
  clear,
  onChange,
  onMultiselectChange,
  onTreeChange,
  handleInputChange,
  handleKeyDown,
  onInputChange,
  ...props
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [badges, setBadges] = useState<AppixSelectProps["options"]>([]);
  const [selectOptions, setSelectOptions] = useState<AppixSelectProps["options"]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [initialOptionsLoaded, setInitialOptionsLoaded] = useState(false);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const refClose = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    multiSelectValue && setBadges(multiSelectValue);
  }, [multiSelectValue]);

  useEffect(() => {
    !value && setSearchValue("");
  }, [value]);

  useEffect(() => {
    if (options?.length) {
      setInitialOptionsLoaded(true);
    }

    (!initialOptionsLoaded || !treeData || !tableData || !withSearch) && setSelectOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOptionsLoaded, treeData, tableData, options, selectOptions, withSearch]);

  const selectedItem = useMemo<IAppixSelectItem | undefined>(() => {
    if (!treeData && !tableData) {
      // eslint-disable-next-line eqeqeq
      return selectOptions?.find((item) => item.value == value);
    }
    return value as IAppixSelectItem;
  }, [selectOptions, value, treeData, tableData]);

  const handleChange = useCallback(
    (value) => {
      if (!withBadges) {
        onChange && onChange(value);
        setOpened(false);
        setSearchValue(value);
      } else {
        setBadges((prev = []) => {
          const newArray = [...prev];
          // eslint-disable-next-line eqeqeq
          const indexOfItem = newArray.findIndex((item) => item.value == value);
          if (indexOfItem > -1) {
            newArray.splice(indexOfItem, 1);
          } else {
            // eslint-disable-next-line eqeqeq
            const item = selectOptions?.find((item) => item.value == value);
            if (item) newArray.push(item);
          }
          onMultiselectChange && onMultiselectChange(newArray);
          return newArray;
        });
      }
    },
    [onChange, onMultiselectChange, selectOptions, withBadges],
  );

  const handleReset = useCallback(() => {
    if (!withBadges) {
      onChange && onChange("");
      // setSearchValue(value);
    } else {
      setBadges(() => {
        onMultiselectChange && onMultiselectChange([]);
        return [];
      });
    }
  }, [onChange, onMultiselectChange, withBadges]);

  const handleDeleteBadge = useCallback(
    (index: number) => {
      const draftBadges = badges ?? [];
      draftBadges.splice(index, 1);
      setBadges(draftBadges);
      onMultiselectChange && onMultiselectChange(draftBadges);
      // if (options?.length) {
      //   setSelectOptions((prev) => {
      //     return options?.filter(
      //       (option) =>
      //         !draftBadges.find((badge) => badge.value === option.value),
      //     );
      //   });
      // }
    },
    [badges, onMultiselectChange],
  );

  const handleAddBadge = useCallback(
    (value) => {
      const draftBadges = badges ?? [];
      draftBadges.push({
        value,
        label: value,
      });
      setBadges(draftBadges);
      onMultiselectChange && onMultiselectChange(draftBadges);
      setSelectOptions(draftBadges);
    },
    [badges, onMultiselectChange],
  );

  const onSearchChange = useCallback(
    (value) => {
      !opened && setOpened(true);
      !isCustom && setSearchValue(value);
      isCustom && handleInputChange && handleInputChange(value);
      options && onInputChange && onInputChange(value, name);
    },
    [isCustom, onInputChange, opened, handleInputChange, name, options],
  );

  const handleSearch = useCallback(
    (e) => {
      const value = e.target.value;
      !opened && setOpened(true);
      !isCustom && setSearchValue(value);
      isCustom && handleInputChange && handleInputChange(value);
      options && onInputChange && onInputChange(value, name);
    },
    [isCustom, onInputChange, opened, handleInputChange, name, options],
  );

  const handleAddCustomBadge = useCallback(
    (e) => {
      handleKeyDown?.(e);
    },
    [handleKeyDown],
  );

  const getInputValue = useCallback(() => {
    if (isCustom) {
      return inputValue;
    }
    if (withSearch && searchValue) {
      return options?.find((option) => option.value === searchValue)?.label;
    }
    if (withBadges) {
      return "";
    }

    return (
      // eslint-disable-next-line eqeqeq
      savedValues?.find((item) => item.value == value)?.label ||
      selectedItem?.label ||
      selectedItem?.value ||
      ""
    );
  }, [
    inputValue,
    isCustom,
    options,
    savedValues,
    searchValue,
    selectedItem?.label,
    selectedItem?.value,
    value,
    withSearch,
    withBadges,
  ]);

  const handleClickAway = useCallback(() => {
    setOpened(false);
  }, []);

  const toggleOpened = useCallback(
    (e) => {
      // Чтобы не открывать селект при клике на reset
      if (!opened && refClose && refClose.current?.contains(e.target)) {
        return;
      }
      !disabled && setOpened((opened) => !opened);
    },
    [disabled, opened],
  );

  const dropdownWidth = useMemo(() => {
    const container = document.querySelector(`#${id}`) as HTMLElement;
    return container?.offsetWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Dropdown>
        <Dropdown.Toggle as="div" id={id + "toggler"} bsPrefix="toggler">
          <div id={id} ref={inputWrapperRef}>
            {withBadges ? (
              <div
                className={clsx("tag-input__select-container-tags", {
                  "tag-input__tags_empty": !badges?.length,
                  "tag-input__options_empty": !selectOptions?.length,
                })}
              >
                {!options || options?.length === 0 ? (
                  <AppixTagsRow
                    id={id}
                    tagsId={`${id}VIEW`}
                    tags={badges ?? []}
                    disabled={disabled}
                    removable={true}
                    onRemoveTag={handleDeleteBadge}
                    readOnly={!isCustom}
                    onAddTag={handleAddBadge}
                    onSearchChange={onSearchChange}
                    searchValue={searchValue}
                    placeholder={placeholder}
                    withSearch={withSearch}
                  />
                ) : (
                  <ConcatenatedNames onClick={toggleOpened} opened={opened} tags={badges ?? []} />
                )}
                {clear && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 0, hide: 100 }}
                    overlay={<Tooltip id="button-tooltip">Очистить</Tooltip>}
                  >
                    <button
                      ref={refClose}
                      className="input-group-text form-control__btn-clear_select"
                      onClick={handleReset}
                    >
                      <RemixIcon icon="close" iconSize="fw" />
                    </button>
                  </OverlayTrigger>
                )}
              </div>
            ) : (
              <div
                className={clsx("mw-100", {
                  "form-select": !isCustom,
                  "multi-chips-input": isCustom,
                  "form-select-sm": isSmall,
                  "is-valid": validation === AppixValidation.success,
                  "is-warning": validation === AppixValidation.warning,
                  "is-invalid": validation === AppixValidation.error,
                  disabled,
                  opened,
                })}
                // eslint-disable-next-line
                onClick={toggleOpened}
                role={disabled ? undefined : "button"}
              >
                <label className="flex-grow-2 mw-100 d-block">
                  <input
                    autoComplete="off"
                    disabled={disabled}
                    className={clsx("form-select__input", {
                      disabled,
                    })}
                    placeholder={placeholder}
                    role={disabled ? undefined : "button"}
                    value={getInputValue()}
                    readOnly={withSearch ? false : true}
                    id={id + `${withSearch ? "SEARCH" : "VIEW"}`}
                    onChange={handleSearch}
                    onKeyDown={isCustom ? handleAddCustomBadge : undefined}
                    {...props}
                  />
                </label>
                {clear && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 0, hide: 100 }}
                    overlay={<Tooltip id="button-tooltip">Очистить</Tooltip>}
                  >
                    <button
                      ref={refClose}
                      className="input-group-text form-control__btn-clear_select"
                      onClick={handleReset}
                    >
                      <RemixIcon icon="close" iconSize="fw" />
                    </button>
                  </OverlayTrigger>
                )}
                {helper && (
                  <div
                    className={clsx({
                      "valid-feedback": validation === AppixValidation.success,
                      "warning-feedback": validation === AppixValidation.warning,
                      "invalid-feedback": validation === AppixValidation.error,
                    })}
                  >
                    {helper}
                  </div>
                )}
              </div>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ width: dropdownWidth, minWidth: "fit-content", zIndex: 2 }}
          show={opened}
          renderOnMount={true}
          bsPrefix={clsx("appix-dropdown p-0", {
            visible: opened,
            invisible: !opened,
          })}
        >
          <div className={clsx(" p-0 d-block", {})}>
            <DropdownContent
              selectOptions={selectOptions}
              setSearchValue={setSearchValue}
              withSearch={withSearch}
              withBadges={withBadges}
              handleChange={handleChange}
              searchValue={searchValue}
              selectedItem={selectedItem}
              isSmall={isSmall}
              treeData={treeData}
              tableData={tableData}
              id={id}
              onChange={onTreeChange}
              value={value}
              handleSearch={handleSearch}
            />
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </ClickAwayListener>
  );
};
