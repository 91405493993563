import clsx from "clsx";
import React, { FC } from "react";
import { OffcanvasPlacement } from "react-bootstrap/esm/Offcanvas";
import { AppixModalProps } from "./AppixModal";

interface AppixOffcanvasProps extends AppixModalProps {
  placement?: OffcanvasPlacement;
  size?: { w: number; h: number; fitContent: boolean } | null;
  handleCancel: () => void;
}

const AppixOffcanvas: FC<AppixOffcanvasProps> = ({
  isShown,
  onClose,
  children,
  id,
  caption,
  className,
  placement,
  size,
  isClosable,
  handleCancel,
}) => {
  return (
    <>
      <div
        className={`offcanvas offcanvas-${placement} ${isShown ? "show" : ""}`}
        tabIndex={-1}
        id={id}
        style={{
          width: size ? `${size.w}%` : undefined,
          height: size ? `${size.h}%` : undefined,
          top:
            size && (placement === "start" || placement === "end")
              ? `calc((100% - ${size.h}%) / 2)`
              : undefined,
          left:
            size && (placement === "top" || placement === "bottom")
              ? `calc((100% - ${size.w}%) / 2)`
              : undefined,
        }}
      >
        <div className="offcanvas-header">
          <h5 className="modal-title">{caption}</h5>

          <button
            type="button"
            className={clsx("btn-close text-reset modal-close", { invisible: !isClosable })}
            data-bs-dismiss="offcanvas"
            aria-label="Закрыть"
            onClick={handleCancel}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>{children}</div>
        </div>
      </div>
      <div
        onClick={handleCancel}
        className={`modal-backdrop fade ${isShown ? "show d-block" : "d-none"}`}
      ></div>
    </>
  );
};

export default AppixOffcanvas;
