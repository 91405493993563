import { FC, useEffect, useRef } from "react";
import clsx from "clsx";

import { AppixSize } from "../types";

export interface AppixLabelProps
  extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  label?: string;
  size?: keyof typeof AppixSize;
  isUpper?: boolean;
  onOverflow?: (isOverflow: boolean) => void;
}

export const AppixLabel: FC<AppixLabelProps> = ({
  size = "md",
  label,
  isUpper,
  onOverflow,
  children,
  ...labelProps
}) => {
  const labelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    if (labelRef.current) {
      const isOverflow = Boolean(labelRef.current.scrollWidth - labelRef.current.offsetWidth);
      if (isOverflow && onOverflow) {
        onOverflow(isOverflow);
      }
    }
  }, [label, onOverflow]);

  return (
    <label
      ref={labelRef}
      {...labelProps}
      className={clsx("form-label", {
        [`form-label-${size}`]: Boolean(size),
        "form-label_upper": isUpper,
      })}
    >
      {children || label}
    </label>
  );
};
