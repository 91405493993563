import { AppixMonitorResponse } from "@appix/core";
import React from "react";

import TableData from "./TableData";

interface TableMonitorValue {
  data: { otherData: { value: number } };
}

interface TableMonitorWidgetBreakdownProps extends Partial<AppixMonitorResponse.Result> {
  extendedData: TableMonitorValue;
}

const TableMonitorWidgetBreakdown: React.FC<TableMonitorWidgetBreakdownProps> = (props) => {
  const { id, name, description, extendedData } = props;

  return (
    <div id={id} className="p-2 h-100">
      <div className="h2">{name}</div>
      <p>{description}</p>
      <div className="table-responsive-container">
        <div className="table-responsive">
          <table className="table">
            <tbody>
              <TableData data={extendedData} />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableMonitorWidgetBreakdown;
