export enum AppixPopoverPlacement {
  "top-start",
  "top-center",
  "top-end",
  "right-start",
  "right-center",
  "right-end",
  "bottom-start",
  "bottom-center",
  "bottom-end",
  "left-start",
  "left-center",
  "left-end",
}

export enum AppixPopoverTrigger {
  click = "click",
  hover = "hover",
}

export type AppixPopoverCoord = [number, number];

export interface AppixPopoverProps extends React.PropsWithChildren {
  id: string;
  withArrow?: boolean;
  PopoverContent: React.ReactElement | null;
  placement?: keyof typeof AppixPopoverPlacement;
  trigger?: keyof typeof AppixPopoverTrigger;
}
