import { AppixMonitorResponse } from "@appix/core";
import React, { useMemo } from "react";
import { getUnit } from "utils";

interface ValueMonitorWidgetProps extends Partial<AppixMonitorResponse.Result> {
  extendedData: number;
}

const ValueMonitorWidget: React.FC<ValueMonitorWidgetProps> = (props) => {
  const { id, name, description, extendedData, chartParams } = props;
  const gauge = chartParams?.gauges?.[0];
  const coloringRules = gauge?.coloringRules;
  const minValue = gauge?.minValue || -Infinity;
  const maxValue = gauge?.maxValue || Infinity;
  const color =
    coloringRules
      ?.find((item) => item.value !== null && +item.value === extendedData)
      ?.color?.toLowerCase() ||
    coloringRules
      ?.find((item) => {
        const itemMin = item.min || item.min === 0 ? item.min : minValue;
        const itemMax = item.max || item.max === 0 ? item.max : maxValue;
        return itemMin <= extendedData && itemMax >= extendedData;
      })
      ?.color?.toLowerCase();

  const unit = useMemo(() => {
    return gauge?.unit ? getUnit(gauge?.unit) : "";
  }, [gauge]);

  return (
    <div id={id} className="mb-2">
      <div className="h-100">
        <div className="value-monitor-title">{name}</div>
        <p>{description}</p>
        <div className={`value-monitor-value text-${color ? color : "default"}`}>
          {extendedData}
          {unit}
        </div>
      </div>
    </div>
  );
};

export default ValueMonitorWidget;
