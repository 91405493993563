import { FC, useCallback, useEffect, useState } from "react";

import clsx from "clsx";

import { AppixButton } from "ui";

import KeyValueItem from "./KeyValueItem";

import { KeyValueDataItem, KeyValueProps } from "./types";

import { getDefaultDataValues } from "utils";

function data2array(nextValue: Record<string, any>): KeyValueDataItem[] {
  return Object.keys(nextValue).map((key) => ({
    key,
    value: nextValue[key],
  }));
}

function array2data(dataArray: KeyValueDataItem[]) {
  const nextData: Record<string, any> = {};
  dataArray.forEach(({ key, value = {} }) => {
    if (key) {
      nextData[key] = value;
    }
  });
  return nextData;
}

const KeyValue: FC<KeyValueProps> = (props) => {
  const { id, data, disabled, size, onChange, dynamicProperties } = props;
  const [dataArray, setDataArray] = useState<KeyValueDataItem[]>([]);

  const addItem = useCallback(() => {
    const nextDataArray = [...dataArray];
    const defaultValue = props.subFields ? getDefaultDataValues({}, props.subFields) : "";
    nextDataArray.push({ key: "", value: defaultValue });
    setDataArray(nextDataArray);
  }, [dataArray, props.subFields]);

  const handleChange = useCallback<
    (index: number) => (nextValue: Partial<KeyValueDataItem>) => void
  >(
    (index) => (nextValue) => {
      const nextDataArray = [...dataArray];
      if (!nextDataArray[index]) nextDataArray[index] = {};
      Object.assign(nextDataArray[index], nextValue);

      setDataArray(nextDataArray);
      onChange(array2data(nextDataArray));
    },
    [dataArray, onChange],
  );

  const handleRemove = useCallback<(index: number) => () => void>(
    (index) => () => {
      const nextDataArray = [...dataArray];
      nextDataArray.splice(index, 1);

      setDataArray(nextDataArray);
      onChange(array2data(nextDataArray));
    },
    [dataArray, onChange],
  );

  useEffect(() => {
    if (data && Object.keys(data).length) {
      setDataArray(data2array(data));
    } else {
      setDataArray([{ key: "", value: "" }]);
    }
  }, [data]);

  return (
    <div
      className={clsx("appix-form-keyvalue", {
        "appix-form-keyvalue_disabled": disabled,
        [`appix-form-keyvalue_${size}`]: !!size,
      })}
    >
      {dataArray.map(({ key = "", value }, index) => (
        <KeyValueItem
          {...props}
          key={index}
          keyValue={key}
          valueValue={value}
          index={index}
          onChange={handleChange(index)}
          onRemove={handleRemove(index)}
          dynamicProperties={dynamicProperties}
        />
      ))}

      <div className="appix-form-keyvalue__more">
        <AppixButton
          id={`${id}ADD_MORE`}
          size={size}
          beforeIconCode="add"
          variant="link"
          onClick={addItem}
        >
          Добавить еще
        </AppixButton>
      </div>
    </div>
  );
};

export default KeyValue;
