import React from "react";
import { TableTextWidgetClass, INITIAL_TABLETEXT_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import TableData from "./TableData";
import TableHeader from "./TableHeader";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const TabletextWidget: React.FC<AppixWidget.TableText> = (props) => {
  const tableTextWidget = useWidget<AppixWidget.TableText>(
    props,
    INITIAL_TABLETEXT_WIDGET_STATE,
    TableTextWidgetClass,
    [props.table, props.header],
  );

  return (
    <div className="table-responsive-container">
      <div id={props.id} className="table-responsive position-relative h-100">
        <CurrentWidgetInfo widget={tableTextWidget} />

        {tableTextWidget.state.isLoading && <Spinner />}
        {!tableTextWidget.state.isLoading && (
          <table className="table">
            {tableTextWidget.state.header && <TableHeader header={tableTextWidget.state.header} />}
            <TableData
              alignment={tableTextWidget.state.header?.alignment}
              table={tableTextWidget.state.table}
              noHeader={!tableTextWidget.state.header}
            />
          </table>
        )}
      </div>
    </div>
  );
};

export default TabletextWidget;
