import { FC, MouseEventHandler, useCallback, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import clsx from "clsx";
import Appix from "Appix";

import RemixIcon from "components/RemixIcon";

interface UserWidgetProps {
  loginInfoLocation?: string;
  loginPageLocation?: string;
  collapsed?: boolean;
  baseUrl: string;
}

interface IUserInfo {
  id: string;
  login: string;
  name: string;
}

const UserWidget: FC<UserWidgetProps> = ({ collapsed, loginInfoLocation, loginPageLocation }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState<IUserInfo | null>(null);

  const logout = useCallback(() => {
    setUser(null);
    Appix.saveAuthToken("");
    loginPageLocation && navigate(loginPageLocation);
    return false;
  }, [loginPageLocation, navigate]);

  const loadUser = useCallback(async () => {
    if (loginInfoLocation && !user?.id && Appix.hasAuth()) {
      try {
        const result = await Appix.fetch<IUserInfo>(loginInfoLocation);
        setUser(result);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
        logout();
      }
    }
  }, [loginInfoLocation, user, logout]);

  const handleLogin = useCallback<MouseEventHandler<HTMLAnchorElement | HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      loginPageLocation && navigate(loginPageLocation);
    },
    [loginPageLocation, navigate],
  );

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  useLayoutEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <div
      className={clsx("appix-user-widget", {
        "appix-user-widget_collapsed": collapsed,
      })}
    >
      {!user?.id ? (
        <>
          <div className="appix-user-widget__body">
            <div role="button" onClick={handleLogin} className="appix-user-widget__title">
              Авторизоваться
            </div>
          </div>
          <a
            className="appix-user-widget__login"
            href={loginPageLocation}
            onClick={handleLogin}
            title="Login"
          >
            <RemixIcon icon="login-box" />
          </a>
        </>
      ) : (
        <>
          <div className="appix-user-widget__body">
            <div className="appix-user-widget__title">{user.name}</div>
          </div>
          <button
            type="button"
            className="appix-user-widget__logout"
            onClick={handleLogout}
            title="Logout"
          >
            <RemixIcon icon="logout-box-r" />
          </button>
        </>
      )}
    </div>
  );
};

export default UserWidget;
