import { FC, useRef, useState, useCallback, useEffect, useLayoutEffect } from "react";

import { AppixDecisionConfig, AppixDecisionData, IFormWidgetAction } from "@appix/core";

import { Modal } from "bootstrap";

import { ContainerWidget, FormWidget } from "widgets";

let dialogConfirmed = false;
let dialogTimer: NodeJS.Timeout;
let formData = {};
let formAction: IFormWidgetAction | null = null;

interface DecisionProps {
  id: string;
  title?: string;
  widget: {
    decision(a: AppixDecisionConfig): Promise<AppixDecisionData>;
  };
}

const Decision: FC<DecisionProps> = ({ id, title = "", widget }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const [bsModal, setBsModal] = useState<Modal | null>(null);
  const [opened, setOpened] = useState<boolean>();
  const [formWidget, setFormWidget] = useState<AppixWidget.Form | null>(null);
  const [containerWidget, setContainerWidget] = useState<AppixWidget.Container | null>(null);

  const handleDialogCancel = useCallback(() => {
    setOpened(false);
  }, []);

  const decision = useCallback<(a: AppixDecisionConfig) => Promise<AppixDecisionData>>(
    ({ formWidget, containerWidget }) => {
      setOpened(true);
      setFormWidget(formWidget);
      setContainerWidget(containerWidget);

      return new Promise((resolve) => {
        dialogTimer = setInterval(() => {
          if (dialogConfirmed) {
            setOpened(false);
            resolve({
              button: formAction?.title!,
              data: formData,
            });
            dialogConfirmed = false;
            formAction = null;
            formData = {};
            clearInterval(dialogTimer);
          }
        }, 250);
      });
    },
    [],
  );

  const handleCloseDialogFromBack = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (e.target === e.currentTarget) {
        handleDialogCancel();
      }
    },
    [handleDialogCancel],
  );

  const handleFormChange = useCallback<(a: Record<string, any>) => void>((nextData) => {
    Object.assign(formData, nextData);
  }, []);

  const handleAction = useCallback<(config: IFormWidgetAction) => void>((action) => {
    dialogConfirmed = true;
    formAction = action;
  }, []);

  useLayoutEffect(() => {
    if (opened) {
      // нужно время чтобы отработала предыдущая анимация закрытия
      setTimeout(() => bsModal?.show(), 250);
    } else {
      bsModal?.hide();
    }
  }, [opened, bsModal]);

  useLayoutEffect(() => {
    const modalContainer = modalRef.current;
    function handleClose() {
      setOpened(false);
    }
    if (modalContainer) {
      modalContainer.addEventListener("hide.bs.modal", handleClose);
    }
    return () => {
      if (modalContainer) {
        modalContainer.removeEventListener("hide.bs.modal", handleClose);
      }
      bsModal?.hide();
    };
  }, [bsModal]);

  useEffect(() => {
    if (modalRef.current) {
      setBsModal(new Modal(modalRef.current));
    }
  }, []);

  useEffect(() => {
    widget.decision = decision;
    return () => clearInterval(dialogTimer);
  }, [widget, decision]);

  // TODO: вынести логику модалки в AppixModal
  return (
    <div
      ref={modalRef}
      className={"modal fade"}
      aria-modal="true"
      role="dialog"
      onClick={handleCloseDialogFromBack}
    >
      <div className="modal-dialog">
        <div className="modal-content text-center">
          <div className="modal-close d-flex justify-content-end">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleDialogCancel}
              id={id + "CLOSE"}
            />
          </div>

          {/* <div className="modal-header justify-content-center d-block">
            <h5 className="modal-title">{title}</h5>
          </div> */}
          <div className="modal-body">
            {containerWidget ? (
              <div className="mb-4">
                <ContainerWidget {...containerWidget} />
              </div>
            ) : null}
            {formWidget ? (
              <FormWidget {...formWidget} onChange={handleFormChange} onAction={handleAction} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Decision;
