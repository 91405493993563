import { AppixCore, getCookie, setCookie } from "@appix/core";

const baseUrl = process.env.PUBLIC_URL ? process.env.PUBLIC_URL + "/" : undefined;

class Appix extends AppixCore {
  baseUrl = baseUrl?.replace(/^https?:\/\/.+?\//i, "") || "";
  getAuthToken = () => getCookie(this.authTokenKey);
  saveAuthToken = (value: string) => setCookie(this.authTokenKey, value);
}

export default new Appix();
