import React from "react";
import clsx from "clsx";
import ReactMarkdown from "react-markdown";
import { ListWidgetClass, INITIAL_LIST_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import remarkGfm from "remark-gfm";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const ListWidget: React.FC<AppixWidget.List> = (props) => {
  const listWidget = useWidget<AppixWidget.List>(
    props,
    INITIAL_LIST_WIDGET_STATE,
    ListWidgetClass,
    [props.items],
  );

  return (
    <div className="position-relative">
      <CurrentWidgetInfo widget={listWidget} />
      <ul
        id={props.id}
        className={clsx({
          "d-none": !listWidget.state.displayed,
          "d-block": listWidget.state.displayed,
        })}
      >
        {listWidget.state.isLoading && <Spinner />}
        {!listWidget.state.isLoading &&
          listWidget.state.items.map((item, index) => (
            <li key={index}>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{item}</ReactMarkdown>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ListWidget;
