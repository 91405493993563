// import { AppixMonitorResponse } from "@appix/core";
import { AppixCharts } from "ui";
import React, { useEffect, useState } from "react";
import { optionHandler } from "ui/AppixCharts";

interface HistogramMonitorWidgetProps {
  id: string;
  // state: AppixWidget.Monitor;
  state: any;
}

const GaugeMonitorWidget: React.FC<HistogramMonitorWidgetProps> = ({ id, state }) => {
  const [option, setOption] = useState({});

  useEffect(() => {
    setOption(optionHandler(state?.monitorExtendedData, { monitorData: state?.monitorData }));
  }, [state]);

  return (
    <div id={id} className="mb-4 h-100 w-100">
      <AppixCharts option={option} />
    </div>
  );
};

export default GaugeMonitorWidget;
