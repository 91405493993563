import { AppixTreeNodeWithKey } from "./types";

export interface BaseTreeItem {
  title: string;
  value: string;
  opened?: boolean;
}

export function convertTree<RAW extends { children?: RAW[] }>(
  tree: RAW[],
  cb: (item: RAW) => BaseTreeItem,
  parentKey = "",
): Array<AppixTreeNodeWithKey<RAW>> {
  return tree.map((item) => {
    const baseItem = cb(item);
    return {
      key: baseItem.value,
      parentKey,
      label: baseItem.title,
      value: baseItem.value,
      opened: baseItem.opened,
      raw: item,
      children: item.children ? convertTree(item.children, cb, parentKey + baseItem.value) : [],
    };
  });
}
