import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { createPortal } from "react-dom";
import { Quarters } from "./Quarters";

export interface AppixQuarterPickerProps {
  boxed?: boolean;
  onChange: (values: [Date, Date]) => void;
}

export const AppixQuarterPicker: React.FC<AppixQuarterPickerProps> = ({
  boxed = true,
  onChange,
}) => {
  const [isDomLoaded, setIsDomLoaded] = useState(false);
  const [currentValue, setCurrentValue] = useState(new Date());
  const [currentQuarter, setCurrentQuarter] = useState(1);

  useEffect(() => {
    setIsDomLoaded(true);
  }, []);

  const ref = useRef<HTMLInputElement>(null);

  const getQuarterRange = useCallback(
    (date: Date, quarter: number): [Date, Date] => [
      moment(date).quarter(quarter).startOf("quarter").toDate(),
      moment(date).quarter(quarter).endOf("quarter").toDate(),
    ],
    [],
  );

  const onCalendarChange = useCallback(
    (e: any): void => {
      setCurrentValue(e);
      onChange(getQuarterRange(e, currentQuarter));
    },
    [currentQuarter, onChange, getQuarterRange],
  );

  return (
    <div className={boxed ? "calendar-box" : ""}>
      <Calendar
        className="quarter-pick"
        onChange={onCalendarChange}
        value={currentValue}
        defaultView="decade"
        maxDetail="decade"
        minDetail="decade"
        inputRef={ref}
      />
      {isDomLoaded ? (
        createPortal(
          <Quarters
            currentQuarter={currentQuarter}
            setCurrentQuarter={setCurrentQuarter}
            onChange={onChange}
            currentValue={currentValue}
            getQuarterRange={getQuarterRange}
          />,
          ref.current?.children?.[1]!,
        )
      ) : (
        <div></div>
      )}
    </div>
  );
};
