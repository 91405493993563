export default function getColor(colorCode?: AppixWidget.Color | null) {
  if (!colorCode) return "";
  switch (colorCode) {
    case "DANGER":
      return "#ef4444";
    case "DARK":
      return "#374151";
    case "INFO":
      return "#0dcaf0";
    case "LIGHT":
      return "#ffffff";
    case "PRIMARY":
      return "#4285f4";
    case "SECONDARY":
      return "#f3f4f6";
    case "SUCCESS":
      return "#10b981";
    case "WARNING":
      return "#f4cd00";
    default:
      return colorCode;
  }
}
