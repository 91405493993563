import { FC, useCallback, useMemo, useEffect, useState } from "react";

import {
  INITIAL_TREETABLE_WIDGET_STATE,
  ITreeTableWidgetNode,
  TableWidgetFilterState,
  TreeTableWidgetClass,
} from "@appix/core";

import useWidget from "utils/useWidget";

import { Confirm } from "components";
import Spinner from "components/Spinner/Spinner";
import TableSearchItem from "ui/AppixTable/TableSearchItem";
import { AppixTree, AppixTreeLabelComponent, AppixTreeNodeState, AppixTreeOnChange } from "ui";
import { tree2list } from "ui/AppixTree";

import TreeLabelWithActions from "./TreeLabelWithActions";
import TreetableWidgetGlobalActions from "./TreetableWidgetGlobalActions";
import TableFormModal from "../TableWidget/TableFormModal";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const prepareData = (
  treeData: ITreeTableWidgetNode[],
  labelProperty: string,
  parentKey = "",
): AppixTreeNodeState<ITreeTableWidgetNode>[] => {
  return treeData.map((item) => ({
    key: String(parentKey + (item.id ?? item?.value?.id)),
    parentKey,
    label: item.label ?? item?.value[labelProperty],
    value: String(item.id ?? item?.value?.id),
    raw: item,
    opened: !!item.isExpanded,
    children: item.children?.length
      ? prepareData(item.children, labelProperty, String(parentKey + (item.id ?? item?.value?.id)))
      : [],
  }));
};

const getCheckedRowsItems = (
  checkedKeys: string[],
  draftData: AppixTreeNodeState<ITreeTableWidgetNode>[],
): ITreeTableWidgetNode[] => {
  const list = tree2list(draftData);
  const checkedRowsList = checkedKeys.map((key) => list.find((item) => item.key === key)!);
  return checkedRowsList.map(({ raw }) => raw) as ITreeTableWidgetNode[];
};

interface TreetableWidgetProps extends Partial<AppixWidget.Table> {
  id: string;
  showCaption?: boolean;
  values?: (string | number)[];
  isTreeForSelect?: boolean;
  onChange?: (nextSelectedRows: { id: string | number; name: string }[]) => void;
  onLoadedData?: (data: ITreeTableWidgetNode[], labelProperty: string) => void;
}

const TreetableWidget: FC<TreetableWidgetProps> = ({
  showCaption = true,
  values,
  isTreeForSelect,
  onChange,
  onLoadedData,
  ...props
}) => {
  const widget = useWidget<AppixWidget.Table, TreeTableWidgetClass>(
    props,
    INITIAL_TREETABLE_WIDGET_STATE,
    TreeTableWidgetClass,
    [props.table],
  );

  const {
    id: widgetId,
    data,
    columns,
    rowActions,
    table,
    formData,
    formMode,
    isLoading,
    selectedRows,
  } = widget.state;

  const treeId = widgetId;

  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

  const draftData = useMemo<AppixTreeNodeState<ITreeTableWidgetNode>[]>(() => {
    return prepareData(data, widget.labelProperty);
  }, [data, widget.labelProperty]);

  const isClearControl = useMemo<boolean>(() => {
    return Boolean(table?.selectMode?.control?.clear);
  }, [table?.selectMode?.control?.clear]);

  const isSelectAllControl = useMemo<boolean>(() => {
    return Boolean(table?.selectMode?.control?.selectAll);
  }, [table?.selectMode?.control?.selectAll]);

  const isSubmitControl = useMemo<boolean>(() => {
    return Boolean(table?.selectMode?.control?.submit);
  }, [table?.selectMode?.control?.submit]);

  const labelComponent = useCallback<AppixTreeLabelComponent<ITreeTableWidgetNode>>(
    ({ node, label, depth }) => (
      <TreeLabelWithActions
        widgetId={treeId}
        rowActions={rowActions ? rowActions[node.value] : {}}
        node={node}
        label={label}
        depth={depth}
      />
    ),
    [rowActions, treeId],
  );

  const handleTreeChange = useCallback<AppixTreeOnChange>(
    (checkedKeys) => {
      // отправляем в виджет только те из выбранных
      // которые не имеют родителя среди выбранных
      const nextSelectedRows = getCheckedRowsItems(checkedKeys, draftData);
      widget.setSelectedRows(nextSelectedRows);
      if (onChange) {
        onChange(
          nextSelectedRows.map((item) => ({
            id: item?.value?.id ?? item?.id,
            name: item?.label ?? item?.value[widget.labelProperty],
          })),
        );
      } else {
        setCheckedKeys(checkedKeys);
      }
    },
    [draftData, widget, onChange],
  );

  const handleTreeClear = useCallback(() => {
    widget.clear();
  }, [widget]);

  const handleSelectAll = useCallback(
    (checkedKeys) => {
      setCheckedKeys(checkedKeys);
      widget.selectAll();
    },
    [widget],
  );

  const handleTreeSubmit = useCallback(() => {
    widget.submit();
  }, [widget]);

  const handleChangeFilterState = useCallback<(nextState: TableWidgetFilterState) => void>(
    (nextState) => {
      widget.setFilterState(nextState);
    },
    [widget],
  );

  const handleCloseFormModal = useCallback(() => {
    widget.setState({
      formData: null,
    });
  }, [widget]);

  useEffect(() => {
    if (data.length && Array.isArray(values)) {
      const list = tree2list(draftData);
      setCheckedKeys(
        list
          .filter(({ raw }) => raw && values.includes(raw?.id ? +raw.id : raw.value?.id))
          .map(({ key }) => key),
      );
      const data = widget.arrayData;
      widget.setSelectedRows(
        values.map(
          (id) =>
            data.find((item) => {
              // eslint-disable-next-line eqeqeq
              return (item?.id ?? item?.value?.id) == id;
            })!,
        ),
      );
    }
  }, [values, data, draftData, widget]);

  useEffect(() => {
    const list = tree2list(draftData);
    setCheckedKeys(
      selectedRows.map(
        (item) => list.find((listItem) => item.value.id.toString() === listItem.value)?.key || "",
      ),
    );
  }, [selectedRows, draftData]);

  useEffect(() => {
    if (data) {
      onLoadedData && onLoadedData(data, widget.labelProperty);
    }
  }, [data, widget, onLoadedData]);

  return (
    <div className="w-100 position-relative">
      <CurrentWidgetInfo widget={widget} />
      {showCaption && <h5>{table?.caption}</h5>}
      {isLoading && <Spinner />}
      <div className="d-flex align-items-end">
        {columns.map((column) => {
          if (!column.search?.length || column.dataIndex === widget.primaryKey) {
            return null;
          }

          return (
            <TableSearchItem<ITreeTableWidgetNode>
              key={column.dataIndex}
              id={treeId}
              column={column}
              onChange={handleChangeFilterState}
            />
          );
        })}
      </div>
      <TreetableWidgetGlobalActions widgetId={treeId} globalActions={widget.globalActions} />
      <AppixTree<ITreeTableWidgetNode>
        id={treeId}
        tree={draftData}
        checkable={table?.selectMode?.enabled}
        isClearControl={isClearControl}
        isSelectAllControl={isSelectAllControl}
        isSubmitControl={isSubmitControl}
        checkedKeys={checkedKeys}
        isTreeForSelect={isTreeForSelect}
        maxCheckedCount={table?.selectMode?.selectionMaximumQty}
        onChange={handleTreeChange}
        onClear={handleTreeClear}
        onSelectAll={handleSelectAll}
        onSubmit={handleTreeSubmit}
        labelComponent={labelComponent}
        onlyRootChecked
      />
      {formData && (
        <TableFormModal
          id={treeId}
          form={widget.form || undefined}
          formBroadcast={widget.formBroadcast}
          formMode={formMode}
          onClose={handleCloseFormModal}
        />
      )}
      <Confirm id={treeId} widget={widget} />
    </div>
  );
};

export default TreetableWidget;
