import TreeNode from "./TreeNode";

import { AppixTreeProps } from "./types";

function Tree<RAW>({ tree, ...props }: AppixTreeProps<RAW>) {
  return (
    <div className="apx-tree">
      {tree.map((node) => (
        <TreeNode<RAW> {...props} key={node.key} node={node} depth={0} />
      ))}
    </div>
  );
}

export default Tree;
