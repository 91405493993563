import React from "react";
import clsx from "clsx";

import { AppixSelectOnChange, IAppixSelectItem } from "./types";

interface AppixSelectOptionProps extends IAppixSelectItem {
  onChange?: AppixSelectOnChange;
  isActive?: boolean;
  isSmall?: boolean;
  isEmpty?: boolean;
}

const AppixSelectOption: React.FC<AppixSelectOptionProps> = ({
  label,
  value,
  onChange,
  isActive,
  isSmall,
  isEmpty,
}) => {
  const handleChange = React.useCallback(
    (e) => {
      e.preventDefault();
      if (e.clientX || e.clientY) {
        onChange && onChange(value);
      }
    },
    [value, onChange],
  );

  const viewLabel = React.useMemo(() => label || String(value), [label, value]);

  return (
    <button
      className={clsx("list-group-item dropdown-item form-select__option", {
        "px-2 py-1": isSmall,
        active: isActive,
        "text-center": isEmpty,
      })}
      onClick={handleChange}
      tabIndex={0}
    >
      {isEmpty ? "NO OPTIONS" : viewLabel}
    </button>
  );
};

export default AppixSelectOption;
