export default function getDefaultDataValues(newData, columns) {
  columns?.forEach((column) => {
    const columnName = column.mapping?.first || column.name;
    if (!newData[columnName]) {
      if (column.dataType === "BOOLEAN") {
        newData[columnName] = false;
      } else if (column.dataType === "OBJECT") {
        newData[columnName] = getDefaultDataValues({}, column.subColumns);
      } else if (column.dataType === "KEY_VALUE") {
        newData[columnName] = {
          "": getDefaultDataValues({}, column.subColumns),
        };
      } else {
        newData[columnName] = null;
      }
    }
  });
  return newData;
}
