import React, { useCallback } from "react";
import clsx from "clsx";

import { AppixTree, AppixTreeLabelComponent, MenuItemType, convertTree } from "ui";
import { collapseSlashes } from "utils";

import Appix from "Appix";

interface MenuChildrenTooltipProps {
  id: string;
  item: MenuItemType;
  expandedItemsData: Record<string, boolean>;
  handleSetExpanded(itemId: string, value: boolean): void;
  onItemClick(path: string): void;
}

const MenuChildrenTooltip: React.FC<MenuChildrenTooltipProps> = ({
  id,
  item,
  expandedItemsData,
  handleSetExpanded,
  onItemClick,
}) => {
  const labelComponent = useCallback<AppixTreeLabelComponent<MenuItemType>>(
    ({ node }) => {
      const menuItem = node.raw;

      const itemSaveId = menuItem?.id ?? menuItem?.fullpath!;
      const baseUrl = Appix.baseUrl;
      const nextRoute = collapseSlashes(baseUrl + "/content" + menuItem?.fullpath);
      const isActive = window.location.pathname.includes(nextRoute);

      function handleItemClick(e) {
        e.preventDefault();
        menuItem?.fullpath && onItemClick(nextRoute);
      }

      function handleToggleOpened() {
        handleSetExpanded(itemSaveId, !expandedItemsData[itemSaveId]);
      }

      return menuItem?.hasPage ? (
        <a
          href={nextRoute}
          className={clsx("tooltip-menu__link", {
            active: isActive,
          })}
          role="button"
          // eslint-disable-next-line
          onClick={handleItemClick}
        >
          {menuItem?.title}
        </a>
      ) : (
        <span
          className={clsx("tooltip-menu__link", {
            active: isActive,
          })}
          role="button"
          // eslint-disable-next-line
          onClick={handleToggleOpened}
        >
          {menuItem?.title}
        </span>
      );
    },
    [expandedItemsData, handleSetExpanded, onItemClick],
  );

  if (!item.children) return null;

  return (
    <div className="tooltip-menu">
      <div className="tooltip-menu__title">{item.title}</div>

      <AppixTree<MenuItemType>
        id={`${id}SUBMENU`}
        tree={convertTree<MenuItemType>(item.children, ({ title, id, fullpath }) => ({
          title,
          value: id ?? fullpath ?? String(Math.random()),
          opened: !!expandedItemsData[id ?? fullpath!],
        }))}
        labelComponent={labelComponent}
        childrenOpened={false}
      />
    </div>
  );
};

export default MenuChildrenTooltip;
