import { AppixMonitorResponse } from "@appix/core";
import React, { useMemo, useRef } from "react";
import { getUnit } from "utils";
import ReactECharts from "echarts-for-react";

type MonitorDataResult<T = string | number> = Record<string, Record<string, T>>;

interface GaugeMonitorWidgetProps extends Partial<AppixMonitorResponse.Result> {
  url?: string;
  extendedData: MonitorDataResult;
}

const sizes = {
  "3XL": 520,
  "2XL": 460,
  XL: 395,
  L: 380,
  M: 365,
  S: 190,
  XS: 0,
};

const gaugeSizes = {
  "3XL": 136,
  "2XL": 120,
  XL: 104,
  L: 96,
  M: 80,
  S: 64,
  XS: 40,
};

const lineSizes = {
  "3XL": 12,
  "2XL": 10,
  XL: 8,
  L: 7,
  M: 6,
  S: 4,
  XS: 2,
};

const colors = {
  PRIMARY: "#4285F4",
  SECONDARY: "#f3f4f6",
  SUCCESS: "#10b981",
  INFO: "#0dcaf0",
  WARNING: "#F4CD00",
  DANGER: "#EF4444",
  LIGHT: "rgba(209, 213, 219, 0.70)",
  DARK: "#374151",
};

const GaugeMonitorWidget: React.FC<GaugeMonitorWidgetProps> = (props) => {
  // return <div className="">GaugeMonitorWidget</div>;

  const { id, name, description, chartParams, extendedData } = props;

  const container = useRef<HTMLDivElement>(null);

  const containerWidth = useMemo(() => {
    if (container.current) {
      return container?.current?.clientWidth;
    }
    return 520;
  }, []);

  const size = useMemo(() => {
    return Object.keys(sizes).find((item) => containerWidth >= sizes[item]) ?? "XS";
  }, [containerWidth]);

  const gauge = useMemo(() => {
    const defaultGauge = {
      unit: "",
      maxValue: 100,
      minValue: 0,
      coloringRules: [],
    };

    if (Array.isArray(chartParams?.gauges) && chartParams?.gauges[0]) {
      return chartParams?.gauges[0];
    }
    if (chartParams?.gauges && Object.values(chartParams.gauges)[0]) {
      return Object.values(chartParams.gauges)[0];
    }
    return defaultGauge;
  }, [chartParams]);

  const percent = useMemo(() => {
    let minValue = gauge?.minValue;
    let maxValue = gauge?.maxValue;
    if (minValue < 0) {
      minValue = 0;
    }
    if (maxValue < 0) {
      maxValue = 0;
    }
    return gauge ? (+extendedData * maxValue) / (maxValue - minValue) : 0;
  }, [extendedData, gauge]);

  const gaugeSize = useMemo(() => {
    return gaugeSizes[size] + gaugeSizes[size] * 0.3;
  }, [size]);

  const isShowTitle = useMemo(() => {
    return size !== "S" && size !== "XS";
  }, [size]);

  const detailSize = useMemo(() => {
    if (containerWidth >= sizes["3XL"]) {
      return 14;
    }
    if (containerWidth >= sizes["XS"]) {
      return 10;
    }
    return 12;
  }, [containerWidth]);

  const unit = useMemo(() => {
    return getUnit(gauge?.unit);
  }, [gauge]);

  const colorProgress = useMemo(() => {
    const color =
      gauge?.coloringRules?.find((item) => item.value !== null && +item.value === percent)?.color ||
      gauge?.coloringRules
        ?.find((item) => {
          const itemMin = item.min || item.min === 0 ? item.min : -Infinity;
          const itemMax = item.max || item.max === 0 ? item.max : Infinity;
          return itemMin <= percent && itemMax > percent;
        })
        ?.color?.toUpperCase() ||
      "PRIMARY";
    return colors[color];
  }, [gauge?.coloringRules, percent]);

  const option = useMemo(() => {
    const gaugeData = [
      {
        value: percent,
        name: description,
        title: {
          offsetCenter: ["0%", "20%"],
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ["0%", isShowTitle ? "-20%" : "5%"],
        },
      },
    ];
    return {
      color: [colorProgress],
      series: [
        {
          type: "gauge",
          startAngle: -270,
          endAngle: 90,
          min: percent >= 0 ? 0 : gauge?.minValue ?? -Infinity,
          max: percent < 0 ? 0 : gauge?.maxValue ?? Infinity,
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 0,
              borderColor: "#4E7FFF",
            },
          },
          axisLine: {
            lineStyle: {
              width: lineSizes[size],
              color: [[1, "rgba(209, 213, 219, 0.35)"]],
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 50,
          },
          data: gaugeData,
          title: {
            show: isShowTitle,
            fontSize: 10,
            lineHeight: 16,
            color: "#6B7280",
            overflow: "break",
            width: gaugeSize - gaugeSize / 2,
          },
          detail: {
            fontSize: detailSize,
            fontWeight: 400,
            color: "#374151",
            borderWidth: 0,
            formatter: "{value}%",
          },
        },
      ],
    };
  }, [
    percent,
    description,
    isShowTitle,
    colorProgress,
    gauge?.minValue,
    gauge?.maxValue,
    size,
    gaugeSize,
    detailSize,
  ]);

  return (
    <div style={{ position: "relative", width: "100%" }} ref={container}>
      <div id={id} className="h-100 gauge-monitor-border">
        <ReactECharts
          option={option}
          theme={"appix"}
          style={{
            position: "relative",
            width: `${gaugeSize}px`,
            height: `${gaugeSize}px`,
          }}
          opts={{ locale: "RU" }}
          notMerge={true}
        />
      </div>
      <div
        className={`gauge-decription gauge-decription_${size}`}
        style={{ left: gaugeSize + "px" }}
      >
        <div className="gauge-decription__title">{name}</div>
        <div className="gauge-decription__value">{`${extendedData} ${unit}`}</div>
        <div className="gauge-decription__maxvalue">
          из {gauge?.maxValue} {unit}
        </div>
      </div>
    </div>
  );
};

export default GaugeMonitorWidget;
