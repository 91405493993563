import { FC, useCallback, useEffect, useMemo } from "react";

import clsx from "clsx";

import {
  INITIAL_TABLE_WIDGET_STATE,
  ITableWidgetState,
  TableWidgetClass,
  TableWidgetFilterState,
} from "@appix/core";

import { AppixButtonColor, AppixTable } from "ui";
import { Pagination, Confirm } from "components";

import useWidget from "utils/useWidget";

import TableFormModal from "./TableFormModal";

import { AppixTableColumn } from "ui/AppixTable/types";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const getButtonColor = (action: string) => {
  if (action === "DELETE") {
    return AppixButtonColor.danger;
  } else if (action === "ADD") {
    return AppixButtonColor.primary;
  }
  return AppixButtonColor.dark;
};

type ITableRow = Record<string, any>;

interface TableWidgetProps extends Partial<AppixWidget.Table> {
  id: string;
  showCaption?: boolean;
  values?: ITableRow[];
  onChange?: (nextSelectedRows: ITableRow[]) => void;
  onLoadedData?: (data: ITableRow[]) => void;
}

const TableWidget: FC<TableWidgetProps> = ({
  showCaption = true,
  values,
  onChange,
  onLoadedData,
  ...props
}) => {
  const widget = useWidget<ITableWidgetState, TableWidgetClass<ITableRow>>(
    props,
    INITIAL_TABLE_WIDGET_STATE,
    TableWidgetClass,
    [props.api, props.table],
  );
  const {
    id: widgetId,
    isLoading,
    displayed,
    data,
    table,
    columns,
    rowActions,
    formData,
    formMode,
    filterState,
    selectedRows,
    pageNum,
    total,
  } = widget.state;

  const primaryKey = widget.primaryKey || "id";

  const tableId = widgetId;

  const setFilterState = useCallback<(nextState: TableWidgetFilterState) => void>(
    (nextState) => {
      widget.setFilterState(nextState);
    },
    [widget],
  );

  const setSortingState = useCallback(
    (column: AppixTableColumn<any>) => {
      widget.setSortingState(column);
    },
    [widget],
  );

  const setSelectedRows = useCallback<(nextRows: ITableRow[]) => void>(
    (nextSelectedRows) => {
      widget.setSelectedRows(nextSelectedRows);
      onChange && onChange(nextSelectedRows);
    },
    [widget, onChange],
  );

  const handleChangePage = useCallback<(nextPage: number, nextPageSize: number) => void>(
    (nextPage, nextPageSize) => {
      widget.setPaginationState({ pageNum: nextPage, pageSize: nextPageSize });
    },
    [widget],
  );

  const handleClickRow = useCallback<(row: ITableRow) => void>(
    (row) => {
      if (primaryKey) {
        widget.selectRow(row[primaryKey]);
      }
    },
    [widget, primaryKey],
  );

  const handleDoubleClickRow = useCallback<(row: ITableRow) => void>(
    (row) => {
      if (primaryKey) {
        widget.dblClickItem(row[primaryKey]);
      }
    },
    [widget, primaryKey],
  );

  const handleCloseFormModal = useCallback(() => {
    widget.setState({
      formData: null,
    });
  }, [widget]);

  useEffect(() => {
    if (values) {
      widget.setState({ selectedRows: values });
    }
  }, [values, widget]);

  useEffect(() => {
    if (data) {
      onLoadedData && onLoadedData(data);
    }
  }, [data, onLoadedData]);

  const handleTreeClear = useCallback(() => {
    widget.clear();
  }, [widget]);

  const handleSelectAll = useCallback(() => {
    widget.selectAll();
  }, [widget]);

  const handleTreeSubmit = useCallback(() => {
    widget.submit();
  }, [widget]);

  const isClearControl = useMemo<boolean>(() => {
    return Boolean(table?.selectMode?.control?.clear);
  }, [table?.selectMode?.control?.clear]);

  const isSelectAllControl = useMemo<boolean>(() => {
    return Boolean(table?.selectMode?.control?.selectAll);
  }, [table?.selectMode?.control?.selectAll]);

  const isSubmitControl = useMemo<boolean>(() => {
    return Boolean(table?.selectMode?.control?.submit);
  }, [table?.selectMode?.control?.submit]);

  return (
    <div
      className={clsx("w-100 position-relative", {
        "d-none": !displayed,
      })}
    >
      <CurrentWidgetInfo widget={widget} />

      {columns?.length ? (
        <>
          <AppixTable<ITableRow>
            id={tableId}
            caption={showCaption ? table?.caption : null}
            primaryKey={primaryKey}
            data={data}
            columns={columns}
            rowActions={rowActions}
            globalActions={widget.globalActions}
            getButtonColor={getButtonColor}
            filterState={filterState}
            setFilterState={setFilterState}
            setSortingState={setSortingState}
            onClickRow={handleClickRow}
            onDoubleClickRow={handleDoubleClickRow}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectable={table?.selectMode?.enabled}
            selectableCount={table?.selectMode?.selectionMaximumQty}
            isClearControl={isClearControl}
            isSelectAllControl={isSelectAllControl}
            isSubmitControl={isSubmitControl}
            onClear={handleTreeClear}
            onSelectAll={handleSelectAll}
            onSubmit={handleTreeSubmit}
            isLoading={isLoading}
          />
          {table?.pagination && (
            <Pagination
              id={tableId}
              page={pageNum}
              limit={table?.defaultPageSize || 10}
              total={total}
              onChangePage={handleChangePage}
            />
          )}
        </>
      ) : null}

      {formData && (
        <TableFormModal
          id={tableId}
          form={widget.form || undefined}
          formBroadcast={widget.formBroadcast}
          formMode={formMode}
          onClose={handleCloseFormModal}
          isShown={!!formData}
        />
      )}

      <Confirm id={tableId} widget={widget} />
    </div>
  );
};

export default TableWidget;
