import React, { useCallback, useEffect, useState } from "react";
import TimeInput from "./TimeInput";
import { AppixSize } from "../types";
import moment from "moment";
import clsx from "clsx";

export interface TimePickerProps {
  size?: keyof typeof AppixSize;
  boxed?: boolean;
  onChange: (value: Date) => void;
  value?: Date | null;
  className?: string;
  inputId?: string;
}

export const AppixTimePicker: React.FC<TimePickerProps> = ({
  size = "md",
  boxed = true,
  onChange,
  value,
  className,
  inputId = "",
}) => {
  const [timePickerValue, setTimePickerValue] = useState(
    value ? moment(value).toDate() : moment().startOf("D").toDate(),
  );

  useEffect(() => {
    setTimePickerValue(moment(value).toDate());
  }, [value]);

  const handleHoursChange = useCallback(
    (e: string): void => {
      const newValue = moment(timePickerValue).hour(+e);
      setTimePickerValue(moment(newValue).toDate());
      onChange(moment(newValue).toDate());
    },
    [onChange, timePickerValue],
  );

  const handleMinutesChange = useCallback(
    (e: string): void => {
      const newValue = moment(timePickerValue)
        .minute(+e)
        .second(0)
        .millisecond(0);
      setTimePickerValue(moment(newValue).toDate());
      onChange(moment(newValue).toDate());
    },
    [onChange, timePickerValue],
  );

  return (
    <div
      className={clsx("timepicker-container", { "timepicker-container-boxed": boxed }, className)}
    >
      <TimeInput
        className={`timepicker-hours`}
        value={moment(timePickerValue).hour().toString()}
        onChange={handleHoursChange}
        max={23}
        id={inputId + "HOURS"}
      />
      <TimeInput
        className={`timepicker-minutes`}
        value={moment(timePickerValue).minute().toString()}
        onChange={handleMinutesChange}
        max={59}
        id={inputId + "MINUTES"}
      />
    </div>
  );
};
