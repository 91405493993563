import React, { useEffect } from "react";
import { AppixBlockquote } from "ui/AppixBlockquote/AppixBlockquote";

export function MessageItem({ message, handleClose }) {
  const { level, title, autoHide, id } = message;

  useEffect(() => {
    if (autoHide) {
      const closeTimeout = setTimeout(handleClose(id), 3000);
      return () => {
        clearTimeout(closeTimeout);
      };
    }
  }, [autoHide, handleClose, id]);

  return (
    <div className="appix-messages__item">
      <AppixBlockquote
        level={level}
        text={title}
        displayed={true}
        isClosable={!autoHide}
        onClose={handleClose(id)}
      />
    </div>
  );
}
