import { FC } from "react";
import clsx from "clsx";
import { GanttWidgetClass, INITIAL_GANTT_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Gantt from "ui/AppixGantt";
import { CurrentWidgetInfo } from "components";

const GanttWidget: FC<AppixWidget.Gantt> = (props) => {
  const ganttWidget = useWidget<AppixWidget.Gantt>(
    props,
    INITIAL_GANTT_WIDGET_STATE,
    GanttWidgetClass,
    [],
  );

  return (
    <div
      id={props.id}
      className={clsx("position-relative h-100", {
        "d-none": !ganttWidget.state.displayed,
        "d-block": ganttWidget.state.displayed,
      })}
    >
      <CurrentWidgetInfo widget={ganttWidget} />
      <Gantt
        payload={ganttWidget.state.data}
        handleCustomAction={ganttWidget.handleCustomAction}
        valueBarClick={ganttWidget.valueBarClick}
        valueBarDblClick={ganttWidget.valueBarDblClick}
        rowClick={ganttWidget.rowClick}
        rowDblClick={ganttWidget.rowDblClick}
        showParentWhenExpanded={ganttWidget.showParentWhenExpanded}
      />
    </div>
  );
};

export default GanttWidget;
