import { FC, useCallback } from "react";

import RemixIcon from "components/RemixIcon";
import { AppixButton, AppixSelect, AppixSelectOnChange, AppixTextField } from "ui";

import KeyValueSub from "./KeyValueSub";

import { KeyValueDataItem, KeyValueProps } from "./types";
import { getDefaultDataValues } from "utils";
import clsx from "clsx";

interface KeyValueItemProps extends KeyValueProps {
  keyValue: string;
  valueValue: string;
  index: number;
  onChange: (nextValue: Partial<KeyValueDataItem>) => void;
  onRemove: () => void;
}

const KeyValueItem: FC<KeyValueItemProps> = ({
  id,
  keyValue,
  valueValue,
  keyField,
  valueField,
  subFields,
  size,
  index,
  onChange,
  onRemove,
  ctx,
  dynamicProperties,
  formData,
  dependencies,
}) => {
  const handleKeySelectChange = useCallback<AppixSelectOnChange>(
    (nextValue) => {
      const newValue = subFields ? getDefaultDataValues({}, subFields) : "";
      onChange({
        key: nextValue !== undefined ? String(nextValue) : "",
        value: newValue,
      });
    },
    [onChange, subFields],
  );

  const handleKeyTextfieldChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ key: e.target.value }),
    [onChange],
  );

  const handleValueSelectChange = useCallback<AppixSelectOnChange>(
    (nextValue) =>
      onChange({
        value: nextValue !== undefined ? String(nextValue) : "",
      }),
    [onChange],
  );

  const handleValueTextfieldChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange({ value: e.target.value }),
    [onChange],
  );

  const handleValueSubChange = useCallback<(nextValue: any) => void>(
    (nextValue) => {
      // Если nextValue пришло null и это вложенная форма - это означает,
      // что нужно сбросить значения в дефолт
      if (nextValue === null && subFields) {
        onChange({ value: getDefaultDataValues({}, subFields) });
      } else onChange({ value: nextValue });
    },
    [onChange, subFields],
  );

  return (
    <div className="appix-form-keyvalue__item">
      <div
        className={clsx("appix-form-keyvalue__key", {
          simple: !subFields?.length,
        })}
      >
        {keyField?.options?.length ? (
          <AppixSelect
            id={`${id}key${index}`}
            options={keyField.options}
            value={keyValue}
            placeholder={"Ключ"}
            onChange={handleKeySelectChange}
          />
        ) : (
          <AppixTextField
            id={`${id}key${index}`}
            placeholder={"Ключ"}
            value={keyValue}
            onChange={handleKeyTextfieldChange}
          />
        )}
      </div>

      {!subFields?.length && (
        <span className="appix-form-keyvalue__arrow">
          <RemixIcon icon="arrow-right-s" />
        </span>
      )}

      <div
        className={clsx("appix-form-keyvalue__value", {
          simple: !subFields?.length,
        })}
      >
        {subFields?.length ? (
          <KeyValueSub
            id={`${id}value${index}`}
            fields={subFields}
            size={size}
            data={valueValue || {}}
            onChange={handleValueSubChange}
            disabled={!keyValue}
            ctx={ctx}
            keyValue={keyValue}
            dynamicProperties={dynamicProperties}
            formData={formData}
            dependencies={dependencies}
          />
        ) : valueField?.options ? (
          <AppixSelect
            id={`${id}value${index}`}
            options={valueField.options}
            value={valueValue}
            placeholder={"Значение"}
            onChange={handleValueSelectChange}
            disabled={!keyValue}
          />
        ) : (
          <AppixTextField
            id={`${id}value${index}`}
            placeholder={"Значение"}
            value={typeof valueValue !== "object" ? valueValue : ""}
            onChange={handleValueTextfieldChange}
            disabled={!keyValue}
          />
        )}
      </div>

      <div className="appix-form-keyvalue__button">
        <AppixButton
          id={`${id}X${index}`}
          size={size}
          variant={"icon"}
          color={"secondary"}
          onClick={onRemove}
        >
          <RemixIcon icon="delete-bin-6" />
        </AppixButton>
      </div>
    </div>
  );
};

export default KeyValueItem;
