import React, { useCallback, useMemo, useState, useEffect } from "react";
import clsx from "clsx";

import { AppixSize, AppixValidation } from "../types";
import { AppixBaseInputProps } from "./types";
import Appix from "Appix";

export interface AppixTextAreaProps
  extends React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    AppixBaseInputProps {
  label?: string;
  sizing?: keyof typeof AppixSize;
  validation?: keyof typeof AppixValidation;
  helper?: string;
  isRequired?: boolean;
  copy?: boolean;
}

export const AppixTextArea: React.FC<AppixTextAreaProps> = ({
  id,
  className,
  label,
  sizing = "md",
  validation,
  helper,
  isRequired,
  copy,
  value,
  onChange,
  ...props
}) => {
  // делаем инпут контролируемым, чтоб в любой ситуации он не висел "в воздухе"
  const [inputValue, setInputValue] = useState<string | number>("");

  useEffect(() => {
    if (typeof value === "string" || typeof value === "number") {
      setInputValue(value);
    } else setInputValue("");
  }, [value]);

  const draftId = useMemo<string>(() => {
    return id || `input-${Math.random()}`;
  }, [id]);

  const handleCopy = useCallback(
    (e) => {
      e.preventDefault();
      inputValue &&
        navigator.clipboard
          .writeText(`${inputValue}`)
          .then(() => {
            Appix.showSuccess("Текст скопирован в буфер обмена!", true);
          })
          .catch((e) => {
            Appix.showError("Не удалось скопировать текст!", true);
          });
    },
    [inputValue],
  );

  const handleChange = useCallback(
    (e) => {
      setInputValue(e.target.value);
      onChange && onChange(e);
    },
    [onChange],
  );

  return (
    <div className="input-group">
      <textarea
        {...props}
        onChange={handleChange}
        value={inputValue}
        id={draftId}
        className={
          className
            ? className
            : clsx("form-control", {
                "form-control-sm": sizing === AppixSize.sm,
                "form-control-lg": sizing === AppixSize.lg,
                "is-valid": validation === AppixValidation.success,
                "is-warning": validation === AppixValidation.warning,
                "is-invalid": validation === AppixValidation.error,
              })
        }
      />
      {copy && (
        <button className="input-group-text" onClick={handleCopy}>
          <i className="ri-file-copy-line"></i>
        </button>
      )}
    </div>
  );
};
