import { AppixTreeNodeState } from "../types";

type Node<RAW> = AppixTreeNodeState<RAW>;
type NodesList<RAW> = AppixTreeNodeState<RAW>[];

export function tree2list<T extends { children?: T[] }>(tree: T[], currentDepth = 0): T[] {
  const list: T[] = [];

  tree.forEach((item) => {
    list.push(Object.assign(item, { depth: currentDepth }));

    if (item.children) {
      list.push(...tree2list(item.children, currentDepth + 1));
    }
  });

  return list;
}

export const getNodesFromKeys = <RAW>(
  nodesList: NodesList<RAW>,
  checkedKeys: string[],
): NodesList<RAW> => nodesList.filter(({ key }) => checkedKeys.includes(key));

export const getOnlyRootNodes = <RAW>(list: NodesList<RAW>): NodesList<RAW> => {
  // копия списка нужна для независимой фильтрации
  const draftList = [...list];
  // отфильтруем, чтобы остались только корневые
  // важно учитывать, что parentKey может быть не пустым
  // однако, элемента с таким key может не существовать
  return list.filter(
    // если родитель найден не был, значит он не выбран был
    (item) => !draftList.some(({ key }) => key === item.parentKey),
  );
};

function refreshParentsCheck<RAW>({
  node,
  nodesList,
  allCheckedKeys,
}: {
  node: Node<RAW>;
  nodesList: NodesList<RAW>;
  allCheckedKeys: string[];
}) {
  const parent = nodesList.find(({ key }) => key === node.parentKey);

  if (parent) {
    const neighbours = nodesList.filter(({ parentKey }) => parentKey === node.parentKey);
    const checkedNeighbours = neighbours.filter(({ key }) => allCheckedKeys.includes(key));

    if (neighbours.length === checkedNeighbours.length) {
      if (!allCheckedKeys.includes(parent.key)) {
        allCheckedKeys.push(parent.key);
      }
    } else {
      const index = allCheckedKeys.indexOf(parent.key);
      if (index !== -1) {
        allCheckedKeys.splice(index, 1);
      }
    }

    refreshParentsCheck({
      node: parent,
      nodesList,
      allCheckedKeys,
    });
  }
}

function refreshChildrenCheck<RAW>({
  node,
  actionType,
  allCheckedKeys,
}: {
  node: Node<RAW>;
  actionType: "check" | "uncheck";
  allCheckedKeys: string[];
}) {
  if (node.children?.length) {
    node.children.forEach((child) => {
      if (child.hidden) return;

      switch (actionType) {
        case "check": {
          if (!allCheckedKeys.includes(child.key)) {
            allCheckedKeys.push(child.key);
          }
          break;
        }
        case "uncheck": {
          const index = allCheckedKeys.indexOf(child.key);
          if (index !== -1) {
            allCheckedKeys.splice(index, 1);
          }
          break;
        }
        default:
      }

      refreshChildrenCheck({
        node: child,
        actionType,
        allCheckedKeys,
      });
    });
  }
}

export function getNextAllChecked<RAW>({
  keys,
  actionType,
  nodesList,
  allCheckedKeys,
}: {
  keys: string[];
  actionType: "check" | "uncheck";
  nodesList: NodesList<RAW>;
  allCheckedKeys: string[];
}) {
  const nextCheckedKeys = [...allCheckedKeys];

  keys.forEach((nodeKey) => {
    const node = nodesList.find(({ key }) => key === nodeKey);
    if (!node || node.hidden) return;

    switch (actionType) {
      case "check": {
        if (!nextCheckedKeys.includes(nodeKey)) {
          nextCheckedKeys.push(nodeKey);
        }
        break;
      }
      case "uncheck": {
        const index = nextCheckedKeys.indexOf(nodeKey);
        if (index !== -1) {
          nextCheckedKeys.splice(index, 1);
        }
        break;
      }
      default:
    }

    refreshChildrenCheck({
      node,
      actionType,
      allCheckedKeys: nextCheckedKeys,
    });

    refreshParentsCheck({
      node,
      nodesList,
      allCheckedKeys: nextCheckedKeys,
    });
  });

  return nextCheckedKeys;
}

export function getHalfOnFromChecked<RAW>({
  allCheckedKeys,
  nodesList,
}: {
  allCheckedKeys: string[];
  nodesList: NodesList<RAW>;
}) {
  const nextHalfOnKeys: string[] = [];

  const refreshParentsHalfOn = ({ node }: { node: Node<RAW> }): void => {
    const parent = nodesList.find(({ key }) => key === node.parentKey);

    if (parent && !allCheckedKeys.includes(parent.key)) {
      const neighbours = nodesList.filter(({ parentKey }) => parentKey === parent.key);

      if (
        neighbours.some(({ key }) => allCheckedKeys.includes(key)) ||
        neighbours.some(({ key }) => nextHalfOnKeys.includes(key))
      ) {
        !nextHalfOnKeys.includes(parent.key) && nextHalfOnKeys.push(parent.key);

        refreshParentsHalfOn({
          node: parent,
        });
      }
    }
  };

  allCheckedKeys.forEach((checkedKey) => {
    const node = nodesList.find(({ key }) => key === checkedKey);
    if (!node || node.hidden) return;

    refreshParentsHalfOn({ node });
  });

  return nextHalfOnKeys;
}
