import {
  FC,
  MouseEventHandler,
  ChangeEventHandler,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";

import clsx from "clsx";

import { AppixTextField, AppixTree, AppixTreeLabelComponent, AppixTreeNodeState } from "ui";
import RemixIcon from "components/RemixIcon";

interface ChainMenuProps {
  id: string;
  nodes: AppixWidget.Chain.Node[];
  onNodeSelect: (node: AppixWidget.Chain.Node) => MouseEventHandler<HTMLDivElement>;
}

const ChainMenu: FC<ChainMenuProps> = ({ id, nodes, onNodeSelect }) => {
  const [searchStr, setSearchStr] = useState<string>("");

  const [draftNodes, setDraftNodes] = useState<AppixWidget.Chain.Node[]>([]);

  const groupedSortedNodes = useMemo<AppixTreeNodeState<AppixWidget.Chain.Node>[]>(() => {
    const nextNodes = [...draftNodes];

    const groupedNodes: Record<string, AppixWidget.Chain.Node[]> = {};
    nextNodes.sort((a, b) => (a.category > b.category ? 1 : -1));
    nextNodes.forEach((node) => {
      if (!groupedNodes[node.category]) groupedNodes[node.category] = [];
      groupedNodes[node.category].push(node);
    });

    return Object.keys(groupedNodes).map((key) => {
      const nodes = [...groupedNodes[key]];
      nodes.sort((a, b) => (a.title > b.title ? 1 : -1));
      return {
        key,
        parentKey: "",
        label: key,
        value: key,
        children: nodes.map((node) => ({
          key: key + node.title,
          parentKey: key,
          label: node.title,
          value: node.title,
          raw: node,
        })),
      };
    });
  }, [draftNodes]);

  const handleSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setSearchStr(e.target.value);
  }, []);

  const labelComponent = useCallback<AppixTreeLabelComponent<AppixWidget.Chain.Node>>(
    ({ node, depth }) => (
      <div
        className={clsx({ "chain-menu__item": !!depth })}
        onMouseDown={node.raw ? onNodeSelect(node.raw) : undefined}
      >
        {node.label}
      </div>
    ),
    [onNodeSelect],
  );

  useEffect(() => {
    setDraftNodes(nodes.filter((node) => node.title.includes(searchStr)));
  }, [searchStr, nodes]);

  useEffect(() => {
    setDraftNodes(nodes);
  }, [nodes]);

  return (
    <div className="chain-menu">
      <div className="chain-menu__title">Основные элементы</div>

      <div className="chain-menu__search">
        <AppixTextField placeholder={"Поиск"} value={searchStr} onChange={handleSearchChange} />
        <div className="chain-menu__search__icon">
          <RemixIcon icon="search" />
        </div>
      </div>

      <div className="chain-menu__nav">
        <AppixTree<AppixWidget.Chain.Node>
          id={`${id}TREE`}
          tree={groupedSortedNodes}
          labelComponent={labelComponent}
        />
      </div>
    </div>
  );
};

export default ChainMenu;
