import { IAppixSelectItem } from "ui";

import React, { useCallback } from "react";
import clsx from "clsx";

interface InlineSelectInputProps {
  onChange: (e: { target: { value: any } }) => void;
  disabled?: boolean;
  id: string;
  value: string;
  options?: IAppixSelectItem[];
}

const InlineSelectInput: React.FC<InlineSelectInputProps> = ({
  options,
  onChange,
  value,
  id,
  disabled,
}) => {
  const handleChange = useCallback(
    (value) => () => {
      if (!disabled) {
        onChange({ target: { value } });
      }
    },
    [disabled, onChange],
  );

  return (
    <div id={id} className="appix-inline-select">
      {options?.map((option, index) => (
        <div
          key={index}
          onClick={handleChange(option.value)}
          className={clsx("appix-inline-select__item", {
            active: value === option.value,
          })}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default InlineSelectInput;
