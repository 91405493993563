import { FC, useCallback, useMemo, useState } from "react";

import { ChainWidgetClass, INITIAL_CHAIN_WIDGET_STATE } from "@appix/core";

import { AppixTooltip, AppixModal, ClickAwayListener } from "ui";

import { useWidget, getColor } from "utils";

import { Confirm } from "components";
import RemixIcon from "components/RemixIcon";

import FormWidget from "../FormWidget";

import { useChainEditor } from "./useChainEditor";
import { widgetNodeToEditorNode } from "./widgetNodeToEditorNode";

import { NodeContextMenu } from "./NodeContextMenu";
import ChainMenu from "./ChainMenu";
import ChainCustomContent from "./ChainCustomContent";
import ChainConnectionEditModal from "./ChainConnectionEditModal";

const ChainWidget: FC<AppixWidget.Chain> = (props) => {
  const chainWidget = useWidget<AppixWidget.Chain, ChainWidgetClass>(
    props,
    INITIAL_CHAIN_WIDGET_STATE,
    ChainWidgetClass,
  );

  const { nodes, libNodes, shortCutsCategory } = chainWidget.state;

  const [editorRef, setEditorRef] = useState<HTMLDivElement | null>(null);

  const [selectedNode, setSelectedNode] = useState<AppixWidget.Chain.Node | null>(null);
  const [contextNode, setContextNode] = useState<AppixWidget.Chain.Node | null>(null);

  const [changedData, setChangedData] = useState<{
    node: AppixWidget.Chain.Node;
    connection: AppixWidget.Chain.Connection;
  }>();

  const handleConnEditModalClose = useCallback(() => setChangedData(undefined), []);

  const [contextPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const shortsCutsNodes = useMemo<AppixWidget.Chain.Node[]>(
    () => (libNodes ? libNodes.filter(({ category }) => category === shortCutsCategory) : []),
    [libNodes, shortCutsCategory],
  );

  const handleCloseDialog = useCallback(() => {
    setSelectedNode(null);
  }, []);

  const handleSetNodeData = useCallback(
    async (nodeId: string | null, data: AppixWidget.Chain.Node) => {
      if (!nodeId) return;
      const nextNodes = [...nodes].map((node) => (node.id === nodeId ? data : node));
      await chainWidget.changeNodes(nextNodes);
      setChangedData(undefined);
      setSelectedNode(null);
    },
    [nodes, chainWidget],
  );

  const handleClickAwayContextMenu = useCallback(() => {
    setContextNode(null);
    setContextMenuPosition(null);
  }, []);

  const handleSelectAction = useCallback(
    (node: AppixWidget.Chain.Node, action: string) => {
      chainWidget.onActionSelect(node, action);
      setContextNode(null);
      setContextMenuPosition(null);
    },
    [chainWidget],
  );

  const onNodeDelete = useCallback(
    (node: AppixWidget.Chain.Node) => {
      chainWidget.deleteNode(node);
    },
    [chainWidget],
  );

  const onNodeRightClick = useCallback((node: AppixWidget.Chain.Node, e: MouseEvent) => {
    setContextNode(node);
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
  }, []);

  const onNodeSetting = useCallback((node: AppixWidget.Chain.Node) => {
    node.formWidget && setSelectedNode(node);
  }, []);

  const onNodesChange = useCallback(
    (nodes: AppixWidget.Chain.Node[]) => {
      chainWidget.changeNodes(nodes);
    },
    [chainWidget],
  );

  const onReservedSlotClick = useCallback<
    (a: { node: AppixWidget.Chain.Node; connection: AppixWidget.Chain.Connection }) => void
  >(({ node, connection }) => {
    setChangedData({ node, connection });
  }, []);

  const editor = useChainEditor(
    editorRef,
    {
      id: chainWidget.id,
      readOnly: chainWidget.state.readOnly,
      nodes,
    },
    {
      onNodeDelete,
      onNodeRightClick,
      onNodeSetting,
      onNodesChange,
      onReservedSlotClick,
    },
  );

  const onMouseDownLibNode = useCallback(
    (node: AppixWidget.Chain.Node) => (e: React.MouseEvent) => {
      editor?.handlePaletteMouseDown(widgetNodeToEditorNode(node), e.nativeEvent);
    },
    [editor],
  );

  const onZoom = useCallback(
    (a: boolean) => () => {
      editor?.handleZoom(a);
    },
    [editor],
  );

  return (
    <>
      <div className="chain-widget">
        {chainWidget.state.libNodes?.length ? (
          <div className="chain-widget__left">
            <ChainMenu
              id={props.id}
              nodes={chainWidget.state.libNodes.filter(
                ({ category }) => category !== shortCutsCategory,
              )}
              onNodeSelect={onMouseDownLibNode}
            />
          </div>
        ) : null}

        <div className="chain-widget__right">
          {shortCutsCategory ? (
            <div className="chain-widget__palette">
              {shortsCutsNodes.map((node, index) => (
                <AppixTooltip key={index} label={node.title} placement="right">
                  <button
                    type="button"
                    className="chain-widget__palette__btn"
                    onMouseDown={onMouseDownLibNode(node)}
                    style={{ color: getColor(node.color) }}
                  >
                    <RemixIcon icon={node.ico} />
                  </button>
                </AppixTooltip>
              ))}
            </div>
          ) : null}

          <div className="chain-widget__zoom">
            <button className="chain-widget__zoom__btn" type="button" onClick={onZoom(true)}>
              <RemixIcon icon="add" />
            </button>
            <button className="chain-widget__zoom__btn" type="button" onClick={onZoom(false)}>
              <RemixIcon icon="subtract" />
            </button>
          </div>

          <div
            // eslint-disable-next-line
            ref={(ref) => setEditorRef(ref)}
            className="chain-widget__canvas"
          ></div>
        </div>
      </div>

      {contextNode?.customActions && (
        <ClickAwayListener onClickAway={handleClickAwayContextMenu}>
          <div>
            <NodeContextMenu
              node={contextNode}
              position={contextPosition}
              onActionSelect={handleSelectAction}
            ></NodeContextMenu>
          </div>
        </ClickAwayListener>
      )}

      {selectedNode ? (
        <AppixModal
          id={`${props.id}NODE_EDIT_MODAL`}
          isShown={!!selectedNode}
          type="MODAL"
          size={null}
          onClose={handleCloseDialog}
          caption={selectedNode.title ?? "Редактирование ноды"}
        >
          <FormWidget
            {...selectedNode?.formWidget}
            id={`${props.id}-editForm`}
            form={{
              ...selectedNode?.formWidget?.form,
              actions: [
                {
                  text: "Применить",
                  event: "nodeChanged",
                  postUrl: "",
                  customAction: (data) =>
                    handleSetNodeData(selectedNode.id, {
                      ...selectedNode,
                      data,
                    }),
                },
              ],
              dataDefaults: {
                ...selectedNode?.formWidget?.form?.dataDefaults,
                ...selectedNode.data,
              },
            }}
            data={selectedNode.data}
          />
        </AppixModal>
      ) : null}

      {changedData?.connection?.details?.formWidget ? (
        <ChainConnectionEditModal
          id={`${props.id}CONNECTION_EDIT`}
          node={changedData.node}
          connection={changedData.connection}
          isShown={!!changedData}
          onClose={handleConnEditModalClose}
          onSubmit={handleSetNodeData}
        />
      ) : null}

      {editor ? <Confirm id={props.id} dialogTitle={"Удаление объекта"} widget={editor} /> : null}

      {editor ? <ChainCustomContent nodes={nodes} /> : null}
    </>
  );
};

export default ChainWidget;
