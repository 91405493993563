import React, { useCallback } from "react";
import AppixSelectOption from "./AppixSelectOption";
import {
  AppixSelectOnTreeChange,
  AppixSelectProps,
  AppixSelectValue,
  IAppixSelectItem,
} from "./types";
import { TableWidget, TreetableWidget } from "widgets";
import clsx from "clsx";
import { AppixCheckbox } from "ui/AppixCheckbox";
import { AppixTextField } from "ui/AppixFormControl/AppixTextField";

interface DropdownProps {
  selectOptions: AppixSelectProps["options"];
  withSearch?: boolean;
  withBadges?: boolean;
  handleChange: (value: string | number) => void;
  searchValue: string | null;
  setSearchValue: (value: string) => void;
  selectedItem?: IAppixSelectItem;
  isSmall?: boolean;
  tableUrl?: string;
  treeData?: AppixWidget.Table["table"];
  tableData?: AppixWidget.Table["table"];
  id: string;
  onChange?: AppixSelectOnTreeChange;
  className?: string;
  value?: IAppixSelectItem | AppixSelectValue | IAppixSelectItem[];
  handleSearch?: (e: Record<string, any>) => void;
}

const DropdownContent: React.FC<DropdownProps> = ({
  selectOptions,
  withSearch,
  withBadges,
  handleChange,
  searchValue,
  setSearchValue,
  selectedItem,
  isSmall,
  treeData,
  tableData = null,
  id,
  onChange,
  className,
  value,
  handleSearch,
}) => {
  const notTableNotTree = !treeData && !tableData;

  const onTreetableChange = useCallback(() => {}, []);

  const onTableChange = useCallback(() => {}, []);

  return (
    <div id={`${id}DROPDOWN`} className={clsx("form-select__dropdown", className)}>
      {treeData && (
        <TreetableWidget
          id={id}
          table={treeData}
          onChange={onTreetableChange}
          showCaption={false}
        />
      )}
      {tableData && (
        <TableWidget id={id} table={tableData} onChange={onTableChange} showCaption={false} />
      )}

      {notTableNotTree && withBadges && (
        <div className="dropdown-select__search">
          <AppixTextField
            placeholder="Поиск"
            value={searchValue || ""}
            // eslint-disable-next-line
            onChange={(e) =>
              withSearch && handleSearch ? handleSearch(e) : setSearchValue(e.target.value)
            }
          />

          {selectOptions?.map((item) => {
            if (
              withSearch &&
              withBadges &&
              !item.label?.toLowerCase().includes(searchValue?.toLowerCase() || "")
            ) {
              return null;
            }

            return (
              <div key={item.value} className="dropdown-select__content-item">
                <AppixCheckbox
                  label={item.label}
                  // нестрогое сравнение потому что у нас в селектах value всегда строка,
                  // но бэк считает, что там может быть число
                  // eslint-disable-next-line eqeqeq
                  checked={value?.find((value) => value == item.value)}
                  // eslint-disable-next-line
                  onClick={() => handleChange(item.value)}
                />
              </div>
            );
          })}
        </div>
      )}
      {notTableNotTree &&
        !withBadges &&
        selectOptions?.map((item, index) => {
          if (
            withSearch &&
            withBadges &&
            !item.label?.toLowerCase().includes(searchValue?.toLowerCase() || "")
          ) {
            return null;
          }
          return (
            <AppixSelectOption
              key={index}
              {...item}
              onChange={handleChange}
              isActive={selectedItem?.value === item.value}
              isSmall={isSmall}
            />
          );
        })}
      {notTableNotTree && selectOptions?.length === 0 && (
        <AppixSelectOption isEmpty={true} value={""} isSmall={isSmall} />
      )}
    </div>
  );
};

export default React.memo((props: DropdownProps) => <DropdownContent {...props} />);
