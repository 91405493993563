import React, { useState, useCallback, FC } from "react";
import { AppixButton, AppixTagsRow, AppixTextField } from "ui";
import clsx from "clsx";
import { TagsWidgetClass, INITIAL_TAGS_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const TagsfilterWidget: FC<AppixWidget.TagsRow> = (props) => {
  const [tagValue, setTagvalue] = useState<string>("");

  const tagsWidget = useWidget<AppixWidget.TagsRow, TagsWidgetClass>(
    props,
    INITIAL_TAGS_WIDGET_STATE,
    TagsWidgetClass,
    [props.crud, props.url],
  );

  const handleChangeInput = useCallback((e) => {
    setTagvalue(e.target.value);
  }, []);

  const addTagHandler = useCallback(() => {
    tagsWidget.addTag([tagValue, tagValue]);
    setTagvalue("");
  }, [tagValue, tagsWidget]);

  const canAdd = tagsWidget.state.crud.includes("ADD");
  return (
    <div
      className={clsx("position-relative", {
        "d-none": !tagsWidget.state.displayed,
        "d-block": tagsWidget.state.displayed,
      })}
    >
      <CurrentWidgetInfo widget={tagsWidget} />

      {tagsWidget.state.isLoading && <Spinner />}
      {!tagsWidget.state.isLoading && (
        <>
          <div>
            <AppixTagsRow
              id={props.id}
              tags={tagsWidget.state.tags.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              onRemoveTag={tagsWidget.removeTagByIndex}
              removable={tagsWidget.state.crud.includes("DELETE")}
              readOnly={true}
            />
          </div>
          {canAdd && (
            <div className="mt-2">
              <AppixTextField id={props.id} onChange={handleChangeInput} value={tagValue} />

              <div className="mt-2">
                <AppixButton
                  id={props.id + "buttonADD_TAG"}
                  title="Добавить тег"
                  size={"sm"}
                  color={"primary"}
                  onClick={addTagHandler}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TagsfilterWidget;
