export enum AppixSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
}

export enum AppixValidation {
  success = "success",
  warning = "warning",
  error = "error",
}
