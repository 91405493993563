import React from "react";

interface TableHeaderProps {
  header: AppixWidget.TableText.Header;
}

const TableHeader: React.FC<TableHeaderProps> = ({ header }) => {
  return (
    <thead>
      <tr>
        {header.elements.map((item, index) => {
          const alignment: any = header.alignment[index].toLowerCase();
          return (
            <th
              key={index}
              style={{
                textAlign: alignment,
              }}
            >
              {item}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
