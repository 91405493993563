import { produce } from "immer";
import { Dispatch, Reducer, useReducer } from "react";

type StateReducer<T> = Reducer<Partial<T>, Partial<T>>;
type StateTuple<T> = [Partial<T>, Dispatch<Partial<T>>];

export default function usePartialState<T>(initialState: T): StateTuple<T> {
  return useReducer<StateReducer<T>>((state, nextState) => {
    return produce(state, (draft) => Object.assign(draft, nextState));
  }, initialState);
}
