import { Fragment, FC, useState, useEffect, useCallback, useRef, useLayoutEffect } from "react";
import RemixIcon from "components/RemixIcon";
import { useResizeDetector } from "react-resize-detector";
import "./AppixGantt.scss";
import clsx from "clsx";

const HEIGHT_OF_LINE = 36;

type contextMenuType = {
  position: {
    x: number;
    y: number;
  };
  content: AppixWidget.CustomAction[];
  valueBar: AppixWidget.Gantt.GanttElement;
};

type appixGanttProps = {
  payload: any;
  className?: string;
  valueBarClick: (valueBar: AppixWidget.Gantt.GanttElement) => void;
  valueBarDblClick: (valueBar: AppixWidget.Gantt.GanttElement) => void;
  rowClick: (row: AppixWidget.Gantt.GanttItem) => void;
  rowDblClick: (row: AppixWidget.Gantt.GanttItem) => void;
  showParentWhenExpanded?: boolean;
  handleCustomAction: (
    action: AppixWidget.CustomAction,
    valueBar: AppixWidget.Gantt.GanttElement,
  ) => void;
};

const AppixGantt: FC<appixGanttProps> = ({
  payload = null,
  className = "",
  handleCustomAction,
  valueBarClick,
  valueBarDblClick,
  rowClick,
  rowDblClick,
  showParentWhenExpanded,
}) => {
  const [openedNodeIds, setOpenedNodeIds] = useState<Record<string, boolean>>({});
  const [heightOfLines, setHeightOfLines] = useState(140);

  const [contextMenu, setContextMenu] = useState<null | contextMenuType>(null);

  const contextMenuRef = useRef<HTMLDivElement>(null);
  const rightHeaderRef = useRef<HTMLDivElement>(null);
  const rightColumnsRef = useRef<HTMLDivElement>(null);

  const [columnWidth, setColumnWidth] = useState(0);

  const { width, height, ref } = useResizeDetector();

  useEffect(() => {
    if (payload && rightHeaderRef?.current && rightColumnsRef?.current) {
      const elemSet = rightHeaderRef.current.querySelectorAll(".column-header");

      elemSet.forEach((item) => {
        item.setAttribute("style", "width:auto");
      });

      let maxVal = 0;

      elemSet.forEach((item) => {
        const bounds = item.getBoundingClientRect();
        if (bounds.width > maxVal) maxVal = bounds.width;
      });

      setColumnWidth(maxVal);

      elemSet.forEach((item) => {
        item.setAttribute("style", `width:${maxVal}px`);
      });

      rightColumnsRef.current.querySelectorAll(".column").forEach((item) => {
        item.setAttribute("style", `width:${maxVal}px`);
      });
    }
  }, [payload, rightHeaderRef, rightColumnsRef, width, height, ref]);

  useEffect(() => {
    if (payload) {
      const newOpenedNodes = {};
      const getInner = (list) => {
        list.forEach((node) => {
          if (node.meta.isExpanded) newOpenedNodes[node.id] = true;
          if (node.children?.length > 0) getInner(node.children);
        });
      };
      getInner(payload.data);
      setOpenedNodeIds(newOpenedNodes);
    } else {
      setOpenedNodeIds({});
    }
  }, [payload]);

  useEffect(() => {
    if (payload) {
      const getNumberOfOpenedNodes = (list) => {
        let opened = 0;
        list.forEach((node) => {
          opened++;
          if (node.children?.length > 0 && openedNodeIds[node.id])
            opened += getNumberOfOpenedNodes(node.children);
        });
        return opened;
      };
      setHeightOfLines(getNumberOfOpenedNodes(payload.data) * HEIGHT_OF_LINE);
    } else {
      setHeightOfLines(HEIGHT_OF_LINE);
    }
  }, [payload, openedNodeIds]);

  const handleClickOnChevron = (nodeId) => {
    const newIds = { ...openedNodeIds };
    if (newIds[nodeId]) delete newIds[nodeId];
    else newIds[nodeId] = true;
    setOpenedNodeIds(newIds);
  };

  const hookForClicks = useCallback((e) => {
    const elem = contextMenuRef.current ? contextMenuRef.current : null;
    if (!elem?.contains(e.target)) setContextMenu(null);
  }, []);

  const hookForScroll = useCallback(() => {
    setContextMenu(null);
  }, []);

  useEffect(
    () => () => {
      document.removeEventListener("mousedown", hookForClicks);
      window.removeEventListener("scroll", hookForScroll, true);
    },
    [hookForClicks, hookForScroll],
  );

  const handleContextMenuOnValueBar = (event, valueBar) => {
    event.preventDefault();
    if (valueBar.meta.actions?.length === 0) return;
    document.addEventListener("mousedown", hookForClicks);
    window.addEventListener("scroll", hookForScroll, true);
    setContextMenu({
      position: {
        x: event.clientX,
        y: event.clientY,
      },
      content: valueBar.meta.actions,
      valueBar: valueBar,
    });
  };

  const handleCustomActionClick = (action: AppixWidget.CustomAction) => {
    contextMenu && handleCustomAction(action, contextMenu.valueBar);
    setContextMenu(null);
  };

  useLayoutEffect(() => {
    if (contextMenu && contextMenuRef.current) {
      const elem = (contextMenuRef.current as HTMLElement).querySelector(".action-list");
      if (elem) {
        const elemBounds = elem.getBoundingClientRect();
        if (contextMenu.position.y + elemBounds.height + 10 > window.innerHeight)
          elem.classList.add("toTop");
        if (contextMenu.position.x + elemBounds.width + 10 > window.innerWidth)
          elem.classList.add("toLeft");
      }
    }
  }, [contextMenu]);

  const drawTree = (nodeList, level = 0) => {
    return nodeList.map((node) => {
      const hasChildren = node.children?.length > 0;
      return (
        <Fragment key={node.id}>
          <div
            style={{ paddingLeft: 36 * level }}
            className={clsx("tree-node", {
              [`bg-${node.meta.coloring.color?.toLowerCase()}`]: node.meta?.coloring?.color,
              clickable: rowClick || rowDblClick,
            })}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => rowClick && rowClick(node)}
            // eslint-disable-next-line react/jsx-no-bind
            onDoubleClick={() => rowDblClick && rowDblClick(node)}
          >
            {hasChildren && (
              <div
                // eslint-disable-next-line react/jsx-no-bind
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickOnChevron(node.id);
                }}
                className={clsx("expanding-chevron", {
                  opened: openedNodeIds[node.id],
                })}
              />
            )}
            <div
              className={clsx("node-name", {
                "no-children": !hasChildren,
                toplevel: level === 0,
              })}
            >
              {node.meta.iconing?.icons?.map((icon, index) => (
                <RemixIcon key={index} icon={icon} />
              ))}
              {node.name}
            </div>
          </div>
          {hasChildren && openedNodeIds[node.id] && (
            <div className="node-children">{drawTree(node.children, level + 1)}</div>
          )}
        </Fragment>
      );
    });
  };

  const drawLines = (nodeList, level = 0) => {
    return nodeList.map((node) => {
      return (
        <Fragment key={node.id}>
          <div
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => rowClick && rowClick(node)}
            // eslint-disable-next-line react/jsx-no-bind
            onDoubleClick={() => rowDblClick && rowDblClick(node)}
            className="line-container"
          >
            {(node.children?.length === 0 || !openedNodeIds[node.id] || showParentWhenExpanded) &&
              node.elements?.map((valueBar) => {
                return (
                  <div
                    key={valueBar.id}
                    className={`valueBar`}
                    style={{
                      width:
                        (valueBar.meta.position.endIndex - valueBar.meta.position.startIndex + 1) *
                        columnWidth,
                      left: valueBar.meta.position.startIndex * columnWidth,
                    }}
                  >
                    <div
                      className={clsx("coloredLine", {
                        [`bg-${valueBar.meta.coloring.color?.toLowerCase()}`]:
                          valueBar.meta?.coloring?.color,
                        "bg-primary": !valueBar.meta?.coloring?.color,
                        clickable: valueBarClick || valueBarDblClick,
                      })}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={(e) => {
                        e.stopPropagation();
                        valueBarClick && valueBarClick(valueBar);
                      }}
                      // eslint-disable-next-line react/jsx-no-bind
                      onDoubleClick={(e) => {
                        e.stopPropagation();
                        valueBarDblClick && valueBarDblClick(valueBar);
                      }}
                      // eslint-disable-next-line react/jsx-no-bind
                      onContextMenu={(e) => {
                        handleContextMenuOnValueBar(e, valueBar);
                      }}
                    >
                      {valueBar.meta.iconing?.icons?.map((icon, index) => (
                        <RemixIcon key={index} icon={icon} />
                      ))}
                      {valueBar.name}
                    </div>
                  </div>
                );
              })}
          </div>
          {node.children?.length > 0 && openedNodeIds[node.id] && (
            <div className="line-children">{drawLines(node.children, level + 1)}</div>
          )}
        </Fragment>
      );
    });
  };

  return (
    <div ref={ref} className={`c-appixgantt h-100 ${className}`}>
      <div className="c-appixgantt__scroll">
        <div className="left" style={{ height: heightOfLines + HEIGHT_OF_LINE }}>
          <div className="sticky-header" />
          {payload?.data && drawTree(payload.data)}
        </div>

        <div className="right" style={{ height: heightOfLines + HEIGHT_OF_LINE }}>
          <div className="right-content">
            <div className="right-header" ref={rightHeaderRef}>
              {payload?.header?.map((item) => (
                <div key={item.id} className="column-header">
                  {item.name}
                </div>
              ))}
            </div>

            <div className="right-columns" style={{ height: heightOfLines }} ref={rightColumnsRef}>
              {payload?.header?.map((item) => (
                <div key={item.id} className={`column`} />
              ))}
            </div>

            <div className="right-lines">{payload?.data && drawLines(payload.data)}</div>
          </div>

          {contextMenu && (
            <div
              className="c-appixgantt__context-menu"
              style={{
                left: contextMenu.position.x,
                top: contextMenu.position.y,
              }}
              ref={contextMenuRef}
            >
              <div className="action-list">
                {contextMenu.content.map((action, index) => {
                  return (
                    <div
                      key={index}
                      className={clsx("action-item", {
                        [`text-${action.color?.toLocaleLowerCase()}`]: action.color,
                      })}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => handleCustomActionClick(action)}
                    >
                      {action.ico && <RemixIcon icon={action.ico} />}
                      {action.text}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppixGantt;
