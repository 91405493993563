import { AppixPage } from "@appix/core";
import React from "react";
import LayoutRow from "./LayoutRow";

interface LayoutContentProps {
  rows: Array<AppixPage.Container.Row>;
  id?: string;
  className?: string;
}

const LayoutContent: React.FC<LayoutContentProps> = ({ rows, id, className }) => {
  return (
    <div className={className}>
      {rows?.map((row, index) => (
        <LayoutRow key={index} {...row} id={id} />
      ))}
    </div>
  );
};

export default LayoutContent;
