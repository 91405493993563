import { FC, DetailedHTMLProps, InputHTMLAttributes } from "react";

import clsx from "clsx";

import AppixCheckboxInput from "./AppixCheckboxInput";

export type AppixCheckboxOnChange = (flag: boolean) => void;

export interface AppixCheckboxProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  indeterminate?: boolean;
  isRequired?: boolean;
  helper?: string;
  onlyCheckbox?: boolean;
  isToggler?: boolean;
  onCheckboxChange?: AppixCheckboxOnChange;
}

export const AppixCheckbox: FC<AppixCheckboxProps> = ({
  label,
  isRequired,
  helper,
  onlyCheckbox,
  isToggler,
  children,
  ...props
}) => {
  return (
    <div
      className={clsx("form-check", {
        "form-switch": isToggler,
        "form-check-only-checkbox": onlyCheckbox,
      })}
    >
      {onlyCheckbox ? (
        <AppixCheckboxInput {...props} />
      ) : (
        <label
          role="button"
          className={clsx("form-check-label", {
            "appix-required": isRequired,
          })}
        >
          <AppixCheckboxInput {...props} />
          {children || label}
        </label>
      )}
    </div>
  );
};
