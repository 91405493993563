import { FC, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppixNotification } from "@appix/core";

import { SecondaryMenu } from "components";
import RemixIcon from "components/RemixIcon";

import { IDataPage } from "containers/Router/Router";
import { AppixBreadcrumbs, ICrumb, AppixButton } from "ui";
import { ContainerWidget } from "widgets";

import Appix from "Appix";

import LayoutContent from "./LayoutContent";
import clsx from "clsx";

const Layout: FC<{
  pagetitle?: string;
  data?: IDataPage;
}> = ({ pagetitle, data }) => {
  const navigate = useNavigate();

  const [notification, setNotification] = useState<AppixNotification | null>(null);

  const isSubMenu = useMemo<boolean>(
    () => Boolean(data?.pageConfig?.layout?.secondaryMenu?.items?.length),
    [data],
  );

  const crumbs = useMemo<ICrumb[] | null>(
    () =>
      data?.pageConfig.layout?.breadcrumbs && data?.pageConfig.layout?.breadcrumbs.length > 1
        ? data.pageConfig.layout.breadcrumbs.map(({ title, fullpath, hasPage }) => ({
            label: title,
            value: fullpath,
            hasPage,
          }))
        : null,
    [data?.pageConfig?.layout?.breadcrumbs],
  );

  Appix.showNotification = useCallback((notification: AppixNotification) => {
    setNotification(notification);
  }, []);

  const onCloseNotification = useCallback(() => {
    setNotification(null);
  }, []);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleBreadcrumbClick = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate],
  );

  return (
    // основной контейнер
    <div className="main-container">
      {isSubMenu && (
        <div className="main-container__sub-menu">
          <SecondaryMenu
            items={data?.pageConfig?.layout?.secondaryMenu?.items}
            baseUrl={Appix.baseUrl}
          />
        </div>
      )}

      <div>
        <div
          className={clsx("main-container__header appix-header", {
            "appix-header_sticky": !!data?.pageConfig.controls,
          })}
        >
          {/* TODO: добавить в типы ядра */}
          {(data?.pageConfig as any)?.goBack ? (
            <div className="appix-header__before">
              <AppixButton color={"light"} variant={"icon"} onClick={goBack}>
                <RemixIcon icon={"arrow-left-s"} />
              </AppixButton>
            </div>
          ) : null}
          <div className="appix-header__body">
            {crumbs ? (
              <AppixBreadcrumbs
                baseUrl={Appix.baseUrl}
                crumbs={crumbs}
                onClick={handleBreadcrumbClick}
              />
            ) : null}
            <div className="appix-header__main">
              <h1 className="appix-header__title">{pagetitle || data?.pageConfig?.title}</h1>
              {data?.pageConfig.controls ? (
                <div className="appix-header__actions">
                  <ContainerWidget {...data.pageConfig.controls} />
                </div>
              ) : null}
            </div>
            {data?.pageConfig?.subtitle ? (
              <div className="appix-header__subtitle">{data.pageConfig.subtitle}</div>
            ) : null}
          </div>
        </div>

        {data?.pageConfig?.content && (
          <div className="row main-container__row">
            <div className="col-12">
              <LayoutContent rows={data?.pageConfig?.content?.rows} />
            </div>
          </div>
        )}
      </div>
      {notification && (
        <div
          className={`alert alert-${notification.notificationLevel} position-absolute d-flex align-items-center alert-dismissible fade show justify-content-around`}
          role="alert"
          style={{ top: "50px", right: 0 }}
        >
          <div className="p-2">
            <AppixButton id="closeNotification" title="&times;" onClick={onCloseNotification} />
          </div>
          <div className="mr-2">
            <h4 className="alert-heading">{notification.title}</h4>
            <p>{notification.body}</p>
            <hr />
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
