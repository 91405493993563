import { AppixErrorResponse, AppixPage } from "@appix/core";
import Appix from "Appix";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { collapseSlashes } from "utils";
import { Layout } from "containers";

export interface IDataPage {
  pageConfig: AppixPage.Result;
}

const Router: React.FC<any> = ({ init, sidebarCollapsed }) => {
  const location = useLocation();
  const [error, setError] = useState<AppixErrorResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<IDataPage>();

  const authToken = Appix.getAuthToken();

  const initialize = useCallback(async () => {
    setLoading(true);
    try {
      const result = await init();
      if (result) {
        setData({
          pageConfig: result,
        });
        Appix.setPageConfig(result);
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      setError(error);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    Appix.reloadData = initialize;
  }, [initialize]);

  // we will reset the error after switching to another page
  useEffect(() => {
    setError(null);
  }, [location]);

  useEffect(() => {
    initialize();
  }, [initialize, location.pathname, authToken]);

  useEffect(() => {
    return () => {
      Appix.wsDisconnect();
    };
  }, []);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ width: `calc(100% - ${sidebarCollapsed ? "73" : "261"}px)` }}
      >
        <div className="spinner-grow me-2" role="status"></div>
        <span>Загрузка...</span>
      </div>
    );
  }

  return (
    <div className="container-fluid main-container-wrapper">
      <Routes>
        <Route index element={<Navigate to={collapseSlashes(`${Appix.baseUrl}/content`)} />} />

        {data ? (
          <Route path="*" element={<Layout data={data} />} />
        ) : (
          error && (
            <Route
              path="*"
              element={
                <Layout
                  pagetitle={
                    error?.code === "404"
                      ? "404 Page Not Found"
                      : error?.code === "AccessDeniedException"
                      ? "403 Forbidden"
                      : "Something went wrong"
                  }
                />
              }
            />
          )
        )}
      </Routes>
    </div>
  );
};

export default Router;
