import React from "react";
import "echarts/i18n/langRU";
import "theme/components/_appix-charts.scss";

export const CustomLegend: React.FC<{
  data: any;
}> = ({ data }) => {
  if (!data) return null;
  else
    return (
      <div className="c-appix-charts__customlegend">
        {/* {[...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data].map( */}
        {data.map((legendItem) => {
          return (
            <div key={legendItem.name} className="c-appix-charts__legend__item">
              <div
                className={`c-appix-charts__legend__marker ${legendItem.type}`}
                style={{ "--color": legendItem.color } as React.CSSProperties}
              />
              <div className="c-appix-charts__legend__name">
                {legendItem.name} {legendItem.type}
              </div>
            </div>
          );
        })}
      </div>
    );
};
