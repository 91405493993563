import { HorizontalWidgetClass, INITIAL_HORIZONTAL_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import clsx from "clsx";
import { Widget } from "containers";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

enum verticalAlignEnum {
  top = "start",
  bottom = "end",
  center = "center",
}

enum horizontalAlignEnum {
  left = "start",
  right = "end",
  center = "center",
}

const HorizontalWidget: React.FC<AppixWidget.Horizontal> = (props) => {
  const horizontalWidget = useWidget<AppixWidget.Horizontal>(
    props,
    INITIAL_HORIZONTAL_WIDGET_STATE,
    HorizontalWidgetClass,
    [props.elements, props.halignment, props.valignment, props.url],
  );

  return horizontalWidget.state.isLoading ? (
    <Spinner />
  ) : (
    <div
      id={props.id}
      className={clsx(
        "horizontal-widget position-relative",
        `align-items-${
          verticalAlignEnum[horizontalWidget.state.valignment.toLowerCase() || "end"]
        }`,
        `justify-content-${
          horizontalAlignEnum[horizontalWidget.state.halignment.toLowerCase() || "start"]
        }`,
        {
          "d-none": !horizontalWidget.state.displayed,
        },
      )}
    >
      <CurrentWidgetInfo widget={horizontalWidget} />

      {horizontalWidget.state.elements.map((widget) => (
        <div key={widget.id} className="horizontal-widget__item">
          <Widget {...widget} />
        </div>
      ))}
    </div>
  );
};

export default HorizontalWidget;
