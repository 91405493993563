import { FC } from "react";
import ReactMarkdown from "react-markdown";
import clsx from "clsx";
import { TextWidgetClass, INITIAL_TEXT_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import remarkGfm from "remark-gfm";
import Spinner from "components/Spinner/Spinner";
import RemixIcon from "components/RemixIcon";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const TextWidget: FC<AppixWidget.Text> = (props) => {
  const textWidget = useWidget<AppixWidget.Text>(
    props,
    INITIAL_TEXT_WIDGET_STATE,
    TextWidgetClass,
    [props.text],
  );

  return (
    <>
      <CurrentWidgetInfo widget={textWidget} />
      <div
        id={props.id}
        className={clsx("appix-text-widget position-relative align-items-center", {
          "d-none": !textWidget.state.displayed,
          "d-flex": textWidget.state.displayed,
          [`text-${textWidget.state.color?.toLowerCase()}`]: textWidget.state.color,
          [`text-size-${textWidget.state.size?.toLowerCase()}`]: textWidget.state.size,
        })}
        style={{ textAlign: "justify" }}
      >
        {textWidget.state.isLoading && <Spinner />}
        {!textWidget.state.isLoading && (
          <>
            <RemixIcon icon={textWidget.state.ico} className="pe-2 float-start" />
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{textWidget.state.text}</ReactMarkdown>
          </>
        )}
      </div>
    </>
  );
};

export default TextWidget;
