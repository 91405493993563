import React from "react";
import clsx from "clsx";
import { ImageWidgetClass, INITIAL_IMAGE_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const ImageWidget: React.FC<AppixWidget.Image> = (props) => {
  const imageWidget = useWidget<AppixWidget.Image>(
    props,
    INITIAL_IMAGE_WIDGET_STATE,
    ImageWidgetClass,
    [props.h, props.w, props.url],
  );

  return (
    <div
      className={clsx("my-1 position-relative", {
        "d-none": !imageWidget.state.displayed,
        "d-block": imageWidget.state.displayed,
      })}
      style={{
        height: imageWidget.state.h ? `${imageWidget.state.h + "px"}` : "auto",
        width: imageWidget.state.w ? `${imageWidget.state.w + "px"}` : "auto",
      }}
    >
      <CurrentWidgetInfo widget={imageWidget} />

      {imageWidget.state.isLoading && <Spinner />}
      {!imageWidget.state.isLoading && (
        <img
          id={props.id}
          src={imageWidget.state.url}
          alt={imageWidget.state.alt || ""}
          height={imageWidget.state.h || "100%"}
          width={imageWidget.state.w || "100%"}
          onClick={imageWidget.broadcastClick}
        />
      )}
    </div>
  );
};

export default ImageWidget;
