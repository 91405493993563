export default function getUnnullableObject(obj: Record<string, any>): Record<string, any> {
  const nextObj = { ...obj };

  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      delete nextObj[key];
    }
  });

  return nextObj;
}
