import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { AppixButton } from "../AppixButton";
import { AppixCheckbox } from "../AppixCheckbox";
import { AppixDropdownMenu } from "../AppixDropdownMenu";
import TableCell from "./TableCell";
import { AppixTableAction, AppixTableProps } from "./types";
import { AppixPopover } from "ui";

interface TableRowProps<ROW> extends Omit<AppixTableProps<ROW>, "data"> {
  row: ROW;
}

function TableRow<ROW>({ columns, row, ...props }: TableRowProps<ROW>) {
  const {
    id,
    primaryKey,
    size,
    selectable,
    selectableCount,
    selectedRows,
    rowActions,
    setSelectedRows,
    onClickRow,
  } = props;

  const rowActionsArray = useMemo<AppixTableAction[]>(() => {
    const currentRowActions = rowActions?.[row[primaryKey]];
    return currentRowActions
      ? Object.keys(currentRowActions).map((actionKey) => ({
          actionKey,
          ...currentRowActions[actionKey],
        }))
      : [];
  }, [rowActions, row, primaryKey]);

  const rowChecked = useMemo<boolean>(() => {
    if (!selectedRows) return false;
    const selectedRowsIds = selectedRows.map((item) => item[primaryKey]);
    return selectedRowsIds.includes(row[primaryKey]);
  }, [primaryKey, selectedRows, row]);

  const rowCheckboxDisabled = useMemo(() => {
    return !!(
      !rowChecked &&
      selectedRows &&
      selectableCount &&
      selectedRows.length >= selectableCount
    );
  }, [rowChecked, selectableCount, selectedRows]);

  const isEmptyActions = useMemo(() => {
    if (rowActions && Object.keys(rowActions).length) {
      return !Object.values(rowActions).some(
        (rowAction) => rowAction && Object.keys(rowAction).length,
      );
    }
    return true;
  }, [rowActions]);

  const handleCheckboxChange = useCallback<(checked: boolean) => void>(
    (checked) => {
      if (setSelectedRows && selectedRows) {
        setSelectedRows(
          checked
            ? [...selectedRows, row]
            : selectedRows.filter((item) => item[primaryKey] !== row[primaryKey]),
        );
      }
    },
    [selectedRows, primaryKey, row, setSelectedRows],
  );

  const handleOnClickRow = useCallback(() => {
    if (!selectable && setSelectedRows && selectedRows) {
      setSelectedRows([row]);
    }
    if (onClickRow) {
      onClickRow(row);
    }
  }, [selectable, selectedRows, row, setSelectedRows, onClickRow]);

  return (
    <tr
      id={`${id}${row[primaryKey]}`}
      className={clsx({
        "table-added": !row["colIndex"] && row["colIndex"] !== 0,
        "table-success": rowChecked && !selectable,
      })}
    >
      {selectable && (
        <td>
          <AppixCheckbox
            id={`${id}CHECKBOX${row[primaryKey]}`}
            checked={rowChecked}
            disabled={rowCheckboxDisabled}
            onCheckboxChange={handleCheckboxChange}
            onlyCheckbox
          />
        </td>
      )}
      {columns.map((column) => (
        <TableCell
          key={column.dataIndex}
          {...props}
          row={row}
          column={column}
          onClickRow={handleOnClickRow}
        />
      ))}
      {!isEmptyActions && (
        <td>
          {rowActionsArray.length > 0 ? (
            <AppixPopover
              id={`${id}${row[primaryKey]}POPOVER`}
              trigger="click"
              placement="bottom-end"
              PopoverContent={
                <AppixDropdownMenu
                  id={`${id}${row[primaryKey]}`}
                  options={rowActionsArray.map((rowAction, index) => ({
                    title: rowAction.title || "",
                    value: rowAction.actionKey || String(index),
                    icon: rowAction.ico,
                    color: rowAction.color,
                    onClick: (_item, e) => {
                      window.document.body.click();
                      e.stopPropagation();
                      rowAction.cb(row);
                    },
                  }))}
                />
              }
              withArrow
            >
              <div className="table-actions">
                <AppixButton
                  id={`${id}SHOW_ACTIONS${row[primaryKey]}`}
                  color={"secondary"}
                  variant={"icon"}
                  beforeIconCode={"more-2"}
                  size={size}
                  type="button"
                />
              </div>
            </AppixPopover>
          ) : (
            <></>
          )}
        </td>
      )}
    </tr>
  );
}

export default TableRow;
