import React from "react";

export interface ClickAwayListenerProps {
  onClickAway: (e: MouseEvent) => void;
  children: React.ReactElement;
}

export const ClickAwayListener: React.FC<ClickAwayListenerProps> = ({ children, onClickAway }) => {
  const childRef = React.useRef<HTMLElement | SVGElement>(null);

  const child = React.useMemo<React.ReactElement>(() => {
    try {
      return React.cloneElement(children, {
        ref: childRef,
      });
    } catch (e) {
      throw new Error("Children should be type is ReactElement!");
    }
  }, [children]);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const handleClickAway = (e: MouseEvent) => {
        if (
          childRef?.current &&
          e?.target &&
          !childRef.current.contains(e.target as Node) &&
          // и если то, на что кликнули, еще есть на странице,
          // а не пропало (например item в селекте, который закрылся)
          document.body.contains(e.target as Node)
        ) {
          onClickAway(e);
        }
      };
      window.document.addEventListener("click", handleClickAway);
      return () => {
        window.document.removeEventListener("click", handleClickAway);
      };
    }
  }, [onClickAway]);

  return <>{child}</>;
};
