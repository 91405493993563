import { AppixSelect, AppixSelectProps } from "ui";

import React, { useCallback, useState } from "react";

interface SelectInputProps extends Omit<AppixSelectProps, "onChange"> {
  onChange: (e: { target: { value: any } }) => void;
  disabled?: boolean;
  clear?: boolean;
  searchable?: boolean;
  id: string;
  treeData?: AppixWidget.Table["table"];
  tableData?: AppixWidget.Table["table"];
  handleSearch?: (query: string, name?: string) => void;
  name?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  id,
  options,
  value,
  disabled = false,
  searchable,
  treeData,
  tableData,
  name,
  helper,
  clear,
  placeholder,
  handleSearch,
  onChange,
  ...props
}) => {
  const [savedValues, setSavedValues] = useState<any>([]);

  const handleChange = useCallback(
    (value) => {
      if (disabled) return;
      onChange({ target: { value } });
    },
    [disabled, onChange],
  );

  const handleTreeChange = useCallback(
    (value) => {
      if (disabled) return;
      const val = value[0] || { label: "", value: null };
      setSavedValues((prev) => {
        if (val.value && prev.find((item) => item.value === val.value)) {
          return prev;
        }
        return [...prev, val];
      });
      onChange({
        target: {
          value: val.value === null ? null : +val.value,
        },
      });
    },
    [disabled, onChange],
  );

  return (
    <AppixSelect
      {...props}
      id={id}
      disabled={disabled}
      value={value}
      options={options}
      withSearch={searchable}
      treeData={treeData}
      tableData={tableData}
      name={name}
      helper={helper}
      savedValues={savedValues}
      placeholder={placeholder}
      clear={clear}
      onInputChange={handleSearch}
      onChange={handleChange}
      onTreeChange={handleTreeChange}
    />
  );
};

export default SelectInput;
