import { FC } from "react";

import RemixIcon from "components/RemixIcon";

interface AppixNoContentProps {
  title?: string;
  icon?: string;
}

const AppixNoContent: FC<AppixNoContentProps> = ({
  title = "Отсутствуют данные для отображения",
  icon = "alert",
}) => {
  return (
    <div className="no-content-warn">
      <div className="no-content-warn__icon">
        <RemixIcon icon={icon} />
      </div>
      <div className="no-content-warn__text">{title}</div>
    </div>
  );
};

export default AppixNoContent;
