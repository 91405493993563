import clsx from "clsx";
import RemixIcon from "components/RemixIcon";
import { useCallback, useMemo, useState, ReactElement } from "react";
import { AppixButton, AppixModal, AppixCodeEditor } from "ui";

import { AppixTableColumn, AppixTableProps } from "./types";

import Appix from "Appix";

const LIST_SUBTYPES_FOR_MODAL_VIEW: AppixWidget.DataSubType[] = [
  "TEXT",
  "MD",
  "HTML",
  "JSON",
  "JAVASCRIPT",
];
interface TableCellProps<ROW> extends Omit<AppixTableProps<ROW>, "data" | "columns"> {
  row: ROW;
  column: AppixTableColumn<ROW>;
}

function TableCell<ROW>({
  id,
  size,
  row,
  column,
  onClickRow,
  onDoubleClickRow,
}: TableCellProps<ROW>) {
  const [isModalShow, setIsModalShow] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    onClickRow && onClickRow(row);
  }, [row, onClickRow]);

  const handleCloseModal = useCallback(() => {
    setIsModalShow(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setIsModalShow(true);
  }, []);

  const handleDoubleClick = useCallback(() => {
    onDoubleClickRow && onDoubleClickRow(row);
  }, [row, onDoubleClickRow]);

  const cancelSelection = useCallback((e) => {
    if (e.detail > 1) {
      e.preventDefault();
    }
  }, []);

  const cellColor = useMemo(() => {
    return column.color(row)?.toLowerCase();
  }, [column, row]);

  const cellIconData = useMemo(() => {
    return column.ico(row);
  }, [column, row]);

  const cellValue = useMemo(() => {
    const value = row[column.dataIndex];
    if (value !== null && value !== undefined) {
      if (typeof value === "object") {
        const { startDate, endDate } = value;
        return `${startDate} - ${endDate}`;
      }
      return `${value}`;
    } else return "";
  }, [column.dataIndex, row]);

  const modalContent = useMemo<ReactElement | undefined>(() => {
    if (!cellValue) {
      return;
    }
    return (
      <AppixCodeEditor
        value={cellValue}
        language={column.dataSubType}
        id={id + column.dataIndex}
        readOnly={true}
      />
    );
  }, [column.dataSubType, cellValue, column.dataIndex, id]);

  const handleCopy = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      cellValue &&
        navigator.clipboard
          .writeText(`${cellValue}`)
          .then(() => {
            Appix.showSuccess("Текст скопирован в буфер обмена!", true);
          })
          .catch((e) => {
            Appix.showError("Не удалось скопировать текст!", true);
          });
    },
    [cellValue],
  );

  return (
    <td onClick={handleClick} onMouseDown={cancelSelection} onDoubleClick={handleDoubleClick}>
      <div className={clsx("table-cell", { [`text-${cellColor}`]: !!cellColor })}>
        {cellIconData && cellIconData.position === "LEFT" && (
          <div className="table-cell-icon">
            <RemixIcon
              className={clsx({
                [`text-${cellIconData.color.toLowerCase()}`]: !!cellIconData.color,
              })}
              icon={cellIconData.ico}
            />
          </div>
        )}
        {cellValue && LIST_SUBTYPES_FOR_MODAL_VIEW.includes(column.dataSubType) ? (
          <div className="table-actions">
            <AppixButton
              id={`${id}SHOW_MODAL${column.dataIndex}`}
              color={"secondary"}
              size={size}
              variant={"icon"}
              afterIconCode={"external-link"}
              type="button"
              onClick={handleOpenModal}
            >
              [{column.dataSubType}]
            </AppixButton>
          </div>
        ) : (
          <span className="table-cell-content">{cellValue.toString()}</span>
        )}
        {column?.validator?.copy && cellValue && (
          <button className="input-group-text table-cell-icon" onClick={handleCopy}>
            <RemixIcon icon="file-copy" />
          </button>
        )}

        {cellIconData && cellIconData.position === "RIGHT" && (
          <div className="table-cell-icon">
            <RemixIcon
              className={clsx({
                [`text-${cellIconData.color.toLowerCase()}`]: !!cellIconData.color,
              })}
              icon={cellIconData.ico}
            />
          </div>
        )}
      </div>
      <AppixModal
        className={"ps-0"}
        id={id}
        type="MODAL"
        size={null}
        caption={column.dataIndex}
        isShown={isModalShow}
        titleIconBtn={column?.validator?.copy ? "file-copy" : ""}
        onClose={handleCloseModal}
        onHandleTitleBtnClick={handleCopy}
      >
        <>{modalContent}</>
      </AppixModal>
    </td>
  );
}

export default TableCell;
