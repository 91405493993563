import React from "react";
import { BlockQuoteWidgetClass, INITIAL_BLOCKQUOTE_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import { AppixBlockquote } from "ui/AppixBlockquote/AppixBlockquote";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const BlockquoteWidget: React.FC<AppixWidget.BlockQuote> = (props) => {
  const blockQuoteWidget = useWidget<AppixWidget.BlockQuote>(
    props,
    INITIAL_BLOCKQUOTE_WIDGET_STATE,
    BlockQuoteWidgetClass,
    [props.text, props.level],
  );
  const { text, level, displayed, id, badge } = blockQuoteWidget.state;

  return (
    <div className="w-100 position-relative">
      <CurrentWidgetInfo widget={blockQuoteWidget} />
      {blockQuoteWidget.state.isLoading && <Spinner />}
      {!blockQuoteWidget.state.isLoading && (
        <AppixBlockquote displayed={displayed} text={text} level={level} id={id} badge={badge} />
      )}
    </div>
  );
};

export default BlockquoteWidget;
