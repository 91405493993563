export default function calculateInterval(granularity: AppixWidget.Granularity) {
  switch (granularity) {
    case "SECOND":
      return 1000;
    case "MINUTE":
      return 1000 * 60;
    case "HOUR":
      return 1000 * 60 * 60;
    case "DAY":
      return 1000 * 60 * 60 * 24;
    case "WEEK":
      return 1000 * 60 * 60 * 24 * 7;
    case "MONTH":
      return 1000 * 60 * 60 * 24 * 30;
    case "YEAR":
      return 1000 * 60 * 60 * 24 * 365;
    default:
      return 1000;
  }
}
