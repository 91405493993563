import React from "react";
import ReactECharts, { EChartsReactProps } from "echarts-for-react";
import * as echarts from "echarts/core";
import "echarts/i18n/langRU";
import moment from "moment";
import Appix from "Appix";
import { CustomLegend } from "./CustomLegend";
import "theme/components/_appix-charts.scss";

export const defaultColors = [
  "#4e7fff",
  "#5aa6ff",
  "#74cdff",
  "#a3e3ff",
  "#aaeeb5",
  "#61d49c",
  "#2eae71",
  "#ff8c39",
  "#ffc4a0",
  "#fae2bf",
  "#ffccca",
  "#ff7878",
];

echarts.registerTheme("appix", {
  color: defaultColors,
  textStyle: {
    fontFamily: "Inter",
  },
  title: {
    textStyle: {
      // H3
      color: "#374151",
      fontSize: 16,
      fontFamily: "Inter",
      fontWeight: 500,
    },
    subtextStyle: {
      color: "#6b7280",
      fontSize: 14,
      fontFamily: "Inter",
      fontWeight: 400,
    },
  },
  grid: {
    top: 70,
    borderColor: "#D1D5DB",
    left: "10%",
  },
  line: {
    itemStyle: {
      borderWidth: "2",
    },
    lineStyle: {
      width: "2",
    },
    symbolSize: "6",
    symbol: "circle",
    smooth: false,
  },
  radar: {
    itemStyle: {
      borderWidth: "2",
    },
    lineStyle: {
      width: "2",
    },
    symbolSize: "6",
    symbol: "circle",
    smooth: false,
  },
  bar: {
    itemStyle: {
      barBorderWidth: "0",
      barBorderColor: "#e7e8ea",
    },
  },
  pie: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#e7e8ea",
    },
  },
  scatter: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#e7e8ea",
    },
  },
  boxplot: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#e7e8ea",
    },
  },
  parallel: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#e7e8ea",
    },
  },
  sankey: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#e7e8ea",
    },
  },
  funnel: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#e7e8ea",
    },
  },
  gauge: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#e7e8ea",
    },
  },
  candlestick: {
    itemStyle: {
      color: "#10b981",
      color0: "#ef4444",
      borderColor: "#10b981",
      borderColor0: "#ef4444",
      borderWidth: "1",
    },
  },
  graph: {
    itemStyle: {
      borderWidth: "0",
      borderColor: "#e7e8ea",
    },
    lineStyle: {
      width: "1",
      color: "#cccccc",
    },
    symbolSize: "6",
    symbol: "circle",
    smooth: false,
    color: [
      "#4e7fff",
      "#5aa6ff",
      "#74cdff",
      "#a3e3ff",
      "#aaeeb5",
      "#61d49c",
      "#2eae71",
      "#ff8c39",
      "#ffc4a0",
      "#fae2bf",
      "#ffccca",
      "#ff7878",
    ],
    label: {
      color: "#ffffff",
    },
  },
  map: {
    itemStyle: {
      areaColor: "#eeeeee",
      borderColor: "#aaaaaa",
      borderWidth: 0.5,
    },
    label: {
      color: "#ffffff",
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(63,177,227,0.25)",
        borderColor: "#3fb1e3",
        borderWidth: 1,
      },
      label: {
        color: "#3fb1e3",
      },
    },
  },
  geo: {
    itemStyle: {
      areaColor: "#eeeeee",
      borderColor: "#aaaaaa",
      borderWidth: 0.5,
    },
    label: {
      color: "#ffffff",
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(63,177,227,0.25)",
        borderColor: "#3fb1e3",
        borderWidth: 1,
      },
      label: {
        color: "#3fb1e3",
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#d1d5db",
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333",
      },
    },
    axisLabel: {
      show: true,
      color: "#999999",
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#e7e8ea"],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#d1d5db",
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333",
      },
    },
    axisLabel: {
      show: true,
      color: "#999999",
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#e7e8ea"],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#d1d5db",
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333",
      },
    },
    axisLabel: {
      show: true,
      color: "#999999",
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#e7e8ea"],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#d1d5db",
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333",
      },
    },
    axisLabel: {
      show: true,
      color: "#999999",
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#e7e8ea"],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: "#999999",
    },
    emphasis: {
      iconStyle: {
        borderColor: "#374151",
      },
    },
  },
  tooltip: {
    valueFormatter: (value) => TooltipFormatter(value),
    axisPointer: {
      lineStyle: {
        color: "#cccccc",
        width: 1,
      },
      crossStyle: {
        color: "#cccccc",
        width: 1,
      },
    },
  },
  timeline: {
    lineStyle: {
      color: "#9ca3af",
      width: "2",
    },
    itemStyle: {
      color: "#9ca3af",
      borderWidth: "3",
    },
    controlStyle: {
      color: "#626c91",
      borderColor: "#626c91",
      borderWidth: "0",
    },
    checkpointStyle: {
      color: "#4285f4",
      borderColor: "#4285f4",
    },
    label: {
      color: "#626c91",
    },
    emphasis: {
      itemStyle: {
        color: "#9ca3af",
      },
      controlStyle: {
        color: "#626c91",
        borderColor: "#626c91",
        borderWidth: "0",
      },
      label: {
        color: "#626c91",
      },
    },
  },
  visualMap: {
    color: ["#4e7fff", "#cff4ff"],
  },
  dataZoom: {
    backgroundColor: "rgba(255,255,255,0)",
    dataBackgroundColor: "rgba(222,222,222,1)",
    fillerColor: "rgba(114,230,212,0.25)",
    handleColor: "#cccccc",
    handleSize: "100%",
    textStyle: {
      color: "#999999",
    },
  },
  markPoint: {
    label: {
      color: "#ffffff",
    },
    emphasis: {
      label: {
        color: "#ffffff",
      },
    },
  },
});

export const LegendStyles = {
  textStyle: {
    color: "#6B7280",
    fontSize: 10,
  },
  type: "scroll",
  orient: "vertical",
  align: "left",
  itemGap: 8,
  bottom: 8,
  left: 0,
  itemHeight: 10,
  itemWidth: 12,
  pageIconSize: 12,
  pageTextStyle: {
    fontSize: 10,
  },
  formatter: (value) => TooltipFormatter(value),
  pageIcons: {
    vertical: [
      "path://M7.99999 7.21868L4.69999 10.5187L3.75732 9.57601L7.99999 5.33334L12.2427 9.57601L11.3 10.5187L7.99999 7.21868Z",
      "path://M7.99999 8.78132L11.3 5.48132L12.2427 6.42399L7.99999 10.6667L3.75732 6.42399L4.69999 5.48132L7.99999 8.78132Z",
    ],
  },
};

export const TooltipFormatter = (value) => {
  if (value === undefined) {
    return "—";
  } else if (typeof value === "number") {
    return value.toLocaleString();
  } else {
    if (value?.toString()?.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/gm)) {
      return moment(value).format("DD.MM.YYYY");
    } else {
      return AxisFormatter(value);
    }
  }
};

export const ConvertXAxisData = (value) => {
  if (value && typeof value === "string" && Date.parse(value)) {
    return new Date(Date.parse(value)).getTime();
  } else {
    return value;
  }
};

export const DetermineChartType = (series) => {
  if (Date.parse(Object.keys(series)[0])) {
    return "time";
  } else if (typeof Object.keys(series)[0] === "number") {
    return "number";
  } else {
    return "category";
  }
};

export const GetMaxLegendHeight = (series, forGrid?: boolean) => {
  const min = 96;
  const height = Math.min(min, series.length * 20);
  if (forGrid) {
    return min === height ? height + 40 : height + 24;
  }
  return height;
};

export const transformTextValue = (value) => {
  if (value?.toString()?.match(/\d{4}-\d{2}-\d{2}[T ]\d{2}:\d{2}:\d{2}Z?(?:\+[\d:]*)?/gm)) {
    if (
      new Date(value).toLocaleTimeString("ru", { timeZone: "UTC" }) === "00:00:00" &&
      Appix.datetimeFormatPattern
    ) {
      return moment(value).format(Appix.datetimeFormatPattern);
    } else if (Appix.dateFormatPattern) {
      return moment(value).format(Appix.dateFormatPattern);
    } else {
      return moment(value).format("DD MMM YYYY");
    }
  } else {
    return value;
  }
};

export const AxisFormatter = (value) => {
  if (typeof value === "number") {
    if (value >= 1000000) {
      return +(value / 1000000).toFixed(2) + "M";
    }

    if (value >= 10000) {
      return +(value / 1000).toFixed(2) + "K";
    }
    return value.toLocaleString();
  } else {
    return transformTextValue(value);
  }
};
// https://echarts.apache.org/en/option.html for "option" prop definition
export const AppixCharts: React.FC<EChartsReactProps> = ({ option, onEvents }) => {
  if (JSON.stringify(option) === "{}") return <div className="c-appix-charts__error">Ошибка</div>;
  else
    return (
      <div className="c-appix-charts">
        <div className="c-appix-charts__chart-container">
          <ReactECharts
            option={option}
            theme={"appix"}
            style={{ height: "100%" }}
            opts={{ locale: "RU" }}
            notMerge={true}
            onEvents={onEvents}
          />
        </div>
        <CustomLegend data={option.__customLegendData} />
      </div>
    );
};
