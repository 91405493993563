import { Widget } from "containers";
import React from "react";
import { LayerContainerWidgetClass, INITIAL_LAYERCONTAINER_WIDGET_STATE } from "@appix/core";
import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const getAlignmentStyle = (alignment, isBaseLayer = false) => {
  if (isBaseLayer) return {};
  switch (alignment) {
    case "TOP_LEFT":
      return { top: 0, left: 0 };
    case "TOP_CENTER":
      return { top: 0, left: "50%", transform: "translateX(-50%)" };
    case "TOP_RIGHT":
      return { top: 0, right: 0 };
    case "CENTER_LEFT":
      return { top: "50%", left: 0, transform: "translateY(-50%)" };
    case "CENTER":
      return {
        top: "50%",
        left: "50%",
        transform: "translateX(-50%) translateY(-50%)",
      };
    case "CENTER_RIGHT":
      return { top: "50%", right: 0, transform: "translateY(-50%)" };
    case "BOTTOM_LEFT":
      return { bottom: 0, left: 0 };
    case "BOTTOM_CENTER":
      return { bottom: 0, left: "50%", transform: "translateX(-50%)" };
    case "BOTTOM_RIGHT":
      return { bottom: 0, right: 0 };
    default:
      return {};
  }
};

const LayercontainerWidget: React.FC<AppixWidget.LayerContainer> = (props) => {
  const layerContainerWidget = useWidget<AppixWidget.LayerContainer>(
    props,
    INITIAL_LAYERCONTAINER_WIDGET_STATE,
    LayerContainerWidgetClass,
    [props.layers, props.link, props.url],
  );

  return (
    <div className="position-relative overflow-auto h-100 w-100">
      <CurrentWidgetInfo widget={layerContainerWidget} />

      {layerContainerWidget.state.isLoading && <Spinner />}
      {!layerContainerWidget.state.isLoading &&
        layerContainerWidget.state.layers.map((layer: AppixWidget.LayerContainer.Layer) => (
          <React.Fragment key={layer.id}>
            {layer.widget && (
              <div
                className={`position-${layer.isBaseLayer ? "relative" : "absolute"}`}
                style={{
                  backgroundColor: layer.widgetBackgroundRGBA,
                  width: layer.isBaseLayer ? "100%" : "fit-content",
                  ...getAlignmentStyle(layer.alignment, layer.isBaseLayer),
                }}
              >
                <Widget {...layer.widget} />
              </div>
            )}
            {!layer.widget && layer.fillRGBA && (
              <div
                className="h-100 w-100 position-absolute"
                style={{
                  backgroundColor: layer.fillRGBA,
                  minHeight: "100%",
                  ...getAlignmentStyle(layer.alignment),
                }}
              />
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default LayercontainerWidget;
