import React from "react";

import { AppixSlider } from "ui";
import { SliderWidgetClass, INITIAL_SLIDER_WIDGET_STATE } from "@appix/core";

import useWidget from "utils/useWidget";
import Spinner from "components/Spinner/Spinner";
import CurrentWidgetInfo from "components/CurrentWidgetInfo";

const SliderWidget: React.FC<any> = (props) => {
  const sliderWidget = useWidget<AppixWidget.Slider>(
    props,
    INITIAL_SLIDER_WIDGET_STATE,
    SliderWidgetClass,
    [props],
  );

  // useEffect(() => {
  //   sliderWidget.setState({ slides: props.slides });
  // }, [props.slides, sliderWidget]);

  return sliderWidget.state.isLoading ? (
    <Spinner />
  ) : (
    <div className="position-relative">
      <CurrentWidgetInfo widget={sliderWidget} />

      <AppixSlider handleSelectItem={sliderWidget.selectSlideByIndex} {...sliderWidget.state} />
    </div>
  );
};

export default SliderWidget;
